import { KeyboardArrowDown } from '@mui/icons-material';
import { ButtonProps, Menu } from '@mui/material';
import { ContainedButton } from '@shared/components';
import { useDisclosure } from '@shared/hooks';
import { MouseEvent, useState } from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';

interface Props extends Omit<ButtonProps, 'onClick'> {
  buttonLabel: string;
  menuItems: ReactElement;
}

export default function MenuDropdown({ menuItems, buttonLabel, ...rest }: Props) {
  const menu = useDisclosure();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    menu.onOpen();
  };

  return (
    <>
      <ContainedButton {...rest} onClick={handleClick} endIcon={<KeyboardArrowDown />}>
        {buttonLabel}
      </ContainedButton>

      <Menu open={menu.isOpen} onClose={menu.onClose} anchorEl={anchorEl}>
        <div>{menuItems}</div>
      </Menu>
    </>
  );
}
