import { APP_VERSION_URL } from '@dap-dashboard/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const dapApi = createApi({
  reducerPath: 'appApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (builder) => ({
    getAppVersion: builder.query<{ version: string }, undefined>({
      query: () => `${APP_VERSION_URL}`,
    }),
  }),
});

export const { useGetAppVersionQuery } = dapApi;
