import { LinkProps } from '@mui/material';
import InlineLink from './InlineLink';

interface Props extends LinkProps {
  href: string;
  blank?: boolean;
}

function InlineExternalLink({ children, blank = true, ...linkProps }: Props): JSX.Element {
  return (
    <InlineLink
      target={blank ? '_blank' : ''}
      rel={blank ? 'noopener noreferrer' : ''}
      {...linkProps}
    >
      {children}
    </InlineLink>
  );
}

export default InlineExternalLink;
