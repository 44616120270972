import { EmployeeSearchDTO, SearchLocationRequest } from '@dap-admin/types';

export function getFilterCount(
  filters: EmployeeSearchDTO | SearchLocationRequest,
  ignoredFields?: Array<keyof EmployeeSearchDTO | SearchLocationRequest>
): number {
  let count = 0;

  for (const [key, value] of Object.entries(filters)) {
    const enabledSwitchIsActive = key === 'enabledStatusFilter' && value === false;
    const fieldIsIgnored = ignoredFields?.some((field) => field === key);
    if (!fieldIsIgnored && (value.length > 0 || value === true || enabledSwitchIsActive)) count++;
  }
  return count;
}

export const hasActiveFilter = <T extends Record<string, any>>(
  filters: T,
  ignoredFields?: Array<keyof T>
) => {
  return Object.entries(filters).some(([key, value]) => {
    const fieldIsIgnored = ignoredFields?.some((field) => field === key);
    return !fieldIsIgnored && (value.length > 0 || value === true);
  });
};
