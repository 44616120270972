import { Theme } from '@mui/material';

import { componentsStyles as componentsStylesV2 } from './../dap/componentsStyles';

export const componentsStyles: Theme['components'] = {
  MuiAppBar: {
    styleOverrides: {
      root: ({ theme }) => ({
        background: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
      }),
    },
  },
  MuiCard: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3),
        boxShadow: 'none', // Flattens design
      }),
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: 'none', // Flattens design
      },
      rounded: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius * 2,
      }),
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        '&.MuiContainer-maxWidthMd': {
          maxWidth: '800px',
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: ({ theme }) => ({
        letterSpacing: 'inherit',
      }),
    },
  },
  // Override border color of contained brand button
  // Destructure the existing styles and add a new variant, overriding the border color of the brand button
  MuiButton: {
    ...(componentsStylesV2?.MuiButton ?? {}),
    variants: [
      ...(componentsStylesV2?.MuiButton?.variants ?? []),
      {
        props: { color: 'brand', variant: 'contained' },
        style: ({ theme }) => ({
          borderColor: theme.palette.brand.main,
          color: theme.palette.brand.contrastText,
          '&:hover': {
            backgroundColor: `oklch(from ${theme.palette.brand.main} calc(l * 0.8) c h)`,
            borderColor: `oklch(from ${theme.palette.brand.main} calc(l * 0.8) c h)`,
            color: theme.palette.brand.contrastText,
            // borderColor: theme.palette.brand.main,
            // boxShadow: '0 0 10px 2px deeppink',
          },
          // '&:focus-visible': {
          // boxShadow: theme.shadows[1],
          // outline: `1px solid ${theme.palette.brand.main}`,
          // outlineOffset: 2,
          // },
        }),
      },
    ],
  },
};
