import React from 'react';
import { IconProps, SvgIcon } from '@mui/material';

import alreadyonImgUri from './alreadyon.png';
import apactaImgUri from './apacta.png';
import boligportenImgUri from './boligporten.png';
import brandadminImgUri from './kjedeadmin.png';
import brandmasterImgUri from './brandmaster.png';
import centigaImgUri from './centiga.png';
import experienceLiveImgUri from './experience_live.png';
import fonnImgUri from './fonn.png';
import holteImgUri from './holte.png';
import mgprisImgUri from './mgpris_favicon.svg';
import motimateImgUri from './motimate.png';
import omniumImgUri from './omnium.png';
import prisinnsiktImgUri from './prisinnsikt.png';
import proffnettImgUri from './proffnett.png';
import prognosesenteretImgUri from './prognosesenteret.png';
import pureserviceImgUri from './pureservice.png';
import salesforceImgUri from './salesforce.png';
import workplaceImgUri from './wp.png';

// Export SVG icons as React components
import { ReactComponent as MgPrisIcon } from './mgpris_favicon.svg';
import { ReactComponent as OmniumIcon } from './omnium.svg';

export { OmniumIcon, MgPrisIcon };

// Temporary logo map. Delete when we get a logo url from apps api
export const appLogos: Record<string, string> = {
  alreadyon: alreadyonImgUri,
  apacta: apactaImgUri,
  boligporten: boligportenImgUri,
  brandadmin: brandadminImgUri,
  brandmaster: brandmasterImgUri,
  centiga: centigaImgUri,
  experienceLive: experienceLiveImgUri,
  fonn: fonnImgUri,
  holte: holteImgUri,
  mgpris: mgprisImgUri,
  motimate: motimateImgUri,
  omnium: omniumImgUri,
  prisinnsikt: prisinnsiktImgUri,
  proffnett: proffnettImgUri,
  prognosesenteret: prognosesenteretImgUri,
  pureservice: pureserviceImgUri,
  salesforce: salesforceImgUri,
  workplace: workplaceImgUri,
};

// Export SVG icons of bitmap app logos, for SidebarAppMenu
export const appLogoIcons: Record<string, React.ComponentType<IconProps>> = {
  alreadyon: () => (
    <SvgIcon>
      <svg fill="none" height="20" viewBox="0 0 61 49" width="20">
        <image height="49" width="61" xlinkHref={alreadyonImgUri} />
      </svg>
    </SvgIcon>
  ),
  apacta: () => (
    <SvgIcon>
      <svg fill="none" height="20" viewBox="0 0 44 44" width="20">
        <image height="44" width="44" xlinkHref={apactaImgUri} />
      </svg>
    </SvgIcon>
  ),
  boligporten: () => (
    <SvgIcon>
      <svg fill="none" height="20" viewBox="0 0 60 69" width="20">
        <image height="69" width="60" xlinkHref={boligportenImgUri} />
      </svg>
    </SvgIcon>
  ),
  brandadmin: () => (
    <SvgIcon>
      <svg fill="none" height="20" viewBox="0 0 88 88" width="20">
        <image height="88" width="88" xlinkHref={brandadminImgUri} />
      </svg>
    </SvgIcon>
  ),
  brandmaster: () => (
    <SvgIcon>
      <svg fill="none" height="20" viewBox="0 0 47 45" width="20">
        <image height="45" width="47" xlinkHref={brandmasterImgUri} />
      </svg>
    </SvgIcon>
  ),
  centiga: () => (
    <SvgIcon>
      <svg fill="none" height="20" viewBox="0 0 78 74" width="20">
        <image height="78" width="74" xlinkHref={centigaImgUri} />
      </svg>
    </SvgIcon>
  ),
  experienceLive: () => (
    <SvgIcon>
      <svg fill="none" height="20" viewBox="0 0 170 149" width="20">
        <image height="149" width="170" xlinkHref={experienceLiveImgUri} />
      </svg>
    </SvgIcon>
  ),
  fonn: () => (
    <SvgIcon>
      <svg fill="none" height="20" viewBox="0 0 32 32" width="20">
        <image height="32" width="32" xlinkHref={fonnImgUri} />
      </svg>
    </SvgIcon>
  ),
  holte: () => (
    <SvgIcon>
      <svg fill="none" height="20" viewBox="0 0 55 53" width="20">
        <image height="53" width="55" xlinkHref={holteImgUri} />
      </svg>
    </SvgIcon>
  ),
  mgpris: () => <MgPrisIcon />,
  motimate: () => (
    <SvgIcon>
      <svg fill="none" height="20" viewBox="0 0 124 124" width="20">
        <image height="124" width="124" xlinkHref={motimateImgUri} />
      </svg>
    </SvgIcon>
  ),
  omnium: () => <OmniumIcon />,
  prisinnsikt: () => (
    <SvgIcon>
      <svg fill="none" height="20" viewBox="0 0 980 836" width="20">
        <image height="836" width="980" xlinkHref={prisinnsiktImgUri} />
      </svg>
    </SvgIcon>
  ),
  proffnett: () => (
    <SvgIcon>
      <svg fill="none" height="20" viewBox="0 0 34 32" width="20">
        <image height="32" width="34" xlinkHref={proffnettImgUri} />
      </svg>
    </SvgIcon>
  ),
  prognosesenteret: () => (
    <SvgIcon>
      <svg fill="none" height="20" viewBox="0 0 63 66" width="20">
        <image height="66" width="63" xlinkHref={prognosesenteretImgUri} />
      </svg>
    </SvgIcon>
  ),
  pureservice: () => (
    <SvgIcon>
      <svg fill="none" height="20" viewBox="0 0 300 350" width="20">
        <image height="350" width="300" xlinkHref={pureserviceImgUri} />
      </svg>
    </SvgIcon>
  ),
  salesforce: () => (
    <SvgIcon>
      <svg fill="none" height="20" viewBox="0 0 595 412" width="20">
        <image height="412" width="595" xlinkHref={salesforceImgUri} />
      </svg>
    </SvgIcon>
  ),
  workplace: () => (
    <SvgIcon>
      <svg fill="none" height="20" viewBox="0 0 100 106" width="20">
        <image height="106" width="100" xlinkHref={workplaceImgUri} />
      </svg>
    </SvgIcon>
  ),
};
