import { Box, styled } from '@mui/material';
import React from 'react';
import getYouTubeId from 'get-youtube-id';
import { useTranslation } from 'react-i18next';
import { messages } from '@dap-common/i18n';

interface Props {
  url: string;
}

const StyledIFrame = styled('iframe')({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
});

const EmbeddedYoutubeVideo: React.FC<Props> = ({ url }) => {
  const { t } = useTranslation();
  const id = getYouTubeId(url);
  if (!id) {
    return null;
  }

  return (
    <Box sx={{ paddingTop: '56.25%', position: 'relative' }}>
      <StyledIFrame
        src={`https://www.youtube.com/embed/${id}`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title={t(messages.common.youtubePlayer)}
      />
    </Box>
  );
};

export default EmbeddedYoutubeVideo;
