import { Button, Card, Stack, Typography } from '@mui/material';
import WelcomeInfo from './WelcomeInfo';
import LandingPageIllustration from './LandingPageIllustration';
import LandingPageLayout from './LandingPageLayout';
import { messages } from '@dap-common/i18n';
import { useTranslation } from 'react-i18next';

interface Props {
  loginCallback: () => void;
}

export default function LandingPage({ loginCallback }: Props) {
  const { t } = useTranslation();
  return (
    <LandingPageLayout>
      <Stack alignItems="center" width="400px">
        <WelcomeInfo />
        <Card
          sx={{
            py: '45px',
            px: '60px',
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',
            alignItems: 'center',
          }}
        >
          <Typography textAlign="center">{t(messages.common.landingPage.appInfo)}</Typography>
          <Button
            onClick={loginCallback}
            variant="contained"
            color="primary"
            sx={{ alignSelf: 'stretch' }}
          >
            {t(messages.common.landingPage.logIn)}
          </Button>
        </Card>
      </Stack>
      <LandingPageIllustration />
    </LandingPageLayout>
  );
}
