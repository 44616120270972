import { Close } from '@mui/icons-material';
import { alpha, IconButton, Modal, Stack } from '@mui/material';

interface Props {
  url: string;
  onClose: () => void;
}
export default function PdfModal({ onClose, url }: Props): JSX.Element {
  return (
    <Modal
      open
      onClose={onClose}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'fixed',
            top: '20px',
            right: '20px',
            bgcolor: ({ palette }) => alpha(palette.common.black, 0.6),
            color: ({ palette }) => palette.common.white,
            '&:hover, :focus': {
              bgcolor: ({ palette }) => palette.common.black,
            },
          }}
        >
          <Close />
        </IconButton>
        <Stack height="95vh" minWidth="80vw" maxWidth="90vw">
          <object width="100%" height="100%" data={url} type="application/pdf">
            PDF
          </object>
        </Stack>
      </>
    </Modal>
  );
}
