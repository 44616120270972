import { Box, BoxProps } from '@mui/material';
import { PropsWithChildren } from 'react';

interface Props extends BoxProps {
  align?: 'left' | 'right';
}

export default function Buttons({ children, align = 'right', ...rest }: PropsWithChildren<Props>) {
  return (
    <Box
      display="flex"
      gap={1}
      {...rest}
      justifyContent={align === 'right' ? 'flex-end' : 'flex-start'}
    >
      {children}
    </Box>
  );
}
