import { Button, ButtonProps, Stack } from '@mui/material';
import { messages } from '@dap-common/i18n';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainedButton } from '@shared/components';

interface Props {
  save?: () => void;
  cancel: () => void;
  loading?: boolean;
  align?: 'left' | 'right';
  saveText?: string;
  cancelText?: string;
  disabled?: boolean;
  formId?: string;
  primaryButtonColor?: ButtonProps['color'];
  size?: 'small' | 'large';
}

const SaveOrCancelEdit: FunctionComponent<Props> = ({
  save,
  cancel,
  loading = false,
  align = 'left',
  saveText,
  cancelText,
  disabled,
  formId,
  primaryButtonColor = 'primary',
  size = 'small',
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent={align === 'left' ? 'flex-start' : 'flex-end'}
    >
      <Button variant="text" size={size} color={'inherit'} onClick={cancel} disabled={loading}>
        {cancelText || t(messages.common.cancel)}
      </Button>
      <ContainedButton
        size={size}
        onClick={save}
        loading={loading}
        disabled={disabled || loading}
        form={formId}
        type="submit"
        color={primaryButtonColor}
      >
        {saveText || t(messages.common.save)}
      </ContainedButton>
    </Stack>
  );
};

export default SaveOrCancelEdit;
