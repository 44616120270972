export const pruneObject = <T extends object>(
  obj: T,
  replacementValue: any = undefined
): T => {
  return JSON.parse(JSON.stringify(obj), (_, value) => {
    if (
      value === null ||
      value === '' ||
      value === undefined ||
      JSON.stringify(value) === '[null]'
    ) {
      return replacementValue;
    }
    return value;
  });
};

export const pruneObjectFromEmptyList = <T extends object>(
  obj: T,
  replacementValue: any = undefined
): T => {
  return JSON.parse(JSON.stringify(obj), (_, value) => {
    if (JSON.stringify(value) === '[]') {
      return replacementValue;
    }
    return value;
  });
};
