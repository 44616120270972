import { messages } from '@dap-common/i18n';
import { withFormProvider } from '@dap-common/ui';
import { SanityServicePreview, SimpleCategory } from '@dap-sanity/types';
import { Box, Card, Stack } from '@mui/material';
import { Filter, filterList } from '@shared/utils';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import ServiceFilters from './ServiceFilters';
import ServicePreviewsList from './ServicePreviewsList';

interface Props {
  servicePreviews: SanityServicePreview[];
  allServicesRoute: string;
}

export const filterServicesSchema = yup.object().shape({
  tagSearch: yup.string(),
  categories: yup.array().of(yup.string()),
});

export interface FilterForm {
  tagSearch: string;
  categories: string[];
}

export const getServiceCategoryFilter = (
  checkedCategories: string[]
): Filter<SanityServicePreview> => ({
  active: checkedCategories.length > 0,
  predicate: (service) =>
    checkedCategories.some((checkedCategory) =>
      service.categories?.some((category) => category.id === checkedCategory)
    ),
});

export const getTagSearchFilter = (tagSearch: string): Filter<SanityServicePreview> => ({
  active: !!tagSearch,
  predicate: (service) =>
    service.tags
      ? service.tags.some((tag) =>
          tag.toLocaleLowerCase('no').includes(tagSearch.toLocaleLowerCase('no'))
        )
      : false,
});

function AllServicePreviewsLayout({ servicePreviews, allServicesRoute }: Props) {
  const { t } = useTranslation(['common', 'services']);
  const { watch } = useFormContext<FilterForm>();
  const checkedCategories = watch('categories');
  const tagSearch = watch('tagSearch');

  const categoryFilter = useMemo(
    () => getServiceCategoryFilter(checkedCategories),
    [checkedCategories]
  );

  const searchTagFilter = useMemo(() => getTagSearchFilter(tagSearch), [tagSearch]);

  const filteredPreviews = useMemo(
    () =>
      filterList({
        filters: [categoryFilter, searchTagFilter],
        initList: servicePreviews,
      }),
    [categoryFilter, searchTagFilter, servicePreviews]
  );

  const availableCategories = useMemo(() => {
    const allCategories: SimpleCategory[] =
      servicePreviews?.flatMap(({ categories }) => categories ?? []) || [];
    const uniqueCategories = allCategories.reduce((accumulator, current) => {
      if (!accumulator.find((obj) => obj.id === current.id)) {
        accumulator.push(current);
      }
      return accumulator;
    }, [] as SimpleCategory[]);
    return uniqueCategories;
  }, [servicePreviews]);

  return (
    <Stack spacing={2}>
      <Card
        // position="sticky"
        // py={2}
        // top="3rem"
        // zIndex="1"
        sx={{
          position: 'sticky',
          top: '66px',
          zIndex: 1,
          marginTop: 2,

          backgroundColor: ({ palette }) => palette.background.default,
        }}
      >
        <ServiceFilters availableCategories={availableCategories} />
      </Card>
      <Card>
        <ServicePreviewsList
          servicePreviews={filteredPreviews}
          emptyListMessage={
            !servicePreviews?.length
              ? t(messages.services.emptyList, { ns: 'services' })
              : t(messages.services.filters.noMatch, { ns: 'services' })
          }
        />
      </Card>
    </Stack>
  );
}

export default withFormProvider<FilterForm, Props>(AllServicePreviewsLayout, () => ({
  schema: filterServicesSchema,
  defaultValues: {
    categories: [],
    tagSearch: '',
  },
  mode: 'onChange',
}));
