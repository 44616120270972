import { Tooltip, TooltipProps, Typography, TypographyProps } from '@mui/material';
import { ReactChild, useEffect, useRef, useState } from 'react';

interface OverflowingTextProps extends TypographyProps {
  tooltipText: string;
  tooltipProps?: TooltipProps;
  children: ReactChild;
}

export default function OverflowingText({
  tooltipText,
  children,
  tooltipProps,
  variant = 'inherit',
  ...typographyProps
}: OverflowingTextProps) {
  const textRef = useRef<HTMLElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);

  useEffect(() => {
    const textElement = textRef.current;
    setIsOverflown(
      textElement
        ? textElement.offsetWidth < textElement.scrollWidth ||
            textElement.offsetHeight < textElement.scrollHeight
        : false
    );
  }, []);

  return isOverflown ? (
    <Tooltip title={tooltipText} {...tooltipProps}>
      <Typography ref={textRef} variant={variant} {...typographyProps} noWrap>
        {children}
      </Typography>
    </Tooltip>
  ) : (
    <Typography ref={textRef} variant={variant} {...typographyProps} noWrap>
      {children}
    </Typography>
  );
}
