import { messages } from '@dap-common/i18n';
import { ButtonField, FormButtonsArray, TextInput } from '@dap-common/ui';
import { SimpleCategory } from '@dap-sanity/types';
import { Search } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  availableCategories: SimpleCategory[];
}

function ServiceFilters({ availableCategories }: Props) {
  const { t } = useTranslation('services');
  const categoryButtonFields: ButtonField[] = useMemo(
    () =>
      availableCategories.map((category) => ({
        value: category.id,
        label: category.title,
      })),
    [availableCategories]
  );

  return (
    <Stack spacing={2}>
      <FormButtonsArray arrayName="categories" fields={categoryButtonFields} />
      <TextInput
        name="tagSearch"
        placeholder={t(messages.services.filters.tagSearch.placeholder)}
        startIcon={<Search />}
        sx={{
          width: ['100%', '30rem'],
        }}
      />
    </Stack>
  );
}

export default ServiceFilters;
