import { EmployeeSortOptions } from '@dap-common/types';
import { AppBasic, OrgUnitRole, Role } from '../commonTypes';
import { DataownerBasic } from './dataownerTypes';
import { LocationBasic } from './locationTypes';
import { BrandBasic } from './brandTypes';
import { UserLanguage } from './userTypes';

export interface EmployeeDetail {
  userId: string;
  email: string;
  status: Array<EmployeeStatus>;
  name: string;
  familyName: string;
  givenName: string;
  phoneNumber: string | null;
  dataowners: Array<DataownerBasic>;
  locations: Array<LocationBasic>;
  brands: Array<BrandBasic>;
  apps: Array<AppBasic>;
  metadata: UserMetadata;
  jobTitle: string | null;
  roles: Array<OrgUnitRole>;
  mgEmployee: boolean;
  superuser: boolean;
}

export type UserMetadata = {
  verifyEmailSent: string | null;
  superuser: boolean;
  preferredLanguage: UserLanguage;
  createdAt: string | null;
  updatedAt: string | null;
  updatedBy: string | null;
  disabledAt: string | null;
};

export interface CreateEmployee {
  email: string;
  familyName?: string;
  givenName?: string;
  jobTitle?: string;
  phoneNumber?: string;
  apps: Array<string>;
  brands: Array<string>;
  dataowners: Array<string>;
  locations: Array<string>;
  roles: Array<Role>;
  emailVerified: boolean;
  verifyEmail: boolean;
  passwordResetEmail?: boolean;
}

export interface PatchEmployeeDto {
  email?: string;
  phoneNumber?: string;
  givenName?: string;
  familyName?: string;
  jobTitle?: string;
  preferredLanguage?: string;
}

export interface EmployeeSearchResult {
  name: string;
  givenName: string;
  familyName: string;
  userId: string;
  email: string;
  status: EmployeeStatus;
  jobTitle: string;
  locationNames: string[];
}

export enum EmployeeStatus {
  BLOCKED = 'BLOCKED',
  DISABLED = 'DISABLED',
  TEMP = 'TEMP',
  UNVERIFIED = 'UNVERIFIED',
  ENABLED = 'ENABLED',
  UNKNOWN = 'UNKNOWN',
}

export interface EmployeeSearchDTO {
  appFilter?: string[];
  brandFilter?: string[];
  dataownerFilter?: string[];
  deep?: boolean;
  enabledStatusFilter?: string[];
  locationFilter?: string[];
  locationPropertyFilter?: string[];
  query?: string;
  regionFilter?: string[];
  roleFilter?: string[];
  sort?: EmployeeSortOptions;
}

export interface EmployeeBasicV2 {
  name: string;
  userId: string;
  email: string | null;
  status: string;
  givenName: string | null;
  familyName: string | null;
  phoneNumber: string | null;
  jobTitle: string | null;
  superuser: boolean;
  apps: Array<AppBasic>;
  locationNames: Array<string>;
  roles: Array<OrgUnitRole>;
  mgEmployee: boolean;
}

export interface EmployeeOverview {
  activeEmployees: Array<EmployeeBasicV2>;
  inactiveEmployees: Array<EmployeeBasicV2>;
}

export interface AddAppToEmployee {
  userId: string;
  apps: Array<string>;
}

export type RemoveAppFromEmployee = AddAppToEmployee;

export interface AddRolesToEmployee {
  userId: string;
  roles: Array<Role>;
}

export type RemoveRolesFromEmployee = AddRolesToEmployee;

export enum Action {
  add = 'add',
  remove = 'remove',
}
