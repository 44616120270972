import { MosaicModuleConfig, MosaicSize, MosaicType } from '@dap-sanity/types';
import { Grid } from '@mui/material';
import { MosaicWithCTA, StandardMosaic } from '@dap-common/ui';
import { useSanityContextActions } from '@dap-sanity/utils';

export default function MosaicGrid({ mosaicBuilder }: Pick<MosaicModuleConfig, 'mosaicBuilder'>) {
  const { getImageUrlBuilder } = useSanityContextActions();

  return (
    <div style={{ width: '100%' }}>
      <Grid container spacing={2}>
        {mosaicBuilder.map((item) => {
          switch (item._type) {
            case MosaicType.WithCta:
              return (
                <Grid item xs={12} md={item.size === MosaicSize.Small ? 3 : 6} key={item._key}>
                  <MosaicWithCTA
                    href={item.url}
                    imageSrc={getImageUrlBuilder(item.image).url()}
                    header={item.title}
                    text={item.subtitle}
                    buttonText={item.linkText}
                    isSmall={item.size === MosaicSize.Small}
                  />
                </Grid>
              );

            default:
              return (
                <Grid item xs={12} md={item.size === MosaicSize.Small ? 3 : 6} key={item._key}>
                  <StandardMosaic
                    href={item.url}
                    imageSrc={getImageUrlBuilder(item.image).url()}
                    text={item.linkText}
                  />
                </Grid>
              );
          }
        })}
      </Grid>
    </div>
  );
}
