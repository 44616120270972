import {
  ArticleType,
  ExtraInfoType,
  KeyInfoType,
  RegistrationType,
  EventSourceType,
} from '@dap-sanity/types';
import { groqQuery } from '@shared/utils';

export interface GetEventPreviewsQueryProps {
  date: string;
  brandKey: string;
  source?: EventSourceType;
  maxNumberToLoad?: number;
}

export const getEventPreviewsQuery = ({
  date,
  brandKey,
  maxNumberToLoad,
  source = 'upcoming',
}: GetEventPreviewsQueryProps) => {
  let dateFilter = '';
  let order: 'asc' | 'desc' = 'asc';

  switch (source) {
    case 'upcoming':
      dateFilter = `eventInformation.dateTimeRange.startDate >= "${date}"`;
      break;

    case 'upcoming_and_current':
      dateFilter = `(
        (
          eventInformation.dateTimeRange.startDate >= "${date}"
        ) || (
          defined(eventInformation.dateTimeRange.endDate)
          && eventInformation.dateTimeRange.startDate < "${date}"
          && eventInformation.dateTimeRange.endDate >= "${date}"
        )
      )`;
      break;

    case 'current':
      dateFilter = `(
        defined(eventInformation.dateTimeRange.endDate)
        && eventInformation.dateTimeRange.startDate < "${date}"
        && eventInformation.dateTimeRange.endDate >= "${date}"
      )`;
      break;

    case 'past':
      dateFilter = `(
        (
          !defined(eventInformation.dateTimeRange.endDate)
          && eventInformation.dateTimeRange.startDate < "${date}"
        ) || (
          defined(eventInformation.dateTimeRange.endDate)
          && eventInformation.dateTimeRange.endDate < "${date}"
        )
      )`;
      order = 'desc';
      break;
  }

  const query = groqQuery({
    filters: [
      `"${brandKey}" in brand`,
      `_type == "${ArticleType.event}"`,
      'defined(title)',
      'defined(eventInformation.dateTimeRange.startDate)',
      dateFilter,
    ],
    ordering: [{ fieldname: 'eventInformation.dateTimeRange.startDate', order }],
    projections: [
      '"id": _id',
      'title',
      '"url": url.current',
      `eventInformation{
        ...,
        "registration": {
          "registrationUrl": registration[_type == "${RegistrationType.url}"][0].registrationUrl,
          "registrationEmail": registration[_type == "${RegistrationType.email}"][0].registrationEmail
        },
        extraInfo{
          "person": extraInfo[_type == "${ExtraInfoType.personRef}"] {
              title,
              "name": ref-> {name}.name
            },
          "link": extraInfo[_type == "${ExtraInfoType.externalLink}"] {
              title,
              url
            },
          "text": extraInfo[_type == "${ExtraInfoType.freeText}"] {
              title,
              value
          }
        }
      }`,
      'intro',
      '"image": image.asset->url',
    ],
    slice: maxNumberToLoad ? { start: 0, end: maxNumberToLoad } : undefined,
  });

  return query;
};

export interface GetEventQueryProps {
  slug: string;
  brandKey: string;
}

export const getEventQuery = ({ slug, brandKey }: GetEventQueryProps) =>
  groqQuery({
    filters: [
      '_type == "eventArticle"',
      `url.current == "${slug}"`,
      `"${brandKey}" in brand`,
      'defined(url)',
    ],
    ordering: [{ fieldname: 'eventInformation.dateTimeRange.startDate', order: 'asc' }],
    projections: [
      '...',
      `eventInformation{
          ...,
          "registration": {
            "registrationUrl": registration[_type == "${RegistrationType.url}"][0].registrationUrl,
            "registrationEmail": registration[_type == "${RegistrationType.email}"][0].registrationEmail
          },
          "extraInfo": {
            "person": extraInfo[_type == "${ExtraInfoType.personRef}"] {
                title,
                "name": ref-> {name}.name
              },
            "link": extraInfo[_type == "${ExtraInfoType.externalLink}"] {
                title,
                url
              },
            "text": extraInfo[_type == "${ExtraInfoType.freeText}"] {
                title,
                value
            }
          }
        }`,
      `"body": textBlock[]{
        ...,
        _type == "image" => {
          ...,
          asset->
        }
      }`,
      `"keyInfo": {
        "email": keyInfo.fields[_type match "${KeyInfoType.email}"],
        "link": keyInfo.fields[_type match "${KeyInfoType.link}"],
        "text": keyInfo.fields[_type match "${KeyInfoType.text}"],
      }`,
      `attachments[]{
        label,
        "url": asset->url
      }`,
      `author->`,
    ],
    slice: { start: 0 },
  });
