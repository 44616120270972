import { EnabledOption } from './filterTypes';

export enum AdminOption {
  ADMIN = 'admin',
  NON_ADMIN = 'non-admin',
}

export interface LocationEmployeeFilters {
  query: string;
  status: EnabledOption[];
  admin: AdminOption[];
  withApps: string[];
  withoutApps: string[];
}

export type LocationEmployeeFilterKeys = keyof LocationEmployeeFilters;
