import { Stack, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

interface Props {
  feature: string;
}

export default function StandardFeatureItem({ feature }: Props) {
  return (
    <Stack key={feature} direction="row" spacing={1} py={0.5}>
      <CheckIcon color="success" fontSize="small" />
      <Typography variant="body2">{feature}</Typography>
    </Stack>
  );
}
