import { LoadingDefaultComponent } from '@dap-common/ui';
import LandingPageLayout from './LandingPageLayout';

export default function PublicLoadingPage() {
  return (
    <LandingPageLayout>
      <LoadingDefaultComponent />
    </LandingPageLayout>
  );
}
