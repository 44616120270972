import { Box, Stack } from '@mui/material';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import React from 'react';
import { useSanityContextActions } from '@dap-sanity/utils';

interface Props {
  brandImageUrl: string;
  backgroundImage?: SanityImageSource;
  children: React.ReactElement;
}

function ProffInfoBanner({ children, brandImageUrl, backgroundImage }: Props) {
  const { getImageUrlBuilder } = useSanityContextActions();

  return (
    <Box
      sx={{
        padding: 3,
        background: `linear-gradient(90deg, #0009, #0009), ${
          backgroundImage && `url(${getImageUrlBuilder(backgroundImage).height(600).url()})`
        }`,
        backgroundSize: 'cover',
        backgroundPosition: 'left center',
        color: ({ palette }) => palette.common.white,
        borderRadius: 2,
      }}
    >
      <Stack
        direction="row"
        gap={2}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'start',
        }}
      >
        <Box maxWidth="420px">{children}</Box>
        <Box
          component="img"
          src={brandImageUrl}
          sx={{
            mt: 2,
            height: '45px',
          }}
        />
      </Stack>
    </Box>
  );
}

export default ProffInfoBanner;
