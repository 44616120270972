import { ArticleType, ModuleType, Tag, Article } from '@dap-sanity/types';
import { groqQuery } from '@shared/utils';
import { SanityReference } from 'sanity-codegen';

export interface GetNewsArticlesPreviewQueryProps {
  brandKey: string;
  brandName: string;
  limit?: number;
  tags: SanityReference<Tag>[];
  articles: SanityReference<Article>[];
}

export const getNewsArticlePreviewsQuery = ({
  brandKey,
  limit,
  tags,
  articles,
}: Omit<GetNewsArticlesPreviewQueryProps, 'brandName'>) => {
  // Select articles that have at least one of the selected tags
  const tagsFilter =
    tags && tags.length > 0
      ? `count((articleTags[]->_id)[@ in [${tags.map((r) => `'${r._ref}'`).join(',')}]]) > 0`
      : undefined;

  // Select specific articles
  const articleFilter =
    articles && articles.length > 0
      ? `_id in [${articles.map((r) => `'${r._ref}'`).join(',')}]`
      : undefined;

  return groqQuery({
    filters: [
      `"${brandKey}" in brand`,
      `_type == "${ArticleType.standard}"`,
      `module == "${ModuleType.newsModule}"`,
      `(defined(publishAt) && dateTime(publishAt) <= dateTime(now()))`,
      articleFilter || tagsFilter,
    ],
    // Ordering will be ignored if predefined articles are used
    ordering: [{ fieldname: 'publishAt', order: 'desc' }],
    projections: [
      '"id": _id',
      '"url": url.current',
      'intro',
      '"image": image.asset->',
      'title',
      'author[]->',
      'publishAt',
    ],
    // Only limit the articles if no predefined articles are used
    slice: limit && !articleFilter ? { start: 0, end: limit } : undefined,
  });
};
