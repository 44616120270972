import { Card, Divider, Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SanityAttachment from './SanityAttachment';
import { Attachment } from '@dap-sanity/types';
import { messages } from '@dap-common/i18n';

interface Props {
  attachments: Attachment[];
  onClickPdfAttachment: (attachment: Attachment) => void;
}

function AttachmentsCard({ attachments, onClickPdfAttachment }: Props) {
  const { t } = useTranslation();

  return (
    <Card sx={{ paddingRight: 4 }}>
      <Typography variant="h4" component="h2">
        {t(messages.common.attachment.label, { ns: 'common' })}
      </Typography>
      <CustomDivider sx={{ marginTop: 1 }} />
      <Stack divider={<CustomDivider />}>
        {attachments.map((attachment) => (
          <SanityAttachment
            {...attachment}
            onClick={() => onClickPdfAttachment(attachment)}
            key={attachment.url}
          />
        ))}
      </Stack>
    </Card>
  );
}

const CustomDivider = styled(Divider)`
  width: 176px;
`;

export default AttachmentsCard;
