import WestRoundedIcon from '@mui/icons-material/WestRounded';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import { IconButton } from '@mui/material';
import { FlexRow } from '../Layout';

interface Props {
  disableNext: boolean;
  disablePrevious: boolean;
  handleNext: () => void;
  handlePrevious: () => void;
}

export default function PaginationArrows({
  disableNext,
  disablePrevious,
  handleNext,
  handlePrevious,
}: Props) {
  return (
    <FlexRow gap={2}>
      <IconButton disabled={disablePrevious} onClick={handlePrevious}>
        <WestRoundedIcon />
      </IconButton>
      <IconButton disabled={disableNext} onClick={handleNext}>
        <EastRoundedIcon />
      </IconButton>
    </FlexRow>
  );
}
