import { SvgIcon, SvgIconProps } from '@mui/material';

const ConnectOmniumIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="34" height="23" viewBox="0 0 34 23">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8582 22.5458C30.7241 22.5458 33.8582 19.4118 33.8582 15.5458C33.8582 11.6798 30.7241 8.54583 26.8582 8.54583C22.9922 8.54583 19.8582 11.6798 19.8582 15.5458C19.8582 19.4118 22.9922 22.5458 26.8582 22.5458ZM22.4122 15.5458C22.4122 14.9935 22.8599 14.5458 23.4122 14.5458H25.8582V12.0998C25.8582 11.5476 26.3059 11.0998 26.8582 11.0998C27.4104 11.0998 27.8582 11.5476 27.8582 12.0998V14.5458H30.3041C30.8564 14.5458 31.3041 14.9935 31.3041 15.5458C31.3041 16.0981 30.8564 16.5458 30.3041 16.5458H27.8582V18.9918C27.8582 19.5441 27.4104 19.9918 26.8582 19.9918C26.3059 19.9918 25.8582 19.5441 25.8582 18.9918V16.5458H23.4122C22.8599 16.5458 22.4122 16.0981 22.4122 15.5458Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0227 0.409379H7.73247C5.29326 0.409379 0.491199 2.95526 0.186304 8.26045C-0.194816 14.8919 4.30239 18.7031 7.73247 19.0842C11.1626 19.4654 14.7451 19.008 16.7269 13.215C17.2465 11.696 17.5933 10.2609 17.9044 8.97285C18.78 5.34853 19.3743 2.88852 22.7486 3.00099C26.0276 3.1103 28.0528 4.78303 28.9365 6.98814C30.1174 7.26224 31.2077 7.77257 32.1549 8.46665C31.8952 2.38902 25.9099 0.551995 22.7486 0.409379C20.5889 0.256932 16.7965 1.37863 15.6598 6.58352C14.2115 13.215 13.2968 16.4926 9.56185 16.4926C5.29326 16.4926 2.85414 12.9451 2.85414 9.78492C2.85414 6.56903 4.91219 3.00099 9.56185 3.00099H15.6598C15.9647 2.44202 16.8641 1.14113 18.0227 0.409379ZM18.841 19.2304C18.4647 18.3903 18.2154 17.4808 18.1178 16.5264C17.4205 16.5256 16.8115 16.5167 16.3458 16.4926C15.7868 17.2295 14.5774 18.7793 14.2115 19.0842C13.9973 19.2628 16.2463 19.2758 18.841 19.2304Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ConnectOmniumIcon;
