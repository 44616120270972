import { captureException, SeverityLevel } from '@sentry/react';

export function reportException(exception: Error, data?: Record<string, unknown>) {
  captureException(exception, {
    extra: data,
  });
}

export function reportMessage(
  message: string,
  severity: SeverityLevel,
  data?: Record<string, unknown>
) {
  captureException(message, {
    level: severity,
    extra: data,
  });
}
