import { groqQuery } from '@shared/utils';

export const getOrderCategoriesQuery = (moduleId: string) =>
  groqQuery({
    filters: [`_id == "${moduleId}"`],
    traversal: 'categories[]->',
    projections: [
      `
      title,
      "id":_id,
      "items":  articles[]->{
        "id": _id,
        intro,
        "image": image.asset->url,
        title,
        price,
        "body": textBlock[],
        includedFeatures,
        optionalFeatures,
        "category": ^.title
      },
      "slug": category_slug.current,
    `,
    ],
  });

export const getOrderCategoryQuery = (moduleId: string, categorySlug: string) =>
  groqQuery({
    filters: [
      "_type=='order_category'",
      `references("${moduleId}")`,
      `category_slug.current == "${categorySlug}"`,
    ],
    projections: [
      `
      title,
      "id":_id,
      "items":  articles[]->{
        "id": _id,
        intro,
        "image": image.asset->url,
        title,
        price,
        "body": textBlock[],
        includedFeatures,
        optionalFeatures,
        "category": ^.title
      },
      "slug": category_slug.current,
      "image": image.asset->url,
      "body": textBlock,
      videoSrc,
      "contacts": person_array[]->
    `,
    ],
    slice: { start: 0 },
  });
