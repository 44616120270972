import { ArrowBack } from '@mui/icons-material';
import { Button, Typography, SxProps, Stack, Theme } from '@mui/material';
import { messages } from '@dap-common/i18n';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

interface Props {
  href?: string;
  sx?: SxProps<Theme>;
}

function GoBackLink({ sx, href }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const styles: SxProps<Theme> = {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    borderRadius: 0,
    gap: 0.5,
    textDecoration: 'underline',
    color: 'common.black',
    border: 'none',
    '&:hover': {
      color: 'common.black',
      bgcolor: 'transparent',
      border: 'none',
      textDecoration: 'none',
    },
    ...sx,
  };

  if (href) {
    return (
      <Link to={href}>
        <Stack sx={styles}>
          <ArrowBack />
          <Typography variant="body1" fontWeight={500}>
            {t(messages.common.goBack)}
          </Typography>
        </Stack>
      </Link>
    );
  }

  return (
    <Button LinkComponent={Link} onClick={() => navigate(-1)} variant="text" sx={styles}>
      <ArrowBack />
      <Typography variant="body1" fontWeight={500}>
        {t(messages.common.goBack)}
      </Typography>
    </Button>
  );
}

export default GoBackLink;
