interface Props {
  src: string;
}

function Embedded23Video({ src }: Props) {
  const regex = RegExp('secret/(.*)/(.*)');
  const matches = regex.exec(src) || [];
  const id = matches['1'];
  const token = matches['2'];

  return (
    <div style={{ position: 'relative', margin: 0, aspectRatio: 16 / 9 }}>
      <iframe
        src={`//x76tfdsl.videomarketingplatform.co/v.ihtml/player.html?token=${token}&source=embed&photo%5fid=${id}`}
        title="embedded video"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'fill',
          top: 0,
          left: 0,
          borderRadius: '8px',
          border: 0,
        }}
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default Embedded23Video;
