import { SvgIcon, SvgIconProps } from '@mui/material';

const AdministratorIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 34">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.919434 15.3059V6.67923C0.919434 6.36724 1.04337 6.06803 1.26398 5.84742C1.39473 5.71667 1.74212 5.61165 2.1358 5.49263C2.40637 5.41083 2.6988 5.32243 2.95779 5.21451L3.06813 5.16859C3.75349 4.88354 5.18792 4.28694 7.90296 3.01992C8.86548 2.57074 9.72478 2.14013 10.471 1.7662L10.471 1.7662C11.95 1.02502 12.9848 0.506508 13.4982 0.507402C13.9431 0.508176 14.7569 0.919908 15.9295 1.51318L15.9295 1.51319C16.7889 1.94802 17.8411 2.48037 19.082 3.01992C22.0162 4.29562 24.04 5.01446 24.4871 5.16153C24.6021 5.19936 24.7736 5.24852 24.9686 5.30443C25.5316 5.46585 26.2909 5.68356 26.4548 5.84742C26.6754 6.06803 26.7993 6.36724 26.7993 6.67923V15.3059C26.7993 27.6599 16.3142 31.7529 14.2206 32.447C13.9865 32.5275 13.7323 32.5275 13.4982 32.447C11.4046 31.7529 0.919434 27.6599 0.919434 15.3059ZM18.1304 10.9909C18.1304 13.3117 16.2336 15.1931 13.8938 15.1931C11.554 15.1931 9.6572 13.3117 9.6572 10.9909C9.6572 8.6701 11.554 6.78872 13.8938 6.78872C16.2336 6.78872 18.1304 8.6701 18.1304 10.9909ZM5.66171 23.3908V20.773C5.6617 20.3195 7.17724 17.1908 13.7905 17.1908C20.4038 17.1908 22.0571 20.2845 22.0571 20.773V23.3908H5.66171Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default AdministratorIcon;
