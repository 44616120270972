import { Action } from '@reduxjs/toolkit';
import { captureException } from '@sentry/react';

export function reportReduxActionException(action: Action, exception: Error, extra?: any) {
  exception.message = `${exception.message} ${action.type}`;
  captureException(exception, {
    extra: {
      action: JSON.stringify(action),
      ...(!!extra && extra),
    },
  });
}
