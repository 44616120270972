import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';
import { forwardRef } from 'react';
import InfoIconWithTooltip from './InfoIconWithTooltip';
import { FormLabelWrapper } from '@shared/components';

interface RadioOption {
  label: string;
  value: string | number | boolean;
  disabled?: boolean;
  icon?: JSX.Element;
  checkedIcon?: JSX.Element;
}

interface Props
  extends Pick<RadioGroupProps, 'onChange' | 'onBlur' | 'name' | 'value' | 'ref' | 'row'> {
  label?: string;
  options: RadioOption[];
  margin?: 'normal' | 'dense' | 'none';
  required?: boolean;
  tooltipText?: string;
  disabled?: boolean;
  error?: string;
  'aria-label': string;
  labelWidth?: number;
  size?: 'small' | 'medium';
}

const InputRadio = forwardRef(
  (
    {
      onBlur,
      onChange,
      value,
      name,
      label,
      options,
      margin = 'normal',
      required,
      tooltipText,
      disabled,
      error,
      row = false,
      labelWidth = 100,
      size = 'medium',
      ...rest
    }: Props,
    ref
  ) => {
    const inputId = `${name}-id`;
    const formLabelId = label && `${name}-label`;
    const helperTextId = error && `${name}-helperText`;

    return (
      <FormControl
        margin={margin}
        error={!!error}
        fullWidth
        required={required}
        disabled={disabled}
        component="fieldset"
        aria-label={rest['aria-label']}
      >
        {label && (
          <FormLabelWrapper direction={row ? 'row' : 'column'} width={labelWidth} size={size}>
            <FormLabel htmlFor={inputId} id={formLabelId}>
              {label}
            </FormLabel>
            {tooltipText && <InfoIconWithTooltip tooltipText={tooltipText} />}
          </FormLabelWrapper>
        )}
        <RadioGroup
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          value={value}
          row={row}
        >
          {options.map((option) => (
            <FormControlLabel
              {...option}
              id={`${option.value}`}
              key={`${option.value}`}
              control={<Radio icon={option.icon} checkedIcon={option.checkedIcon} size={size} />}
            />
          ))}
        </RadioGroup>
        {error && <FormHelperText id={helperTextId}>{error}</FormHelperText>}
      </FormControl>
    );
  }
);

export default InputRadio;
