import { SvgIcon, SvgIconProps } from '@mui/material';

const FremdriftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M16 8C16 6.31057 15.4652 4.6645 14.4721 3.29772C13.4791 1.93094 12.0789 0.913612 10.4721 0.391548L9.23607 4.19577C10.0394 4.45681 10.7396 4.96547 11.2361 5.64886C11.7326 6.33225 12 7.15528 12 8H16Z"
        fill="#453B71"
        stroke="white"
        strokeWidth="0.6"
      />
      <path
        d="M16 8C16 9.68943 15.4652 11.3355 14.4721 12.7023C13.4791 14.0691 12.0789 15.0864 10.4721 15.6085L9.23607 11.8042C10.0394 11.5432 10.7396 11.0345 11.2361 10.3511C11.7326 9.66775 12 8.84472 12 8H16Z"
        fill="#453B71"
        stroke="white"
        strokeWidth="0.6"
      />
      <path
        d="M1.53095 3.29348C2.52486 1.92735 3.92575 0.910946 5.53284 0.389934C7.13992 -0.131078 8.87069 -0.129949 10.4771 0.39316L9.23855 4.19658C8.43535 3.93503 7.56996 3.93446 6.76642 4.19497C5.96288 4.45547 5.26243 4.96368 4.76547 5.64674L1.53095 3.29348Z"
        fill="#453B71"
        stroke="white"
        strokeWidth="0.6"
      />
      <path
        d="M1.53691 12.7147C0.541259 11.3498 0.0032591 9.70479 1.47513e-05 8.01536C-0.00322959 6.32593 0.528448 4.67884 1.51885 3.31015L4.75942 5.65508C4.26422 6.33942 3.99839 7.16297 4.00001 8.00768C4.00163 8.8524 4.27063 9.67492 4.76845 10.3574L1.53691 12.7147Z"
        fill="#453B71"
        stroke="white"
        strokeWidth="0.6"
      />
      <path
        d="M10.4847 15.6043C8.87885 16.1291 7.14808 16.1319 5.54047 15.6125C3.93286 15.0931 2.53095 14.0781 1.53566 12.713L4.76783 10.3565C5.26547 11.0391 5.96643 11.5466 6.77023 11.8063C7.57404 12.066 8.43943 12.0645 9.24237 11.8022L10.4847 15.6043Z"
        fill="#453B71"
        stroke="white"
        strokeWidth="0.6"
      />
    </SvgIcon>
  );
};

export default FremdriftIcon;
