import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { addUserPicture, useAppDispatch } from '@dap-common/data-access';
import { AuthContextProps } from '@dap-common/types';
import { LocalStorage } from '@shared/constants';
import { removeToken, storeToken } from '@shared/state/auth';
import { MGAuthToken } from '@shared/types';
import { jwtDecode } from 'jwt-decode';
import { createContext, useCallback, useContext, useEffect, useState, ReactNode } from 'react';

const AuthContext = createContext<AuthContextProps | null>(null);

function AuthContextContainer({ children }: { children: ReactNode }) {
  const {
    isLoading: auth0IsLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
    getIdTokenClaims,
    error: auth0Error,
  } = useAuth0();
  const [token, setToken] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [initialPath] = useState(window.location.pathname);

  const dispatch = useAppDispatch();

  const refreshToken = useCallback(async () => {
    try {
      const newToken = await getAccessTokenSilently();
      setToken(newToken);
      dispatch(storeToken(newToken));
    } catch (error) {
      console.error(error);
      loginWithRedirect();
    }
  }, [dispatch, getAccessTokenSilently, loginWithRedirect]);

  useEffect(() => {
    let isMounted = true;
    const thresholdPercentage = 0.9; // threshold ın %, used as % of the access token expiration time
    let checkTimeInSeconds = 120; // set a fallback in seconds, cannot be bigger than max access token exp time

    const checkToken = async () => {
      if (!isMounted) return;
      if (!isAuthenticated || !token) return;

      const decodedToken = jwtDecode<MGAuthToken>(token);
      const tokenExpTime = decodedToken.exp; // seconds unix
      const tokenIssueAtTime = decodedToken.iat; // seconds unix

      if (tokenExpTime && tokenIssueAtTime) {
        checkTimeInSeconds = Math.max(120, (tokenExpTime - tokenIssueAtTime) * thresholdPercentage); // use either fallback or 90% of the access token expiration time
        if (shouldReset(tokenExpTime, tokenIssueAtTime, thresholdPercentage)) {
          await refreshToken();
        }
      }
    };
    const interval = setInterval(checkToken, checkTimeInSeconds * 1000);

    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, [token, refreshToken, isAuthenticated]);

  useEffect(() => {
    // @ts-ignore
    if (window.Cypress) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const auth0 = JSON.parse(localStorage.getItem(LocalStorage.AUTH0_CYPRESS)!);
      dispatch(storeToken(auth0.body.access_token));
      setToken(auth0.body.access_token);
    } else {
      const initAuth = async () => {
        if (isAuthenticated) {
          try {
            const token = await getAccessTokenSilently();
            const idToken = await getIdTokenClaims();
            dispatch(storeToken(token));
            setToken(token);
            dispatch(addUserPicture(idToken?.picture || ''));
          } catch (error) {
            console.error(error);
            loginWithRedirect();
          }
        }
        setIsLoading(false);
      };

      if (auth0IsLoading) {
        return;
      }

      initAuth();
    }
  }, [
    isAuthenticated,
    auth0IsLoading,
    dispatch,
    getAccessTokenSilently,
    getIdTokenClaims,
    loginWithRedirect,
  ]);

  const login = useCallback(
    (options?: RedirectLoginOptions) =>
      loginWithRedirect({
        appState: { returnTo: initialPath },
        ...options,
      }),
    [initialPath, loginWithRedirect]
  );

  const logoutAndRemoveToken = useCallback(() => {
    dispatch(removeToken());
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [dispatch, logout]);

  return (
    <AuthContext.Provider
      value={{
        logout: logoutAndRemoveToken,
        login,
        token,
        isAuthenticated,
        error: auth0Error,
        isLoading,
        refreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const shouldReset = (
  tokenExpTime: number,
  tokenIssueAtTime: number,
  thresholdPercentage: number
) => {
  const secondsToExpire = tokenExpTime - tokenIssueAtTime;

  const now = Date.now();
  const expirationThreshold = (now + secondsToExpire) * thresholdPercentage * 1000;

  return expirationThreshold >= tokenExpTime * 1000;
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error(`useAuthContext must be used within a AuthContextProvider`);
  }
  return context;
};

export { useAuthContext, AuthContextContainer, AuthContext };
