import { IconButton, SxProps, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

interface Props {
  tooltipText: React.ReactNode;
}

export default function InfoIconWithTooltip({ tooltipText }: Props) {
  return (
    <Tooltip title={tooltipText} placement="right" arrow>
      <IconButton size="small" color="inherit" sx={infoButtonSx}>
        <InfoIcon sx={infoIconSx} />
      </IconButton>
    </Tooltip>
  );
}

const infoButtonSx: SxProps = {
  p: 0,
  ml: 0.5,
};

const infoIconSx: SxProps = {
  fontSize: 18,
};
