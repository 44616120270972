import { Add } from '@mui/icons-material';
import {
  CardActionArea as MuiCardActionArea,
  Card as MuiCard,
  CardActions as MuiCardActions,
  CardContent as MuiCardContent,
  styled,
  Typography,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PreviewSanityImage } from '../previewContent';
import { previewCardImageHeight } from './constants';
import { FlexRow, PrimaryButton, SecondaryButton } from '@dap-common/ui';
import { NorwegianKroner, truncateString } from '@shared/utils';
import { messages } from '@dap-common/i18n';
import { MouseEvent } from 'react';
import { InfoTooltip, OverflowingText } from '@shared/components';
import { OrderArticle } from '@dap-sanity/types';

interface Props {
  orderArticle: OrderArticle;
  openDialog: () => void;
  width?: number;
  addToCart: () => void;
}

export default function OrderPreviewCard({ orderArticle, openDialog, width, addToCart }: Props) {
  const { t } = useTranslation('order');
  const { title, intro, image, price } = orderArticle;

  const handleShowMore = (event: MouseEvent) => {
    event.stopPropagation();
    openDialog();
  };
  const handleAddToCart = (event: MouseEvent) => {
    event.stopPropagation();
    addToCart();
  };

  return (
    <Card style={{ width: width }}>
      <CardActionArea onClick={openDialog}>
        {image && <PreviewSanityImage height={previewCardImageHeight} image={image} />}
        <CardContent>
          <Stack justifyContent={'space-between'} height={'100%'}>
            <span>
              <OverflowingText tooltipText={title} variant="h2" paragraph>
                {title}
              </OverflowingText>
              <Typography variant="body3">{truncateString(intro, 120)}</Typography>
            </span>
            <FlexRow paddingTop={2}>
              <Typography variant="body3">
                {price
                  ? t(messages.order.price.priceExample, { price: NorwegianKroner.format(price) })
                  : t(messages.order.price.onRequest)}
              </Typography>
              <InfoTooltip
                tooltipText={t([
                  messages.order.price.examplePriceInfo,
                  messages.order.price.summaryInfo,
                ])}
              />
            </FlexRow>
          </Stack>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <SecondaryButton onClick={handleShowMore} variant="outlined">
          <Typography variant="body2">{t(messages.order.order.showMore)}</Typography>
        </SecondaryButton>
        <PrimaryButton startIcon={<Add />} onClick={handleAddToCart} fullWidth>
          <Typography variant="body2">{t(messages.order.order.addItem)}</Typography>
        </PrimaryButton>
      </CardActions>
    </Card>
  );
}

const Card = styled(MuiCard)(() => ({
  '&:hover img': {
    transform: 'scale(1.02)',
  },
  display: 'flex',
  flexDirection: 'column',
}));

const CardContent = styled(MuiCardContent)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  flex: 1,
  width: '100%',
}));

const CardActionArea = styled(MuiCardActionArea)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  '.MuiCardActionArea-focusHighlight': {
    background: 'transparent',
  },
}));

const CardActions = styled(MuiCardActions)(({ theme }) => ({
  padding: 0,
  '&.MuiCardActions-root>:not(:first-of-type)': {
    marginLeft: theme.spacing(2),
  },
}));
