import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from '@mui/material';
import { CSSProperties, forwardRef, useState } from 'react';
import AllOptionCheckbox from './AllOptionCheckbox';
import { CheckboxGroupProps } from './types';
import { FormControlWrapper, FormLabelWrapper, HelperText, InfoTooltip } from '@shared/components';

const CheckboxGroup = forwardRef(
  (
    {
      label,
      options,
      noOptionsText,
      error,
      onChange,
      onBlur,
      name,
      value,
      color,
      disabled,
      margin,
      fullWidth,
      required,
      enableSelectAll = false,
      direction = 'column',
      labelDirection = 'column',
      tooltipText,
      labelWidth = 100,
      size = 'medium',
      customHandleChange,
      checked,
    }: CheckboxGroupProps,
    ref
  ) => {
    const inputId = `${name}-id`;
    const formLabelId = `${name}-label`;
    const helperTextId = `${name}-helperText`;

    const [selectAll, setSelectAll] = useState<boolean>(value.length === options.length);

    const toggleSelectAll = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setSelectAll(true);
        onChange(options.map((option) => option.value));
      } else {
        setSelectAll(false);
        const disabledFilters = options
          .filter(({ disabled }) => disabled)
          .map(({ value }) => value);
        onChange(disabledFilters);
      }
      return;
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        const newValue = [...value, event.target.value];
        onChange(newValue);

        if (enableSelectAll && newValue.length === options.length) {
          setSelectAll(true);
        }
      } else {
        const newValue = value.filter((item: string) => item !== event.target.value);
        onChange(newValue);

        if (enableSelectAll && newValue.length !== options.length) {
          setSelectAll(false);
        }
      }
    };

    return (
      <FormControl
        component="fieldset"
        variant="standard"
        error={!!error}
        margin={margin}
        fullWidth={fullWidth}
        required={required}
        disabled={disabled}
      >
        <FormControlWrapper labelDirection={labelDirection}>
          {label && (
            <FormLabelWrapper direction={labelDirection} width={labelWidth} size={size}>
              {typeof label === 'string' ? (
                <FormLabel htmlFor={inputId} id={formLabelId}>
                  {label}
                </FormLabel>
              ) : (
                label
              )}

              {tooltipText && <InfoTooltip tooltipText={tooltipText} disabled={disabled} />}
            </FormLabelWrapper>
          )}

          <FormGroup id={inputId} style={{ ...(direction === 'row' ? rowStyle : {}) }}>
            {options.length > 0 ? (
              <>
                {enableSelectAll && (
                  <AllOptionCheckbox
                    selectAll={selectAll}
                    toggleSelectAll={toggleSelectAll}
                    name={name}
                    color={color}
                    disabled={disabled}
                  />
                )}

                {options.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    label={option.label}
                    disabled={disabled}
                    control={
                      <Checkbox
                        size="small"
                        name={option.value}
                        checked={checked || value.includes(option.value)}
                        onChange={customHandleChange || handleChange}
                        onBlur={onBlur}
                        value={option.value}
                        id={`${option.value}-${inputId}`}
                        color={color}
                        disabled={option.disabled}
                        style={checkboxStyle}
                      />
                    }
                    componentsProps={{ typography: { variant: 'body1' } }}
                    ref={ref}
                    id={`${option.value}-${formLabelId}`}
                    style={{ marginRight: 0 }} // will be fixed in new mui release https://github.com/mui/material-ui/issues/34039
                  />
                ))}
              </>
            ) : (
              <Typography variant="body1">{noOptionsText}</Typography>
            )}
          </FormGroup>

          {error && (
            <HelperText id={helperTextId} status={'error'}>
              {error}
            </HelperText>
          )}
        </FormControlWrapper>
      </FormControl>
    );
  }
);

export default CheckboxGroup;

const checkboxStyle: CSSProperties = {
  height: '32px',
  marginLeft: '2px',
};

const rowStyle: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, max(200px, 100%/4)), 1fr))',
  rowGap: '8px',
};
