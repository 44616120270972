import { useState } from 'react';

interface Props {
  onChange: (...event: any[]) => void;
  /**
   * The value of the field, i.e. ```field.value```
   */
  value: Array<string>;
}

export const useCheckboxGroup = ({ onChange, value }: Props) => {
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const toggleSelectAll = (checked: boolean, options: any[]) => {
    if (checked) {
      setSelectAll(true);
      onChange(options.map((option) => option.value));
    } else {
      setSelectAll(false);
      const disabledFilters = options.filter(({ disabled }) => disabled).map(({ value }) => value);
      onChange(disabledFilters);
    }
    return;
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    options: any[],
    enableSelectAll: boolean
  ) => {
    if (checked) {
      const newValue = [...value, event.target.value];
      onChange(newValue);

      if (enableSelectAll && newValue.length === options.length) {
        setSelectAll(true);
      }
    } else {
      const newValue = value.filter((item: string) => item !== event.target.value);
      onChange(newValue);

      if (enableSelectAll && newValue.length !== options.length) {
        setSelectAll(false);
      }
    }
  };

  return {
    selectAll,
    toggleSelectAll,
    value,
    handleChange,
  };
};
