import { SvgIcon, SvgIconProps } from '@mui/material';

export const DAPIcon = ({ fontSize, sx }: Pick<SvgIconProps, 'fontSize' | 'sx'>) => {
  return (
    <SvgIcon fontSize={fontSize} sx={sx}>
      <svg viewBox="0 1 53 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.86164 0.960449H0.251953V23.5448H8.86164C8.86164 23.5448 18.745 23.6247 18.745 12.1994C18.745 0.987082 8.86164 0.960449 8.86164 0.960449ZM8.86164 19.0173H4.58227V5.38144H8.86164C8.86164 5.38144 14.2109 5.5146 14.2109 12.1994C14.2109 18.9374 8.86164 19.0173 8.86164 19.0173Z"
          fill="#1A008F"
          stroke="#1A008F"
          strokeMiterlimit="10"
        />
        <path
          d="M44.8948 0.960449H27.166L34.0436 23.5448H38.4758V18.5911H44.8948C44.8948 18.5911 52.5366 18.6178 52.5366 9.53611C52.5366 0.960449 44.8948 0.960449 44.8948 0.960449ZM44.8948 14.1169H38.4758V5.38144H44.8948C44.8948 5.38144 48.1553 5.46134 48.1553 9.6959C48.1553 14.0903 44.8948 14.1169 44.8948 14.1169Z"
          fill="#1A008F"
          stroke="#1A008F"
          strokeMiterlimit="10"
        />
        <path
          d="M27.1669 15.9809H22.3271L24.8744 8.09766L27.1669 15.9809Z"
          fill="#1A008F"
          stroke="#1A008F"
          strokeMiterlimit="10"
        />
        <path
          d="M20.0342 23.5445H29.5609L28.5929 20.4019H21.0021L20.0342 23.5445Z"
          fill="#1A008F"
          stroke="#1A008F"
          strokeMiterlimit="10"
        />
      </svg>
    </SvgIcon>
  );
};
