import { Action } from '@reduxjs/toolkit';
import { AjaxError } from '@shared/fetch-utils';
import { catchError, of } from 'rxjs';
import { AjaxError as RxjsAjaxError } from 'rxjs/ajax';
import { reportReduxActionException } from './reportReduxActionException';

export function catchAndLogError(
  failedAction: Action,
  actionsToDispatch: ((error: AjaxError) => Action)[]
) {
  return catchError((error: RxjsAjaxError) => {
    reportReduxActionException(failedAction, error, {
      message: error.message,
      status: error.status,
      response: error.response,
    });
    
    return of(
      ...actionsToDispatch.map((failureAction) =>
        failureAction({ message: error.message, response: error.response, status: error.status })
      )
    );
  });
}
