import { forwardRef } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const ConnectIcon = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
  <SvgIcon ref={ref} {...props} width="31" height="33" viewBox="0 0 31 33">
    <path
      d="M5.13571 17.4594L15.6645 26.5502L13.5171 29.0373C12.0734 30.7094 9.54753 30.8945 7.87542 29.4508L3.40186 25.5882C1.72975 24.1445 1.54461 21.6186 2.98833 19.9465L5.13571 17.4594Z"
      fill="currentColor"
    />
    <path
      d="M13.2027 12.6126C13.5484 12.2123 14.1531 12.1679 14.5535 12.5136V12.5136C14.9538 12.8593 14.9982 13.4641 14.6525 13.8644L10.7881 18.3402L9.33825 17.0884L13.2027 12.6126Z"
      fill="currentColor"
    />
    <path
      d="M17.8776 16.649C18.2233 16.2486 18.828 16.2043 19.2284 16.55V16.55C19.6287 16.8956 19.6731 17.5004 19.3274 17.9008L15.463 22.3765L14.0131 21.1247L17.8776 16.649Z"
      fill="currentColor"
    />
    <path
      d="M4.64278 15.6704C5.01524 15.2391 5.66689 15.1913 6.09828 15.5638L17.3996 25.3215C17.831 25.694 17.8787 26.3456 17.5063 26.777V26.777C17.1338 27.2084 16.4822 27.2561 16.0508 26.8837L4.74946 17.1259C4.31807 16.7535 4.27031 16.1018 4.64278 15.6704V15.6704Z"
      fill="currentColor"
    />
    <path
      d="M3.94742 26.0593L7.32986 28.9798L6.56591 29.8645C6.20498 30.2826 5.57351 30.3289 5.15548 29.9679L3.28685 28.3545C2.86882 27.9936 2.82254 27.3621 3.18347 26.9441L3.94742 26.0593Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.22191 31.4767C1.96055 31.251 1.93161 30.8562 2.15727 30.5949L3.95137 28.517C4.17704 28.2556 4.57184 28.2267 4.8332 28.4523V28.4523C5.09456 28.678 5.1235 29.0728 4.89784 29.3342L3.10374 31.4121C2.87808 31.6734 2.48327 31.7024 2.22191 31.4767V31.4767Z"
      fill="currentColor"
    />
    <path
      d="M26.7331 6.60144L23.3301 3.705L24.0878 2.81482C24.4457 2.39425 25.0769 2.34349 25.4974 2.70146L27.3774 4.3016C27.798 4.65956 27.8488 5.29069 27.4908 5.71126L26.7331 6.60144Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.4202 1.17195C28.6832 1.39576 28.7149 1.79035 28.4911 2.0533L26.7118 4.14385C26.488 4.4068 26.0934 4.43853 25.8304 4.21473V4.21473C25.5675 3.99092 25.5357 3.59633 25.7595 3.33338L27.5389 1.24282C27.7627 0.979875 28.1573 0.948143 28.4202 1.17195V1.17195Z"
      fill="currentColor"
    />
    <path
      d="M25.6128 15.0283L15.084 5.93751L17.2313 3.45044C18.6751 1.77833 21.2009 1.59319 22.8731 3.03692L27.3466 6.89947C29.0187 8.3432 29.2039 10.8691 27.7601 12.5412L25.6128 15.0283Z"
      fill="currentColor"
    />
    <path
      d="M26.1057 16.8173C25.7332 17.2487 25.0816 17.2964 24.6502 16.9239L13.3489 7.16621C12.9175 6.79374 12.8697 6.14209 13.2422 5.71071V5.71071C13.6147 5.27932 14.2663 5.23156 14.6977 5.60402L25.999 15.3618C26.4304 15.7342 26.4782 16.3859 26.1057 16.8173V16.8173Z"
      fill="currentColor"
    />
  </SvgIcon>
));

export default ConnectIcon;
