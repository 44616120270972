import { Card, Link, Stack, Typography, Avatar } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export interface ContactCardProps {
  name?: string;
  userPicture?: string;
  jobTitle?: string;
  phoneNumber?: string;
  email: string;
}

function ContactCard({ name, userPicture, jobTitle, phoneNumber, email }: ContactCardProps) {
  const showDetails = email || phoneNumber;

  return (
    <Card
      sx={{
        padding: 3,
        height: '100%',
      }}
      variant="outlined"
    >
      <Stack direction="row" alignItems="center" spacing={2} sx={{ marginBottom: 4 }}>
        <Avatar
          alt={name}
          src={userPicture}
          sx={{
            width: 72,
            height: 72,
          }}
        />
        {name && (
          <Stack spacing={0}>
            <Typography variant="h6">{name}</Typography>
            {jobTitle && <Typography variant="body2">{jobTitle}</Typography>}
          </Stack>
        )}
      </Stack>
      {showDetails && (
        <Stack spacing={1} direction="column">
          {email && (
            <Stack direction="row" spacing={3} alignItems="center">
              <MailOutlineIcon fontSize="inherit" />
              <Typography variant="body2">
                <Link href={`mailto:${email}`}>{email}</Link>
              </Typography>
            </Stack>
          )}
          {phoneNumber && (
            <Stack direction="row" spacing={3} alignItems="center">
              <PhoneIcon fontSize="inherit" />
              <Typography variant="body2">
                <Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
    </Card>
  );
}

export default ContactCard;
