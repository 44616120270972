import { SvgIcon, SvgIconProps } from '@mui/material';

const UnlockEntityIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="27" height="27" viewBox="0 0 27 27">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.869 16.0861V16.668H15.9937V14.3008C15.9937 13.4845 16.4405 12.7017 17.2359 12.1245C18.0313 11.5473 19.1101 11.223 20.235 11.223C21.1963 11.223 22.1239 11.4598 22.8698 11.8889V10.5295C22.8698 10.3902 22.8407 10.2524 22.7843 10.125C22.7279 9.99754 22.6456 9.88331 22.5425 9.78958L12.5418 0.697695C12.3577 0.530341 12.1178 0.437614 11.8691 0.437622C11.6203 0.43763 11.3804 0.530374 11.1964 0.69774L1.19705 9.78959C1.09397 9.88331 1.01161 9.99754 0.95525 10.125C0.898893 10.2524 0.869781 10.3902 0.869781 10.5295V22.087C0.869781 22.2184 0.895651 22.3484 0.945914 22.4697C0.996176 22.5911 1.06985 22.7013 1.16272 22.7942C1.25559 22.887 1.36584 22.9607 1.48718 23.0109C1.60852 23.0612 1.73857 23.087 1.86991 23.087L7.86917 23.0862C8.13437 23.0862 8.38869 22.9808 8.5762 22.7933C8.76371 22.6058 8.86905 22.3514 8.86905 22.0862V16.0861C8.86905 15.8209 8.97441 15.5665 9.16194 15.379C9.34948 15.1915 9.60383 15.0861 9.86905 15.0861H13.869C14.1343 15.0861 14.3886 15.1915 14.5762 15.379C14.7637 15.5665 14.869 15.8209 14.869 16.0861Z"
        fill="currentColor"
      />
      <path
        d="M18.0908 17.8139V15.4791C18.0908 14.8599 18.3368 14.266 18.7746 13.8281C19.2125 13.3903 19.8064 13.1443 20.4256 13.1443C21.0448 13.1443 21.6387 13.3903 22.0765 13.8281C22.5144 14.266 22.7604 14.8599 22.7604 15.4791"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2372 17.8139H25.614C25.9006 17.8139 26.1328 18.0462 26.1328 18.3327V25.5965C26.1328 25.8831 25.9006 26.1153 25.614 26.1153H15.2372C14.9506 26.1153 14.7183 25.8831 14.7183 25.5965V18.3327C14.7183 18.0462 14.9506 17.8139 15.2372 17.8139ZM21.605 21.9646C21.605 22.616 21.077 23.144 20.4256 23.144C19.7742 23.144 19.2462 22.616 19.2462 21.9646C19.2462 21.3132 19.7742 20.7852 20.4256 20.7852C21.077 20.7852 21.605 21.3132 21.605 21.9646Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default UnlockEntityIcon;
