import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="31" height="31" viewBox="0 0 31 31" fill={'none'}>
      <path
        d="M13.175 16.0811L10.075 12.9811L7.75 15.3061L13.175 20.7311L23.25 10.6561L20.925 8.33105L13.175 16.0811Z"
        fill={props.color}
      />
    </SvgIcon>
  );
};

export default CheckIcon;
