import { defaultBrandFeatures } from './defaultBrandFeatures';
import { AppFeatureConfig } from '@dap-dashboard/types';

const bademiljoeFeaturesDefault: AppFeatureConfig = {
  ...defaultBrandFeatures,
  completeRegistrationEmailInfo: defaultBrandFeatures.completeRegistrationEmailInfo,
};

export const bademiljoeFeaturesDev: AppFeatureConfig = { ...bademiljoeFeaturesDefault };
export const bademiljoeFeaturesStage: AppFeatureConfig = { ...bademiljoeFeaturesDefault };
export const bademiljoeFeaturesProd: AppFeatureConfig = {
  ...bademiljoeFeaturesDefault,
  completeRegistrationEmailInfo: { resolveToggle: () => 'off' },
};
