import { HelpCenterModuleConfig } from '@dap-sanity/types';
import { replaceBodyBrandNamePlaceholder } from './sanityUtils';

export const transformHelpCentreResponseToHelpCentre = (
  config: HelpCenterModuleConfig,
  brandName: string
): HelpCenterModuleConfig => {
  const { body, ...rest } = config;
  if (!body) return config;
  return {
    body: replaceBodyBrandNamePlaceholder(body, brandName),
    ...rest,
  };
};
