import React, { ReactNode } from 'react';
import { Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { messages } from '@dap-common/i18n';

interface Props {
  children?: ReactNode;
  width?: string;
  padding?: string;
  height?: string;
}

function ErrorCard({ children, width, padding, height }: Props) {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        width,
        height: height || 'max-content',
        maxWidth: '500px',
        padding: padding || '1.5rem',
        margin: '5px',
        border: ({ palette }) => `1px solid ${palette.error.main}`,
      }}
    >
      {children || t(messages.common.error.generic)}
    </Card>
  );
}

export default ErrorCard;
