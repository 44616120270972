import { ObjectType } from './objectType';
import { CustomLinkField } from './customLink';

export enum NavigationItemIconType {
  Briefcase = 'briefcase',
  Calendar = 'calendar',
  Fremdrift = 'fremdrift',
  News = 'news',
}

export interface NavigationItem {
  _type: ObjectType.NavigationItem;
  _key: string;
  label?: string;
  icon: NavigationItemIconType;
  link: CustomLinkField;
}
