export enum LoadingStatus {
  notLoaded = 'notLoaded',
  loading = 'loading',
  success = 'success',
  isUpdating = 'isUpdating',
  error = 'error',
}

export interface LoadingState<DataT, ErrorT> {
  status: LoadingStatus;
  error?: ErrorT;
  data?: DataT;
}
