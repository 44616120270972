import { AppFeatureConfig } from '@dap-dashboard/types';
import { defaultBrandFeatures } from './defaultBrandFeatures';

const bolistFeaturesDefault: AppFeatureConfig = {
  ...defaultBrandFeatures,
  intranetBanner: {
    resolveToggle: () => 'on',
  },
};

export const bolistFeaturesDev: AppFeatureConfig = {
  ...bolistFeaturesDefault,
};
export const bolistFeaturesStage: AppFeatureConfig = {
  ...bolistFeaturesDefault,
};
export const bolistFeaturesProd: AppFeatureConfig = {
  ...bolistFeaturesDefault,
};
