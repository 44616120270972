import { Link, LinkProps } from 'react-router-dom';
import { Tab, TabProps, Tabs as MuiTabs } from '@mui/material';

import { a11yProps } from '@shared/utils';

/* Use the same id as TabPanel */
interface Props<TOption extends string | number> {
  prefixId: string;
  tabs: Array<Pick<TabProps, 'label' | 'icon'> & Pick<LinkProps, 'to'> & { value: TOption }>;
  currentTab: TOption | null;
}

export default function Tabs<TOption extends string | number>({
  prefixId,
  tabs,
  currentTab,
}: Props<TOption>) {
  return (
    <MuiTabs value={currentTab}>
      {tabs.map(({ label, value, to, icon }) => (
        <Tab
          key={value}
          {...a11yProps(prefixId, value)}
          label={label}
          value={value ?? false}
          to={to}
          component={Link}
          icon={icon}
          iconPosition="start"
          sx={{ flex: '1', maxWidth: '250px' }}
        />
      ))}
    </MuiTabs>
  );
}
