import { SxProps, Theme, Palette } from '@mui/material';
import { BlockTextPalette, BlockLinkListPalette } from '@dap-sanity/types';

// Returns the background, text and button colors for a palette
export const getPaletteColors = (
  themePalette: Palette,
  palette: BlockTextPalette | BlockLinkListPalette
): {
  backgroundColor: string;
  textColor: string;
  buttonColor: string;
  buttonSx: SxProps<Theme>;
} => {
  const [backgroundColor, textColor] = getTextColors(themePalette, palette);
  const buttonStyle = getButtonStyle(themePalette, palette);

  const yoda = {
    backgroundColor,
    textColor,
    buttonColor: buttonStyle.color,
    buttonSx: buttonStyle.sx,
  };

  return yoda;
};

// Returns the background and text colors for a palette
export const getTextColors = (
  themePalette: Palette,
  palette: BlockTextPalette | BlockLinkListPalette
): [string, string] => {
  switch (true) {
    case palette === 'white':
      return [themePalette.common.white, themePalette.grey[800]];
    case palette === 'skyBlue':
      // @ts-ignore TODO Typescript - Why does this not validate?
      return [themePalette.info[300], themePalette.grey[800]];
    case palette === 'grey':
    case palette === 'brand' && !themePalette.brand:
      return [themePalette.grey[200], themePalette.grey[800]];
    case palette === 'darkGrey':
      return [themePalette.grey[800], themePalette.common.white];
    case palette === 'midnightBlue':
      return [themePalette.gradient.primary, themePalette.common.white];
    case palette === 'saffron':
      return [themePalette.gradient.secondary, themePalette.grey[800]];
    case palette === 'brand' && !!themePalette.brand:
      return [themePalette.brand.main, themePalette.brand.contrastText];
    default:
      return [themePalette.common.white, themePalette.grey[800]];
  }
};

// Returns the button color to use and sx props to use
export const getButtonStyle = (
  themePalette: Palette,
  palette: BlockTextPalette | BlockLinkListPalette
): { color: string; sx: SxProps<Theme> } => {
  let color = 'inherit';
  let sx: SxProps<Theme> = {
    fontWeight: 600,
  };

  const hasBrandColors = !!themePalette.brand;

  switch (true) {
    case palette === 'white':
      color = hasBrandColors ? 'brand' : 'primary';
      break;
    case palette === 'skyBlue':
      color = hasBrandColors ? 'brand' : 'secondary';
      break;
    case palette === 'grey':
    case palette === 'darkGrey':
    case palette === 'saffron':
    case palette === 'brand' && !hasBrandColors:
      sx = {
        ...sx,
        color: themePalette.common.white,
        background: themePalette.grey[900],
        borderColor: themePalette.grey[900],
        '&:hover': {
          background: themePalette.grey[600],
          borderColor: themePalette.grey[600],
        },
      };
      break;
    case palette === 'midnightBlue':
      sx = {
        ...sx,
        color: themePalette.primary[800],
        background: themePalette.common.white,
        borderColor: themePalette.common.white,
      };
      break;
    case palette === 'brand' && hasBrandColors:
      sx = {
        ...sx,
        color: themePalette.brand.main,
        background: themePalette.brand.contrastText,
        borderColor: themePalette.brand.contrastText,
        '&:hover': {
          background: ({ palette }) => `oklch(from ${palette.brand.contrastText} l c h / 0.8)`,
          borderColor: ({ palette }) => `oklch(from ${palette.brand.contrastText} l c h / 0.8)`,
          color: themePalette.brand.main,
        },
        '&:focus-visible': {
          background: ({ palette }) => `oklch(from ${palette.brand.contrastText} l c h / 0.8)`,
          borderColor: ({ palette }) => `oklch(from ${palette.brand.contrastText} l c h / 0.8)`,
          color: themePalette.brand.main,
        },
      };
      break;
  }

  return {
    color,
    sx,
  };
};
