import { ToggleButton as MuiToggleButton, ToggleButtonProps } from '@mui/material';
import { MouseEvent, PropsWithChildren, forwardRef } from 'react';

interface Props
  extends Pick<
    ToggleButtonProps,
    'children' | 'color' | 'disabled' | 'fullWidth' | 'selected' | 'value'
  > {
  size?: 'small' | 'large';
  // onClick & onChange from ToggleButtonProps gives a "Cannot parse JSDoc tags"-error in storybook
  onClick?: (event: MouseEvent<HTMLElement>, value: any) => void;
  onChange?: (event: MouseEvent<HTMLElement>, value: any) => void;
}

function ToggleButton(
  { children, size = 'large', ...rest }: PropsWithChildren<Props>,
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <MuiToggleButton ref={ref} size={size} {...rest}>
      {children}
    </MuiToggleButton>
  );
}

export default forwardRef(ToggleButton);
