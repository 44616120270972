import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

interface Props {
  minWidth: string;
  minHeight?: string;
  gridGap?: string;
}

const FillMinMax: React.FC<PropsWithChildren<Props>> = ({
  minWidth,
  minHeight,
  gridGap,
  children,
}) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(${minWidth}, 1fr))`,
        gridAutoRows: `minmax(${minHeight}, 1fr)`,
        gridGap: gridGap || '0.5rem',
      }}
    >
      {children}
    </Box>
  );
};

export default FillMinMax;
