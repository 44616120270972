import {
  ContactInfo,
  LocationBasic,
  LocationEmployees,
  LocationOverview,
  LocationProperty,
  Location,
} from '@dap-admin/types';
import {
  EmployeeOverviewDTO,
  LocationBasicDTO,
  LocationContactInfoDTO,
  LocationDetailDTO,
  LocationOverviewDTO,
  LocationPropertyDTO,
} from '@generated-types';
import { employeeBasicMapper } from './employeeMappers';
import {
  addressMapper,
  appBasicMapper,
  contactMapper,
  coordinatesMapper,
  dataownerBasicMapper,
  glnMapper,
  integrationConfigMapper,
  propertiesMapper,
  roleBasicMapper,
} from './mappers';

export function locationEmployeesMapper(overview: EmployeeOverviewDTO): LocationEmployees {
  return {
    activeEmployees: overview.activeEmployees.map((employee) => employeeBasicMapper(employee)),
    inactiveEmployees: overview.activeEmployees.map((employee) => employeeBasicMapper(employee)),
  };
}

export function locationDetailMapper(location: LocationDetailDTO): Location {
  return {
    uuid: '',
    regionName: '',
    isAdmin: false,
    ...location,
    dataowner: dataownerBasicMapper(location.dataowner),
    contactInfo: contactInfoMapper(location.contactInfo),
    integrationDetails: integrationConfigMapper(location.integrationData),
    properties: propertiesMapper(location.properties),
    glns: glnMapper(location.glns),
    apps: appBasicMapper(location.apps),
    locationProperties: locationPropertiesMapper(location.locationProperties),
    availableRoles: roleBasicMapper(location.availableRoles),
    updatedAt: location.updatedAt ?? null,
    updatedBy: location.updatedBy ?? null,
  };
}

export function locationBasicMapper(locations?: LocationBasicDTO[]): LocationBasic[] {
  return locations
    ? locations.map((location) => ({
        uuid: '',
        regionName: '',
        enabled: false,
        chiefExecutive: '',
        countTotalEmployees: 0,
        hasUnverifiedEmployees: false,
        ...location,
        properties: propertiesMapper(location.properties),
        locationProperties: locationPropertiesMapper(location.locationProperties),
      }))
    : [];
}

export function locationOverviewMapper(locations?: LocationOverviewDTO[]): LocationOverview[] {
  return locations
    ? locations.map((location) => ({
        uuid: '',
        regionName: '',
        enabled: false,
        chiefExecutive: '',
        countTotalEmployees: 0,
        hasUnverifiedEmployees: false,
        ...location,
        properties: propertiesMapper(location.properties),
        locationProperties: locationPropertiesMapper(location.locationProperties),
        contactInfo: contactInfoMapper(location.contactInfo),
      }))
    : [];
}

export function locationPropertiesMapper(
  locationPropertiesDTO?: LocationPropertyDTO[]
): LocationProperty[] {
  return locationPropertiesDTO ? locationPropertiesDTO.map(locationPropertyMapper) : [];
}

export function locationPropertyMapper(locationPropertyDTO: LocationPropertyDTO): LocationProperty {
  const locationProperty: LocationProperty = {
    key: locationPropertyDTO.key,
    title: locationPropertyDTO.title,
    description: locationPropertyDTO.description ?? null,
  };
  return locationProperty;
}

export function contactInfoMapper(contactInfoDTO?: LocationContactInfoDTO): ContactInfo {
  return {
    address: addressMapper(contactInfoDTO?.address),
    postalAddress: contactInfoDTO?.postalAddress
      ? addressMapper(contactInfoDTO.postalAddress)
      : null,
    contact: contactMapper(contactInfoDTO?.contact),
    coordinates: coordinatesMapper(contactInfoDTO?.coordinates),
  };
}
