import { useMemo } from 'react';
import { Menu, MenuItem, Stack, Typography, Button, lighten } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { usePopupElement } from '@shared/hooks';
import { brandStylesConfig } from '@dap-common/styles';
import { RecognizedBrands } from '@dap-common/types';
import { useNavigate } from 'react-router-dom';

export interface BrandSelectOption {
  name: string;
  key: string;
  logoSrc?: string;
}

interface Props {
  brands: BrandSelectOption[];
  selectedBrandKey: string;
  selectedTitle?: string;
  onBrandSelect: (brandKey: string) => void;
}

export function BrandSelect({ brands, selectedBrandKey, selectedTitle, onBrandSelect }: Props) {
  const navigate = useNavigate();
  const selectedBrand = useMemo(
    () => brands.find((b) => b.key === selectedBrandKey) as BrandSelectOption,
    [brands, selectedBrandKey]
  );

  // Copied from old SidebarHeader component
  const brandStyle =
    brandStylesConfig[selectedBrandKey as RecognizedBrands] || brandStylesConfig.default;

  const brandColors = brandStyle.colors;
  const mainBrandColor = brandColors?.main;
  const lightBrandColor = brandColors?.light;
  const backgroundColor =
    lightBrandColor || mainBrandColor || brandStylesConfig.default.colors.main;
  const textColor = brandColors?.contrastText || brandStylesConfig.default.colors.contrastText;

  const { menuElementProps, triggerElementProps } = usePopupElement();
  const enableMenu = useMemo(() => brands.length > 1, [brands]);

  return (
    <>
      <Button
        variant="outlined"
        sx={{
          cursors: enableMenu ? 'pointer' : 'default',
          width: 235,
          height: 48,
          background: backgroundColor,
          color: textColor,
          borderColor: '#453B711A',
          boxShadow: 'none',
          '&:hover, &:focus': {
            background: lighten(backgroundColor, 0.1),
            boxShadow: 'none',
            color: textColor,
          },
        }}
        size="small"
        endIcon={
          enableMenu && (
            <ChevronLeft
              sx={{
                transform: menuElementProps.open ? 'rotate(90deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s ease-in-out',
              }}
            />
          )
        }
        {...triggerElementProps}
        onClick={(e) => {
          e.preventDefault();
          if (brands.length > 1) {
            triggerElementProps.onClick(e);
          } else {
            // Navigate to root if clicked when only one brand
            navigate('/');
          }
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          whiteSpace="nowrap"
          gap={2}
        >
          <img
            src={brandStyle.icon}
            alt=""
            height="33px"
            width="33px"
            style={{ margin: 'auto', objectFit: 'contain' }}
          />
          <Typography variant="body1" fontWeight="500">
            {selectedTitle ?? selectedBrand.name}
          </Typography>
        </Stack>
      </Button>

      {enableMenu && (
        <Menu
          {...menuElementProps}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {brands.map((brand) => (
            <MenuItem
              key={brand.key}
              value={brand.key}
              onClick={() => {
                menuElementProps.onClose();
                onBrandSelect(brand.key);
              }}
              selected={brand.key === selectedBrand.key}
            >
              {brand.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
}
