import {
  DateTimeRange,
  EventAddress,
  SanityBlockContent,
  SanityEventPreview,
} from '@dap-sanity/types';
import { format, isSameDay, parseISO } from 'date-fns';

export const sanityBrandNamePlaceholder = '{{brand}}';

export const replacePlaceholderWithBrandName = (text: string, brandName: string) =>
  text.split(sanityBrandNamePlaceholder).join(brandName);

export const replaceBodyBrandNamePlaceholder = (
  sanityBody: SanityBlockContent[],
  brandName: string
): SanityBlockContent[] => {
  return sanityBody.map((bodyPiece) => {
    if (bodyPiece.children) {
      const formattedChildren = bodyPiece.children.map((bodyChild: any) => {
        const text = bodyChild.text
          ? replacePlaceholderWithBrandName(bodyChild.text, brandName)
          : undefined;
        return { ...bodyChild, text };
      });
      return { ...bodyPiece, children: formattedChildren };
    }
    return bodyPiece;
  });
};

export type GroupedEvent = {
  [key: string]: SanityEventPreview[];
};

export const groupEventsByMonthYear = (events: SanityEventPreview[]) => {
  return events.reduce((groupedEvents, event) => {
    const monthYear = format(new Date(event.eventInformation.dateTimeRange.startDate), 'yyyy-MM');
    groupedEvents[monthYear]
      ? groupedEvents[monthYear].push(event)
      : (groupedEvents[monthYear] = [event]);
    return groupedEvents;
  }, {} as GroupedEvent);
};

export function getHrefValueForLink(link: string) {
  if (link.startsWith('https://') || link.startsWith('http://') || link.startsWith('mailto:')) {
    return link;
  }
  return `https://${link}`;
}

export function getFullAddress(address: EventAddress) {
  const postalCodeAndCity =
    address.postalCode && address.city ? `${address.postalCode} ${address.city}` : null;
  const fullAddress = [address.street, postalCodeAndCity, address.addressFreeText]
    .filter(Boolean)
    .join(', ');
  return fullAddress;
}

export function getDateRange(dateTimeRange: DateTimeRange) {
  const startDate = parseISO(dateTimeRange.startDate);
  const endDate = dateTimeRange.endDate ? parseISO(dateTimeRange.endDate) : null;
  const formattedStartDate = format(startDate, 'PPp');

  if (!endDate) {
    return formattedStartDate;
  } else {
    if (isSameDay(startDate, endDate)) {
      return `${formattedStartDate} – ${format(endDate, 'p')}`;
    } else {
      return `${formattedStartDate} – ${format(endDate, 'PPp')}`;
    }
  }
}
