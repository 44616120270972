import { SvgIcon, SvgIconProps } from '@mui/material';

export default function LikeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <ellipse cx="9.9458" cy="9" rx="9.22998" ry="9" fill="url(#paint0_linear_2078_11168)" />
      <path
        d="M6.84961 7.625H5.28711C5.01367 7.625 4.81836 7.83984 4.81836 8.09375V12.7812C4.81836 13.0547 5.01367 13.25 5.28711 13.25H6.84961C7.10352 13.25 7.31836 13.0547 7.31836 12.7812V8.09375C7.31836 7.83984 7.10352 7.625 6.84961 7.625ZM6.06836 12.4688C5.79492 12.4688 5.59961 12.2734 5.59961 12C5.59961 11.7461 5.79492 11.5312 6.06836 11.5312C6.32227 11.5312 6.53711 11.7461 6.53711 12C6.53711 12.2734 6.32227 12.4688 6.06836 12.4688ZM12.3184 4.85156C12.3184 3.40625 11.3809 3.25 10.9121 3.25C10.502 3.25 10.3262 4.03125 10.248 4.38281C10.1309 4.8125 10.0332 5.24219 9.74023 5.53516C9.11523 6.17969 8.7832 6.98047 8.00195 7.74219C7.96289 7.80078 7.94336 7.85938 7.94336 7.91797V12.0977C7.94336 12.2148 8.04102 12.3125 8.1582 12.332C8.4707 12.332 8.88086 12.5078 9.19336 12.6445C9.81836 12.918 10.5801 13.25 11.5176 13.25H11.5762C12.416 13.25 13.4121 13.25 13.8027 12.6836C13.9785 12.4492 14.0176 12.1562 13.9199 11.8047C14.252 11.4727 14.4082 10.8477 14.252 10.3398C14.584 9.89062 14.623 9.24609 14.4277 8.79688C14.6621 8.5625 14.8184 8.19141 14.7988 7.83984C14.7988 7.23438 14.291 6.6875 13.6465 6.6875H11.6543C11.8105 6.14062 12.3184 5.67188 12.3184 4.85156Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2078_11168"
          x1="9.9458"
          y1="0"
          x2="9.9458"
          y2="18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13A0F9" />
          <stop offset="1" stopColor="#0572E6" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
