import { SanityError } from '@dap-sanity/types';
import { Stack, Typography } from '@mui/material';
import { messages } from '@dap-common/i18n';
import { useTranslation } from 'react-i18next';
import { LoadingErrorComponent } from '@dap-common/ui';

const SanityErrorComponent: LoadingErrorComponent<SanityError> = ({ error }) => {
  const { t } = useTranslation('common');
  if (error?.code === 'noResult') {
    return (
      <Stack>
        <Typography variant="h1" gutterBottom>
          {t(messages.common.sanity.error.noResult.title)}
        </Typography>
        <Typography variant="body2">{t(messages.common.sanity.error.noResult.content)}</Typography>
      </Stack>
    );
  }

  return <Typography>{t(messages.common.error.generic)}</Typography>;
};

export default SanityErrorComponent;
