import { AppFeatureConfig } from '@dap-dashboard/types';
import { defaultBrandFeatures } from './defaultBrandFeatures';

const mestergruppenFeaturesDefault: AppFeatureConfig = {
  ...defaultBrandFeatures,
};

export const mestergruppenFeaturesDev: AppFeatureConfig = {
  ...mestergruppenFeaturesDefault,
  mgaBanner: {
    resolveToggle: ({ order_module }) => (order_module?.[0]?.show ? 'on' : 'off'),
  },
};

export const mestergruppenFeaturesStage: AppFeatureConfig = {
  ...mestergruppenFeaturesDefault,
  mgaBanner: {
    resolveToggle: ({ order_module }) => (order_module?.[0]?.show ? 'on' : 'off'),
  },
};
export const mestergruppenFeaturesProd: AppFeatureConfig = {
  ...mestergruppenFeaturesDefault,
  completeRegistrationEmailInfo: { resolveToggle: () => 'off' },
};
