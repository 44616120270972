import { routes } from '@dap-common/consts';
import {
  useGetUserQuery,
  useLanguageSelection,
  useSelectedBrandKey,
} from '@dap-common/data-access';
import { AuthorizedPageContainer } from '@dap-common/modules';
import { ErrorPage, LoadingPage, NotFoundPage } from '@dap-common/pages';
import { RecognizedBrands } from '@dap-common/types';
import { loadable, MainContent } from '@dap-common/ui';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { themeV2 } from '@shared/styles';
import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import AppLayout from './AppLayout';
import { config } from './config';
import { useAuthContext } from './contexts/Auth';
import useGoogleAnalyticsEffect from './utils/useGoogleAnalyticsEffect';
import { APP_BAR_HEIGHT } from '@shared/components';
import { Alert, AlertTitle, Box } from '@mui/material';
import { intranetTheme } from '@shared/styles';

const loadingFallback = {
  fallback: <LoadingPage />,
};

const BrandDetailsPage = loadable(
  () => import('@dap-admin/pages').then(({ BrandDetailsPage }) => ({ default: BrandDetailsPage })),
  loadingFallback
);

const BrandOutlet = loadable(
  () => import('@dap-admin/pages').then(({ BrandOutlet }) => ({ default: BrandOutlet })),
  loadingFallback
);

const BrandAdministratorsContainer = loadable(
  () =>
    import('@dap-admin/modules').then(({ BrandAdministratorsContainer }) => ({
      default: BrandAdministratorsContainer,
    })),
  loadingFallback
);

const BrandConfigContainer = loadable(
  () =>
    import('@dap-admin/modules').then(({ BrandConfigContainer }) => ({
      default: BrandConfigContainer,
    })),
  loadingFallback
);

const BrandProperties = loadable(
  () =>
    import('@dap-admin/modules').then(({ BrandPropertiesContainer }) => ({
      default: BrandPropertiesContainer,
    })),
  loadingFallback
);

const BrandChangelog = loadable(
  () =>
    import('@dap-datahub/feature').then(({ BrandChangelog }) => ({
      default: BrandChangelog,
    })),
  loadingFallback
);

const BrandadminPage = loadable(
  () => import('@dap-admin/pages').then(({ BrandadminPage }) => ({ default: BrandadminPage })),
  loadingFallback
);
const OrganizationViewPage = loadable(() =>
  import('@dap-admin/pages').then(({ OrganizationPage }) => ({
    default: OrganizationPage,
  }))
);
const DataownerPage = loadable(
  () => import('@dap-admin/pages').then(({ DataownerPage }) => ({ default: DataownerPage })),
  loadingFallback
);
const DataownerDetailsContainer = loadable(
  () =>
    import('@dap-admin/modules').then(({ DataownerDetailsContainer }) => ({
      default: DataownerDetailsContainer,
    })),
  loadingFallback
);
const DataownerLocationsContainer = loadable(
  () =>
    import('@dap-admin/modules').then(({ DataownerLocationsContainer }) => ({
      default: DataownerLocationsContainer,
    })),
  loadingFallback
);
const DataownerAdministratorsContainer = loadable(
  () =>
    import('@dap-admin/modules').then(({ DataownerAdministratorsContainer }) => ({
      default: DataownerAdministratorsContainer,
    })),
  loadingFallback
);
const DataownerPropertiesContainer = loadable(
  () =>
    import('@dap-admin/modules').then(({ DataownerPropertiesContainer }) => ({
      default: DataownerPropertiesContainer,
    })),
  loadingFallback
);
const DataownerChangelog = loadable(
  () =>
    import('@dap-datahub/feature').then(({ DataownerChangelog }) => ({
      default: DataownerChangelog,
    })),
  loadingFallback
);

const EmployeePage = loadable(
  () => import('@dap-admin/pages').then(({ EmployeePage }) => ({ default: EmployeePage })),
  loadingFallback
);
const EmployeeDetailsContainer = loadable(
  () =>
    import('@dap-admin/modules').then(({ EmployeeDetailsContainer }) => ({
      default: EmployeeDetailsContainer,
    })),
  loadingFallback
);
const EmployeeChangelog = loadable(
  () =>
    import('@dap-datahub/feature').then(({ EmployeeChangelog }) => ({
      default: EmployeeChangelog,
    })),
  loadingFallback
);

const LocationPage = loadable(
  () => import('@dap-admin/pages').then(({ LocationPage }) => ({ default: LocationPage })),
  loadingFallback
);

const LocationDetailsContainer = loadable(
  () =>
    import('@dap-admin/modules').then(({ LocationDetailsContainer }) => ({
      default: LocationDetailsContainer,
    })),
  loadingFallback
);

const LocationEmployeesContainer = loadable(
  () =>
    import('@dap-admin/modules').then(({ LocationEmployeesContainer }) => ({
      default: LocationEmployeesContainer,
    })),
  loadingFallback
);

const LocationProperties = loadable(
  () =>
    import('@dap-admin/modules').then(({ LocationProperties }) => ({
      default: LocationProperties,
    })),
  loadingFallback
);
const LocationIntegrationsContainer = loadable(
  () =>
    import('@dap-admin/modules').then(({ LocationIntegrationsContainer }) => ({
      default: LocationIntegrationsContainer,
    })),
  loadingFallback
);

const LocationChangelog = loadable(
  () =>
    import('@dap-datahub/feature').then(({ LocationChangelog }) => ({
      default: LocationChangelog,
    })),
  loadingFallback
);

const AllEventsPage = loadable(
  () => import('@dap-dashboard/pages').then(({ AllEventsPage }) => ({ default: AllEventsPage })),
  loadingFallback
);

const AllNewsPage = loadable(
  () => import('@dap-dashboard/pages').then(({ AllNewsPage }) => ({ default: AllNewsPage })),
  loadingFallback
);

const AllServicesPage = loadable(
  () =>
    import('@dap-dashboard/pages').then(({ AllServicesPage }) => ({ default: AllServicesPage })),
  loadingFallback
);

const DashboardPage = loadable(
  () => import('@dap-dashboard/pages').then(({ DashboardPage }) => ({ default: DashboardPage })),
  loadingFallback
);

const EventPage = loadable(
  () => import('@dap-dashboard/pages').then(({ EventPage }) => ({ default: EventPage })),
  loadingFallback
);

const EventsPageOutlet = loadable(
  () =>
    import('@dap-dashboard/pages').then(({ EventsPageOutlet }) => ({ default: EventsPageOutlet })),
  loadingFallback
);

const HelpCentreOutletPage = loadable(
  () =>
    import('@dap-dashboard/pages').then(({ HelpCentreOutletPage }) => ({
      default: HelpCentreOutletPage,
    })),
  loadingFallback
);

const HelpCentrePage = loadable(
  () => import('@dap-dashboard/pages').then(({ HelpCentrePage }) => ({ default: HelpCentrePage })),
  loadingFallback
);

const MePage = loadable(
  () => import('@dap-dashboard/pages').then(({ MePage }) => ({ default: MePage })),
  loadingFallback
);

const ModulePage = loadable(
  () => import('@dap-dashboard/pages').then(({ ModulePage }) => ({ default: ModulePage })),
  loadingFallback
);

const NewsArticlePage = loadable(
  () =>
    import('@dap-dashboard/pages').then(({ NewsArticlePage }) => ({ default: NewsArticlePage })),
  loadingFallback
);

const NewsPageOutlet = loadable(
  () => import('@dap-dashboard/pages').then(({ NewsPageOutlet }) => ({ default: NewsPageOutlet })),
  loadingFallback
);

const ServicePage = loadable(
  () => import('@dap-dashboard/pages').then(({ ServicePage }) => ({ default: ServicePage })),
  loadingFallback
);

const ServicesPageOutlet = loadable(
  () =>
    import('@dap-dashboard/pages').then(({ ServicesPageOutlet }) => ({
      default: ServicesPageOutlet,
    })),
  loadingFallback
);

const TopicPage = loadable(
  () => import('@dap-dashboard/pages').then(({ TopicPage }) => ({ default: TopicPage })),
  loadingFallback
);

const BrandadminLandingPage = loadable(
  () => import('@dap-admin/pages').then(({ LandingPage }) => ({ default: LandingPage })),
  loadingFallback
);

const SanityStudio = loadable(
  () =>
    import('@sanity/studio-embedded').then(({ SanityStudioContainer }) => ({
      default: SanityStudioContainer,
    })),
  loadingFallback
);

const brandStyles = config.styles;

function App() {
  const env = process.env['NX_PUBLIC_REACT_APP_ENV'] || 'local';
  const { logout } = useAuthContext();
  const selectedBrandKey = useSelectedBrandKey();
  const { data: userData } = useGetUserQuery();
  const { initUserLanguage } = useLanguageSelection();
  useGoogleAnalyticsEffect();

  const currentBrandStyle =
    brandStyles[selectedBrandKey as RecognizedBrands] || brandStyles.default;

  useEffect(() => {
    if (userData) {
      initUserLanguage(userData);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.metadata.preferredLanguage]);

  const brandIcon = currentBrandStyle.favicon;

  // Determine if we're on the production dataset in the production project
  const isSanityProductionDataset =
    config.sanity.dataset === 'digital-arbeidsplass' && config.sanity.projectId === 'q2buvpwa';

  // Create memoized theme for the selected brand, if it has brand colors
  const brandTheme = useMemo(() => {
    const hasBrandcolors = !!currentBrandStyle?.colors?.brand;
    return hasBrandcolors
      ? createTheme(intranetTheme, {
          palette: {
            brand: currentBrandStyle?.colors?.brand,
          },
        })
      : undefined;
  }, [selectedBrandKey]);

  return (
    <ErrorBoundary
      fallback={<ErrorPage />}
      beforeCapture={(scope) => {
        scope.setTag('component', 'app');
      }}
    >
      <Helmet>
        <link rel="icon" href={brandIcon} />
        <link rel="mask-icon" href={brandIcon} color="#000000" />
        {env === 'prod' && (
          <script type="text/javascript">
            {`(function (c, l, a, r, i, t, y) {
                c[a] =
                  c[a] ||
                  function () {
                    (c[a].q = c[a].q || []).push(arguments);
                  };
                t = l.createElement(r);
                t.async = 1;
                t.src = 'https://www.clarity.ms/tag/' + i;
                y = l.getElementsByTagName(r)[0];
                y.parentNode.insertBefore(t, y);
              })(window, document, 'clarity', 'script', 'jdh67vcatk');`}
          </script>
        )}
      </Helmet>
      <ThemeProvider theme={brandTheme || intranetTheme}>
        <Routes>
          <Route
            path={routes.root}
            element={
              <AuthorizedPageContainer logoutCallback={logout}>
                <AppLayout>{selectedBrandKey && <Outlet />}</AppLayout>
              </AuthorizedPageContainer>
            }
          >
            <Route path={routes.module}>
              <Route index element={<Navigate to={routes.root} replace />} />
              <Route path={`${routes.mosaic}/${routes.mosaicSlug}/*`} element={<ModulePage />} />
              <Route path={`${routes.order}/${routes.orderSlug}/*`} element={<ModulePage />} />
              <Route path={`${routes.moduleSlug}/*`} element={<ModulePage />} />
            </Route>
            <Route
              path={routes.brandadmin}
              element={
                <ThemeProvider theme={themeV2}>
                  <CssBaseline />
                  <BrandadminPage />
                </ThemeProvider>
              }
            >
              <Route index element={<BrandadminLandingPage />} />
              <Route path={routes.organizationView} element={<OrganizationViewPage />} />
              <Route path={routes.brandDetails} element={<BrandDetailsPage />}>
                <Route element={<BrandOutlet />}>
                  <Route index element={<Navigate to={routes.configurations} replace />} />
                  <Route path={routes.configurations} element={<BrandConfigContainer />} />
                  <Route path={routes.administrators} element={<BrandAdministratorsContainer />} />
                  <Route path={routes.properties} element={<BrandProperties />} />
                  <Route path={routes.changelog} element={<BrandChangelog />} />
                </Route>
              </Route>
              <Route path={routes.dataowner} element={<Outlet />}>
                <Route path={routes.dataownerKey} element={<DataownerPage />}>
                  <Route index element={<Navigate to={routes.details} replace />} />
                  <Route path={routes.details} element={<DataownerDetailsContainer />} />
                  <Route path={routes.locations} element={<DataownerLocationsContainer />} />
                  <Route
                    path={routes.administrators}
                    element={<DataownerAdministratorsContainer />}
                  />
                  <Route path={routes.properties} element={<DataownerPropertiesContainer />} />
                  <Route path={routes.changelog} element={<DataownerChangelog />} />
                </Route>
              </Route>
              <Route path={routes.location} element={<BrandOutlet />}>
                <Route path={routes.locationKey} element={<LocationPage />}>
                  <Route index element={<Navigate to={routes.employees} replace />} />
                  <Route path={routes.employees} element={<LocationEmployeesContainer />} />
                  <Route path={routes.details} element={<LocationDetailsContainer />} />
                  <Route path={routes.properties} element={<LocationProperties />} />
                  <Route path={routes.integrations} element={<LocationIntegrationsContainer />} />
                  <Route path={routes.changelog} element={<LocationChangelog />} />
                </Route>
              </Route>
              <Route path={routes.employee} element={<BrandOutlet />}>
                <Route path={routes.employeeId} element={<EmployeePage />}>
                  <Route index element={<Navigate to={routes.details} replace />} />
                  <Route path={routes.details} element={<EmployeeDetailsContainer />} />
                  <Route path={routes.changelog} element={<EmployeeChangelog />} />
                </Route>
              </Route>
              <Route path={routes.me} element={<MePage />}>
                <Route index element={<Navigate to={routes.details} replace />} />
                <Route path={routes.details} element={<EmployeeDetailsContainer />} />
                <Route path={routes.changelog} element={<EmployeeChangelog />} />
              </Route>
            </Route>
            <Route path={routes.home} element={<DashboardPage config={config} />} />
            <Route path={routes.news} element={<NewsPageOutlet />}>
              <Route path="" element={<AllNewsPage />} />
              <Route path={routes.slug} element={<NewsArticlePage />} />
            </Route>
            <Route path={routes.services} element={<ServicesPageOutlet />}>
              <Route path="" element={<AllServicesPage />} />
              <Route path={routes.slug} element={<ServicePage />} />
            </Route>
            <Route path={routes.events} element={<EventsPageOutlet />}>
              <Route path="" element={<AllEventsPage />} />
              <Route path={routes.slug} element={<EventPage />} />
            </Route>
            <Route path={routes.helpCentre} element={<HelpCentreOutletPage />}>
              <Route path="" element={<HelpCentrePage />} />
            </Route>
            <Route path={routes.topic}>
              <Route path={routes.slug} element={<TopicPage config={config} />} />
            </Route>
            <Route
              path={`${routes.sanity}/*`}
              element={
                <MainContent
                  sx={{
                    position: 'sticky',
                    top: `${APP_BAR_HEIGHT}px`,
                    height: '100%',
                    maxHeight: `calc(100dvh - ${APP_BAR_HEIGHT}px)`,
                    padding: 3,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {!isSanityProductionDataset && (
                    <Alert
                      severity="warning"
                      variant="filled"
                      sx={{
                        marginBottom: 3,
                        color: 'common.white',
                      }}
                    >
                      <AlertTitle>OBS!</AlertTitle>
                      Du jobber ikke mot produksjonsdatasettet, men mot{' '}
                      <strong>{config.sanity.dataset}</strong>.
                    </Alert>
                  )}
                  <Box sx={{ containerType: 'size', flex: 1 }}>
                    <Box
                      sx={{
                        flex: 1,
                        height: '100%',
                        maxHeight: '100cqh',
                      }}
                    >
                      <SanityStudio />
                    </Box>
                  </Box>
                </MainContent>
              }
            />
            <Route path={routes.notFound} element={<NotFoundPage />} />
            <Route path="*" element={<Navigate to={routes.root} replace />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
