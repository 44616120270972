import { Stack, Typography } from '@mui/material';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import PreviewSanityImage from '../previewContent/PreviewSanityImage';
import { PreviewCard } from '@dap-common/ui';
import { maxLinesSx } from '@shared/utils';

interface Props {
  title: string;
  intro: string;
  image: SanityImageSource;
  imgHeight: number;
  toUrl: string;
}

export default function ServicePreviewCard({
  title,
  intro,
  image,
  imgHeight,
  toUrl,
}: Props): JSX.Element {
  return (
    <PreviewCard to={toUrl} sx={{ padding: 3 }}>
      <Stack spacing={1}>
        {image && <PreviewSanityImage height={130} image={image} />}
        <Typography
          variant="h5"
          component="h3"
          sx={{ ...max2LinesTextSx, paddingTop: 1, fontWeight: 700 }}
        >
          {title}
        </Typography>
        <Typography variant="body1" sx={{ ...max3LinesTextSx, lineHeight: 19.2 / 16 }}>
          {intro}
        </Typography>
      </Stack>
    </PreviewCard>
  );
}

const max2LinesTextSx = maxLinesSx(2);
const max3LinesTextSx = maxLinesSx(3);
