import { Card, Stack, Typography, CircularProgress } from '@mui/material';

interface Props {
  title?: string;
}

export function LoadingContentBlockComponent({ title }: Props) {
  return (
    <Card>
      <Stack justifyContent="space-between" alignItems="center" direction="row">
        <Typography variant="h4" component="h2">
          {title}
        </Typography>

        <CircularProgress color="primary" size={28} />
      </Stack>
    </Card>
  );
}
