import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
  styled,
} from '@mui/material';
import { CSSProperties, forwardRef } from 'react';
import AllOptionCheckbox from './AllOptionCheckbox';
import { CheckboxGroupProps } from './types';
import { useCheckboxGroup } from './useCheckboxGroup';
import { FormControlWrapper, FormLabelWrapper, HelperText, InfoTooltip } from '@shared/components';

const CheckboxGroupWithBackground = forwardRef(
  (
    {
      label,
      options,
      noOptionsText,
      error,
      onChange,
      onBlur,
      name,
      value,
      color,
      disabled,
      margin,
      fullWidth,
      required,
      enableSelectAll = false,
      direction = 'column',
      labelDirection = 'column',
      backgroundColor,
      tooltipText,
      labelWidth = 100,
      size = 'medium',
    }: CheckboxGroupProps & { backgroundColor?: string },
    ref
  ) => {
    const inputId = `${name}-id`;
    const formLabelId = `${name}-label`;
    const helperTextId = `${name}-helperText`;

    const { selectAll, toggleSelectAll, handleChange } = useCheckboxGroup({ onChange, value });

    return (
      <FormControl
        component="fieldset"
        variant="standard"
        error={!!error}
        margin={margin}
        fullWidth={fullWidth}
        required={required}
        disabled={disabled}
      >
        <FormControlWrapper labelDirection={labelDirection}>
          {label && (
            <FormLabelWrapper direction={labelDirection} width={labelWidth} size={size}>
              {typeof label === 'string' ? (
                <FormLabel htmlFor={inputId} id={formLabelId}>
                  {label}
                </FormLabel>
              ) : (
                label
              )}

              {tooltipText && <InfoTooltip tooltipText={tooltipText} disabled={disabled} />}
            </FormLabelWrapper>
          )}

          <StyledFormGroup
            style={{ ...(direction === 'row' ? rowStyle : {}) }}
            backgroundColor={backgroundColor}
            id={inputId}
            fullWidth={fullWidth}
          >
            {options.length > 0 ? (
              <>
                {enableSelectAll && (
                  <AllOptionCheckbox
                    selectAll={selectAll}
                    toggleSelectAll={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                      toggleSelectAll(checked, options)
                    }
                    name={name}
                    color={color}
                    disabled={disabled}
                  />
                )}

                {options.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    label={option.label}
                    disabled={disabled}
                    control={
                      <Checkbox
                        size="small"
                        name={option.value}
                        checked={value.includes(option.value)}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                          handleChange(event, checked, options, enableSelectAll)
                        }
                        onBlur={onBlur}
                        value={option.value}
                        id={`${option.value}-${inputId}`}
                        color={color}
                        disabled={option.disabled}
                        style={checkboxStyle}
                      />
                    }
                    componentsProps={{ typography: { variant: 'body1' } }}
                    ref={ref}
                    id={`${option.value}-${formLabelId}`}
                    style={{ marginRight: 0 }} // TODO will be fixed in new mui release https://github.com/mui/material-ui/issues/34039
                  />
                ))}
              </>
            ) : (
              <Typography variant="body1">{noOptionsText}</Typography>
            )}
          </StyledFormGroup>

          {error && (
            <HelperText id={helperTextId} status={'error'}>
              {error}
            </HelperText>
          )}
        </FormControlWrapper>
      </FormControl>
    );
  }
);

export default CheckboxGroupWithBackground;

const checkboxStyle: CSSProperties = {
  height: '32px',
  marginLeft: '2px',
};

const rowStyle: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, max(200px, 100%/4)), 1fr))',
  rowGap: '8px',
};

const StyledFormGroup = styled(FormGroup, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<{ backgroundColor?: string; fullWidth?: boolean }>(({ theme, backgroundColor, fullWidth }) => ({
  flexGrow: fullWidth ? 1 : undefined,
  justifyContent: 'center',
  background: backgroundColor ? backgroundColor : theme.palette.background.paper,
  border: `1px solid ${theme.palette.grey.A100}`,
  paddingInline: 16,
  paddingBlock: 8,
  borderRadius: theme.shape.borderRadius * 3,
}));
