import { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/dist/query/react';
import { UseQueryHookResult } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import React from 'react';
import ErrorDefaultComponent from '../Fallbacks/ErrorDefaultComponent';
import LoadingDefaultComponent from '../Fallbacks/LoadingDefaultComponent';
import { LoadingErrorComponent } from './LoadingErrorComponent';

interface Props<DataT, ErrorT> {
  loadingState: UseQueryHookResult<
    QueryDefinition<any, BaseQueryFn<any, unknown, ErrorT>, never, DataT, string>
  >;
  children: (data: DataT, isFetching: boolean) => JSX.Element;
  LoadingComponent?: React.ComponentType;
  ErrorComponent?: LoadingErrorComponent<ErrorT>;
  NoDataComponent?: React.ComponentType;
  showLoaderWhenFetching?: boolean;
}

function RtkQueryResultWrapper<DataT, ErrorT>({
  loadingState: { data, error, isError, isLoading, isUninitialized, isFetching, refetch },
  LoadingComponent = LoadingDefaultComponent,
  ErrorComponent = ErrorDefaultComponent,
  NoDataComponent = () => null,
  children,
  showLoaderWhenFetching = false,
}: Props<DataT, ErrorT>) {
  if (isUninitialized) {
    return null;
  }

  if (isLoading || (isFetching && showLoaderWhenFetching)) {
    return <LoadingComponent />;
  }

  if (isError) {
    return <ErrorComponent error={error as ErrorT} reload={refetch} />;
  }

  if (data) {
    return children(data, isFetching);
  }

  return <NoDataComponent />;
}

export default RtkQueryResultWrapper;
