import { alpha, ButtonBase, ButtonProps, Stack, Typography } from '@mui/material';
import PDFObject from 'pdfobject';
import { useMemo } from 'react';
import { PDF_FILE_TYPE } from './fileTypes';
import { getFileExtension } from '@shared/utils';
import { Attachment } from '@dap-sanity/types';
import { DownloadIcon } from '@shared/custom-icons';

interface Props extends Attachment {
  onClick: () => void;
}

interface AttachmentButtonProps extends ButtonProps {
  extension: string;
}

export default function SanityAttachment({ label, url, onClick }: Props) {
  const fileExtension = useMemo(() => getFileExtension(url), [url]);
  const fileType = useMemo(() => getFileExtension(url), [url]);
  const fileName = `${label}.${fileType}`;
  const downloadUrl = `${url}?dl=${label}.${fileType}`;

  if (fileType === PDF_FILE_TYPE && PDFObject.supportsPDFs) {
    return (
      <AttachmentButton onClick={onClick} extension={fileExtension ?? ''}>
        {label}
      </AttachmentButton>
    );
  }

  return (
    <AttachmentButton extension={fileExtension ?? ''} href={downloadUrl}>
      {fileName}
    </AttachmentButton>
  );
}

function AttachmentButton({ children, extension, ...props }: AttachmentButtonProps) {
  return (
    <ButtonBase
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBlock: 2,
        marginInline: -1.5,
        paddingInline: 1.5,
        '&:hover,:focus': {
          boxShadow: ({ palette }) => `0 0 10px ${alpha(palette.common.black, 0.1)}`,
        },
      }}
      {...props}
    >
      <Stack
        justifyContent={'space-between'}
        direction="row"
        alignItems="center"
        flexGrow={1}
        gap={1}
        sx={{
          '& > svg': {
            width: '16px',
          },
          '& > svg path': {
            fill: (theme) => theme.palette.primary.main,
          },
        }}
      >
        <Stack>
          <Typography
            variant="body2"
            component="span"
            textAlign="left"
            fontWeight={500}
            sx={{ color: (theme) => theme.palette.grey[900], marginBottom: 0.5 }}
          >
            {children}
          </Typography>
          <Typography
            variant="body3"
            component="span"
            textAlign="left"
            fontWeight={500}
            sx={{ color: (theme) => theme.palette.grey.A400 }}
          >
            .{extension}
          </Typography>
        </Stack>
        <DownloadIcon />
      </Stack>
    </ButtonBase>
  );
}
