export type Predicate<T> = (element: T) => boolean;

export interface Filter<T> {
  active: boolean;
  predicate: Predicate<T>;
}

export const filterList = <T>({
  filters,
  initList,
}: {
  filters: Filter<T>[];
  initList: T[];
}): T[] =>
  filters
    .filter(({ active }) => active)
    .reduce(
      (tmpFiltered, { predicate }) => tmpFiltered.filter(predicate),
      initList
    );
