import { ComponentType } from 'react';
import { Box, Collapse, IconButton, IconProps, List } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useDisclosure } from '@shared/hooks';
import { SidebarAppLink } from './SidebarAppLink';

export type App = {
  key: string;
  name: string;
  url: string;
  Icon: ComponentType<IconProps>;
  default: boolean;
};

type Props = {
  accessibleApps: App[];
  expandedView: boolean;
  visibleApps?: number;
};

export function SidebarAppMenu({ accessibleApps, expandedView, visibleApps = 3 }: Props) {
  const { isOpen, onToggle } = useDisclosure(false);

  const hiddenApps: App[] = accessibleApps?.slice(visibleApps) || [];

  if (accessibleApps?.length) {
    return (
      <List
        sx={{
          padding: '24px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ width: '100%' }}>
          {accessibleApps.slice(0, visibleApps).map((app) => (
            <SidebarAppLink key={`app-link-${app.key}`} app={app} expandedView={expandedView} />
          ))}

          <Collapse id="hidden-apps" in={isOpen} timeout="auto" sx={{ width: '100%' }}>
            {hiddenApps.map((app) => (
              <SidebarAppLink key={`app-link-${app.key}`} app={app} expandedView={expandedView} />
            ))}
          </Collapse>
        </Box>

        {hiddenApps?.length > 0 && (
          <IconButton
            color="primary"
            onClick={onToggle}
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              color: (theme) => theme.palette.grey.A400,
              backgroundColor: (theme) => theme.palette.background.default,
              '&:hover': {
                backgroundColor: (theme) => theme.palette.grey[50],
              },
            }}
          >
            {isOpen ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
          </IconButton>
        )}
      </List>
    );
  }
  return null;
}
