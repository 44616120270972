import { messages } from '@dap-common/i18n';
import { Typography } from '@mui/material';
import { CenterElement } from '@dap-common/ui';
import { useTranslation } from 'react-i18next';
import LandingPageLayout from '../Public/LandingPageLayout';

function ErrorPage() {
  const { t } = useTranslation('common');
  return (
    <LandingPageLayout>
      <CenterElement>
        <div>
          <Typography variant="h2" textAlign="center" gutterBottom>
            {t(messages.common.error.oops)}
          </Typography>
          <Typography variant="body2">{t(messages.common.error.generic)}</Typography>
        </div>
      </CenterElement>
    </LandingPageLayout>
  );
}

export default ErrorPage;
