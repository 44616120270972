import { SvgIcon, SvgIconProps } from '@mui/material';

const ProhibitEntityIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="27" height="27" viewBox="0 0 27 27">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1702 15.6338H9.28394C9.01872 15.6338 8.76437 15.7391 8.57683 15.9267C8.38929 16.1142 8.28394 16.3686 8.28394 16.6338V22.6339C8.28394 22.8991 8.1786 23.1534 7.99109 23.341C7.80358 23.5285 7.54926 23.6339 7.28406 23.6339L1.28479 23.6347C1.15346 23.6347 1.02341 23.6089 0.902071 23.5586C0.780731 23.5084 0.670477 23.4347 0.577605 23.3418C0.484733 23.249 0.411063 23.1387 0.3608 23.0174C0.310538 22.8961 0.284668 22.766 0.284668 22.6347V11.0771C0.284668 10.9378 0.313779 10.8 0.370136 10.6726C0.426493 10.5452 0.508852 10.431 0.611932 10.3373L10.6112 1.24541C10.7953 1.07804 11.0352 0.985299 11.2839 0.985291C11.5327 0.985282 11.7726 1.07801 11.9567 1.24536L21.9574 10.3373C22.0604 10.431 22.1428 10.5452 22.1992 10.6726C22.2556 10.8001 22.2847 10.9378 22.2847 11.0772V12.1981C21.2961 11.7406 20.1947 11.4853 19.0338 11.4853C16.0539 11.4853 13.4668 13.1673 12.1702 15.6338Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4261 15.0223C16.7736 15.0223 14.6233 17.1726 14.6233 19.8251C14.6233 22.4775 16.7736 24.6278 19.4261 24.6278C22.0785 24.6278 24.2288 22.4775 24.2288 19.8251C24.2288 17.1726 22.0785 15.0223 19.4261 15.0223ZM12.6233 19.8251C12.6233 16.068 15.669 13.0223 19.4261 13.0223C23.1831 13.0223 26.2288 16.068 26.2288 19.8251C26.2288 23.5821 23.1831 26.6278 19.4261 26.6278C15.669 26.6278 12.6233 23.5821 12.6233 19.8251Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6158 15.0148C15.0063 14.6243 15.6395 14.6243 16.03 15.0148L24.2363 23.2211C24.6268 23.6116 24.6268 24.2448 24.2363 24.6353C23.8458 25.0258 23.2126 25.0258 22.8221 24.6353L14.6158 16.429C14.2253 16.0385 14.2253 15.4053 14.6158 15.0148Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ProhibitEntityIcon;
