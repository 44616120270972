import { KeyInfoUrlField } from '@dap-sanity/types';
import { Stack, Typography } from '@mui/material';
import InlineExternalLink from './InlineExternalLink';

interface Props {
  link: KeyInfoUrlField;
}

export default function KeyInfoLink({ link }: Props) {
  const href =
    link.url.startsWith('https://') || link.url.startsWith('http://')
      ? link.url
      : `https://${link.url}`;

  return (
    <Stack>
      <Typography variant="h6" component="h3">
        {link.title}
      </Typography>
      <InlineExternalLink variant="body2" href={href} noWrap>
        {href}
      </InlineExternalLink>
    </Stack>
  );
}
