import { LocalStorage } from '@shared/constants';
import { lazy, ReactNode, Suspense } from 'react';

const lazyWithRetry = (componentImport: () => Promise<any>) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(LocalStorage.PAGE_HAS_BEEN_FORCE_REFRESHED) || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(LocalStorage.PAGE_HAS_BEEN_FORCE_REFRESHED, 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // The user is not on the latest version of the application
        // Refresh the page
        window.localStorage.setItem(LocalStorage.PAGE_HAS_BEEN_FORCE_REFRESHED, 'true');
        return window.location.reload();
      }

      // The page has already been reloaded
      throw error;
    }
  });

const loadable = (
  importFunc: () => Promise<any>,
  { fallback = null }: { fallback: ReactNode | null } = { fallback: null }
) => {
  const LazyComponent = lazyWithRetry(importFunc);

  return (props: any) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;
