import { MessagePaths, useMessagesTranslation } from '@shared/i18n';
import { AppResources } from '../resources';

export const useAppTranslation = () => useMessagesTranslation<AppResources>();

export type AppMessageKeys = MessagePaths<AppResources>;

export const useAppTFunction = () => useAppTranslation().t;

export type AppTFunction = ReturnType<typeof useAppTFunction>;
