import { groqQuery } from '@shared/utils';

export interface GetFremdriftArticlesPreviewQueryProps {
  categoryId: string;
  brandKey: string;
}

export const getFremdriftArticlePreviewsQuery = ({
  categoryId,
  brandKey,
}: GetFremdriftArticlesPreviewQueryProps) =>
  groqQuery({
    filters: [
      `_id == "${categoryId}" && defined(articles)`,
      `(!defined(publishAt) || dateTime(publishAt) <= dateTime(now()))`,
      `count((articles[]->brand)["${brandKey}" in @]) > 0`,
    ],
    traversal: `articles["${brandKey}" in @->.brand]->`,
    projections: ['"id": _id', '"url": url.current', 'intro', '"image": image.asset->', 'title'],
  });
