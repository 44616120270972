import { BrandV2 } from '@dap-admin/types';
import { useOutletContext } from 'react-router-dom';

export interface BrandadminRouteOutletState {
  selectedBrand: BrandV2;
}

function useBrandadminRouteOutletContext() {
  const brandadminOutletState = useOutletContext<BrandadminRouteOutletState | undefined>();

  if (!brandadminOutletState) {
    throw new Error(
      `useBrandadminRouteOutletContext is ${brandadminOutletState}. Make sure to use this context in a direct Route child to a Route exposing an Outlet with this context.`
    );
  }

  return brandadminOutletState;
}

export default useBrandadminRouteOutletContext;
