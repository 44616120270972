import { Typography } from '@mui/material';
import { CenterElement } from '../Layout';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useAppTFunction } from '@dap-common/i18n';

function SuccessDefaultComponent() {
  const t = useAppTFunction();

  return (
    <CenterElement flexDirection="column" gap={2} my={1}>
      <Typography variant="h1">{t('common.completed')}</Typography>
      <CheckCircleOutlineIcon color="success" sx={{ fontSize: '100px' }} />
    </CenterElement>
  );
}

export default SuccessDefaultComponent;
