import { useAppTFunction } from '@dap-common/i18n';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Dialog } from '@shared/components';
import React, { PropsWithChildren, useEffect } from 'react';
import { SaveOrCancelEdit } from '../Buttons';

export interface Props {
  value: any;
  open: boolean;
  onClose: (value?: any) => void;
  title: string;
}

function ConfirmationDialog({
  children,
  onClose,
  value: valueProp,
  open,
  title,
}: PropsWithChildren<Props>) {
  const t = useAppTFunction();
  const [value, setValue] = React.useState(valueProp);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose} closeOnBackdropClick={false}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <SaveOrCancelEdit
          cancel={onClose}
          save={() => onClose(value)}
          saveText={t('common.yes')}
          primaryButtonColor="warning"
        />
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
