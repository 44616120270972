import { BrregSearchResult } from '@dap-admin/types';
import { Enheter } from '@generated-types';

export function mapBrregResult(enheter: Enheter): Array<BrregSearchResult> {
  return (
    enheter._embedded?.enheter?.map((enhet) => ({
      name: enhet.navn,
      organizationNumber: enhet.organisasjonsnummer,
    })) || []
  );
}
