export enum BlockType {
  EventList = 'eventList',
  FeaturedArticle = 'featuredArticle',
  Leads = 'leads',
  LegacyBanner = 'legacyBanner',
  LegacyImageBanner = 'legacyImageBanner',
  LegacyIntranetBanner = 'legacyIntranetBanner',
  LegacyMGABanner = 'legacyMGABanner',
  LegacyProffBanner = 'legacyProffBanner',
  LinkList = 'linkList',
  NewsList = 'newsList',
  PersonList = 'personList',
  ServiceMessage = 'serviceMessage',
  Text = 'textBlock',
  WorkplaceFeed = 'workplaceFeed',
}
