import { atom, RecoilState } from 'recoil';
import { useRecoilDisclosure } from '@shared/hooks';

let SidebarAtom: RecoilState<boolean>;

export const useSidebarDisclosure = (initialValue = false) => {
  if (!SidebarAtom) {
    SidebarAtom = atom<boolean>({
      key: 'SidebarState',
      default: initialValue,
    });
  }
  return useRecoilDisclosure(SidebarAtom);
};
