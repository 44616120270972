import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LocalStorage } from '@shared/constants';
import { AjaxError } from '@shared/fetch-utils';

export interface BrandState {
  error?: AjaxError;
  selectedBrandKey: string;
}

export const brandInitialState: BrandState = {
  selectedBrandKey: '',
};

export const brandSlice = createSlice({
  name: 'brand',
  initialState: brandInitialState,
  reducers: {
    selectBrandKey: (state, action: PayloadAction<string>) => {
      state.selectedBrandKey = action.payload;
      localStorage.setItem(LocalStorage.BRAND_KEY, action.payload);
    },
  },
});

export const { selectBrandKey } = brandSlice.actions;

export default brandSlice.reducer;
