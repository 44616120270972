import { KeyInfoType, ModuleType, ArticleType } from '@dap-sanity/types';
import { groqQuery } from '@shared/utils';

export interface GetModuleArticleQueryProps {
  moduleId: string;
  categorySlug: string;
  brandKey: string;
  slug: string;
}

export interface GetStandardArticleQueryProps {
  slug: string;
  moduleType: ModuleType;
}

export interface GetArticleByIdQueryProps {
  id: string;
  modules?: ModuleType[];
}

// Used by Fremdrift- and Proff articles
export const getModuleArticleQuery = ({
  moduleId,
  categorySlug,
  brandKey,
  slug,
}: GetModuleArticleQueryProps) =>
  groqQuery({
    filters: [
      `references("${moduleId}")`,
      `"${brandKey}" in brand`,
      `_id in *[category_slug.current == "${categorySlug}"].articles[]._ref`,
      `url.current == "${slug}"`,
    ],
    projections: [
      '"id": _id',
      '"url": url.current',
      'intro',
      '"image": image.asset->',
      'title',
      `"body": textBlock[]`,
      `attachments[]{
        label,
        "url": asset->url
      }`,
      `"keyInfo": {
        "email": keyInfo.fields[_type match "${KeyInfoType.email}"],
        "link": keyInfo.fields[_type match "${KeyInfoType.link}"],
        "text": keyInfo.fields[_type match "${KeyInfoType.text}"],
      }`,
      `"categoryTitle": *[references(^._id) && defined(title)][0].title`,
    ],
    slice: { start: 0 }, // Only one result
  });

// Get standard article for a specific module and brand
export const getStandardArticleQuery = ({ slug, moduleType }: GetStandardArticleQueryProps) =>
  groqQuery({
    filters: [`url.current == "${slug}"`, `module == "${moduleType}"`],
    projections: [
      `
      "id":_id,
      title,
      "url": url.current,
      brand,
      publishAt,
      "image": image.asset->url,
      intro,
      "body": textBlock,
      "keyInfo": {
        "email": keyInfo.fields[_type match "${KeyInfoType.email}"],
        "link": keyInfo.fields[_type match "${KeyInfoType.link}"],
        "text": keyInfo.fields[_type match "${KeyInfoType.text}"],
      },
      attachments[]{
        label,
        "url": asset->url
      },
      author[]->,
      tags,
      module
    `,
    ],
    slice: { start: 0 }, // Only one result
    ordering: [{ fieldname: '_createdAt', order: 'desc' }],
  });

// Get standard article for a specific module and brand
export const getArticleByIdQuery = ({ id, modules }: GetArticleByIdQueryProps) => {
  return groqQuery({
    filters: [
      `_id == "${id}"`,
      modules && modules.length > 0 ? `module in ['${modules.join(',')}']` : '',
    ],
    projections: [
      `
      "id":_id,
      title,
      "url": url.current,
      brand,
      publishAt,
      "image": image,
      intro,
      "body": textBlock,
      "keyInfo": {
        "email": keyInfo.fields[_type match "${KeyInfoType.email}"],
        "link": keyInfo.fields[_type match "${KeyInfoType.link}"],
        "text": keyInfo.fields[_type match "${KeyInfoType.text}"],
      },
      attachments[]{
        label,
        "url": asset->url
      },
      author[]->,
      tags,
      module
    `,
    ],
    slice: { start: 0 }, // Only one result
  });
};

export interface GetLatestArticlesQueryProps {
  brandKey: string;
  limit?: number;
}

/**
 * Fetches the latest articles for a specific brand
 *
 * Articles are fetched from the following types/module
 * - articles (_type = "article" and module = "newsModule")
 * - event articles (_type = "eventArticle")
 * - module articles (_type = "moduleArticle" and module reference to one of ["fremdrift_module", "proff_module"])
 */

export const getLatestArticlesQuery = ({
  brandKey,
  limit,
}: Omit<GetLatestArticlesQueryProps, 'brandName'>) => {
  // Array of filters pairs (AND) to join with OR
  const typeFilterPairs = [
    // Standard articles
    [`_type == "${ArticleType.standard}"`, `module == "${ModuleType.newsModule}"`],
    // Event articles
    [`_type == "${ArticleType.event}"`],
    // Module articles from fredrift_module or proff_module
    [
      `_type == "${ArticleType.module}"`,
      `module_reference->_type in ["${ModuleType.fremdriftModule}", "${ModuleType.proffModule}"]`,
      'module_reference->show', // Ensure we are only fetching content from visible modules
      `count(*[
        _type == 'category'
        && references(^._id)
        && !(_id in path("drafts.**"))
        && "${brandKey}" in brand
      ]) > 0`, // Only fetch module articles that are added to a category which exists for the brand
    ],
  ];
  const typeFilters = `(${typeFilterPairs.map((pairs) => `(${pairs.join(' && ')})`).join(' || ')})`;

  // Ensure  documents with a publishAt date is published, and only fetch documents that has a publishAt date
  const publishFilterPairs = [[`defined(publishAt)`, 'dateTime(publishAt) <= dateTime(now())']];
  const publishFilters = `(${publishFilterPairs
    .map((pairs) => `(${pairs.join(' && ')})`)
    .join(' || ')})`;

  return groqQuery({
    filters: [
      `"${brandKey}" in brand`,
      '!(_id in path("drafts.**"))', // Prevent fetching drafts
      typeFilters,
      publishFilters,
    ],
    // Project publishAt as publishedAt, in case we wish to coalesce multiple fields at a later stage (_createdAt or _updatedAt)
    projections: [
      `
      "publishedAt": publishAt,
      "id": _id,
      "url": url.current,
      intro,
      title,
      _type,
      module,
      "moduleRef": module_reference->{
        "slug": module_slug.current,
        _type,
      },
      "category": *[
        _type == 'category'
       && references(^._id)
       && !(_id in path("drafts.**"))
       && "${brandKey}" in brand
      ][0] {
        "slug": category_slug.current,
      }
      `,
    ],
    orderAfterProjections: true,
    ordering: [{ fieldname: 'publishedAt', order: 'desc' }],
    slice: limit ? { start: 0, end: limit } : undefined,
  });
};
