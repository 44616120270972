import { UserData } from '@dap-admin/types';
import { getLanguageKeyFromUserLanguage, getUserLanguageSetting } from '@dap-admin/utils';
import { LanguageCapitalLettersCode } from '@shared/types';
import { getDTOLanguageKeyFromResource } from '@shared/utils';
import { setDefaultOptions as setDateFnsLocale } from 'date-fns';
import { enGB, nb, sv } from 'date-fns/locale';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useGetUserQuery, useUpdateUserMutation } from '../../api/userApi';
import { newErrorMessage } from '../snackbar/snackbarReducer';
import { useAppTFunction } from '@dap-common/i18n';

export const useUser = () => {
  const { data } = useGetUserQuery();
  if (!data) {
    throw new Error('No user signed in');
  }

  return data;
};

export const useUserState = () => {
  const state = useGetUserQuery();
  return state;
};

function getPreferredLocale(languageCode: LanguageCapitalLettersCode) {
  switch (languageCode) {
    case LanguageCapitalLettersCode.Norwegian:
      return nb;
    case LanguageCapitalLettersCode.English:
      return enGB;
    case LanguageCapitalLettersCode.Swedish:
      return sv;
    default:
      return nb;
  }
}

export function useLanguageSelection() {
  const { i18n } = useTranslation();
  const { changeLanguage, language } = i18n;
  const t = useAppTFunction();
  const dispatch = useDispatch();
  const [updateUser] = useUpdateUserMutation();

  const switchLanguage = async (
    languageCapitalCode: LanguageCapitalLettersCode,
    previousLanguageCapitalCode?: LanguageCapitalLettersCode
  ) => {
    const preferredLanguage = languageCapitalCode;

    if (previousLanguageCapitalCode !== languageCapitalCode) {
      try {
        await updateUser({ preferredLanguage }).unwrap();
      } catch (error) {
        dispatch(newErrorMessage(t('common.error.update')));
      }
    }

    const isoTwoLetterLanguageCode = getLanguageKeyFromUserLanguage(languageCapitalCode);
    changeLanguage(isoTwoLetterLanguageCode);
    dayjs.locale(isoTwoLetterLanguageCode);
    setDateFnsLocale({ locale: getPreferredLocale(languageCapitalCode) });
  };

  const initUserLanguage = (userData: UserData) => {
    const userLanguageCode = getUserLanguageSetting(userData);
    const languageCapitalLettersCode = getDTOLanguageKeyFromResource(userLanguageCode);

    if (userLanguageCode !== language) {
      switchLanguage(languageCapitalLettersCode, languageCapitalLettersCode);
    }

    setDateFnsLocale({ locale: getPreferredLocale(languageCapitalLettersCode) });
  };

  return {
    initUserLanguage,
    switchLanguage,
    language,
  };
}
