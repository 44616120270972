import { UserData } from '@dap-admin/types';
import { useGetUserQuery } from '@dap-common/data-access';
import {
  NotAuthorizedPage,
  PublicErrorPage,
  PublicLoadingPage,
  LandingPage,
} from '@dap-common/pages';
import { AuthStatus } from '@dap-common/types';
import { brandKeyFromCurrentUrl } from '@dap-common/utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { PropsWithChildren, useMemo } from 'react';
import { useAuthContext } from '../contexts/Auth';

const hasDAPAccess = (user: UserData): boolean => {
  if (brandKeyFromCurrentUrl) {
    return (
      user.metadata.superuser ||
      !!user.brands?.some((brand) => brand.key === brandKeyFromCurrentUrl)
    );
  }
  return user.metadata.superuser || !!user.brands?.length;
};

export function AuthContainer({ children }: PropsWithChildren) {
  const { isAuthenticated, token, error, login, logout, isLoading } = useAuthContext();
  const { data: user, isError: userError } = useGetUserQuery(token ? undefined : skipToken);

  const status = useMemo(() => {
    if (token && user) {
      return hasDAPAccess(user) ? AuthStatus.Authorized : AuthStatus.NotAuthorized;
    }
    if (error || userError) {
      return AuthStatus.Error;
    }
    if (!isAuthenticated && !isLoading) {
      return AuthStatus.NotAuthenticated;
    }
    return AuthStatus.Loading;
  }, [token, user, error, userError, isAuthenticated, isLoading]);

  switch (status) {
    case AuthStatus.Authorized:
      return children;
    case AuthStatus.Error:
      return <PublicErrorPage logoutCallback={logout} />;
    case AuthStatus.NotAuthorized:
      return <NotAuthorizedPage logoutCallback={logout} />;
    case AuthStatus.Loading:
      return <PublicLoadingPage />;
    case AuthStatus.NotAuthenticated:
      return <LandingPage loginCallback={login} />;
  }
}
