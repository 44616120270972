import { Location } from '@dap-admin/types';
import { useOutletContext } from 'react-router-dom';

export interface LocationOutletState {
  selectedLocation: Location;
}

function useLocationOutletContext() {
  const locationOutletState = useOutletContext<LocationOutletState | undefined>();

  if (!locationOutletState) {
    throw new Error(
      `useLocationOutletContext is ${locationOutletState}. Make sure to use this context in a direct Route child to a Route exposing an Outlet with this context.`
    );
  }

  return locationOutletState;
}

export default useLocationOutletContext;
