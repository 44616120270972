import { matchPath, useLocation } from 'react-router-dom';

export const useCurrentTab = (patterns: readonly string[], customPathname?: string) => {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, decodeURIComponent(customPathname || pathname));
    if (possibleMatch !== null) {
      return possibleMatch.pattern.path;
    }
  }

  return null;
};
