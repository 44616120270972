import { Paper as MuiPaper, PaperProps, styled } from '@mui/material';
import { forwardRef, PropsWithChildren } from 'react';

interface Props extends PaperProps {
  /**Should the padding prop not be sufficient, use paddingY = "none" and set the desired padding in the child component.
   * X-padding cannot be overrided. */
  paddingY?: 'none' | 'normal' | 'dense';
  tabPanel?: boolean;
}

const StyledPaper = styled(MuiPaper, {
  shouldForwardProp: (prop) => prop !== 'paddingY' && prop !== 'tabPanel',
})<Pick<Props, 'paddingY' | 'tabPanel'>>(({ theme, paddingY, tabPanel }) => ({
  padding:
    paddingY === 'normal'
      ? theme.spacing(4)
      : paddingY === 'dense'
      ? theme.spacing(3, 4)
      : theme.spacing(0, 4),
  borderRadius: theme.shape.borderRadius * 2,
  borderTopLeftRadius: tabPanel ? 0 : theme.shape.borderRadius * 2,
}));

/**
 * Use as surface in Grid items or as background for content.
 */
const Paper = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ paddingY = 'normal', tabPanel, children, ...rest }, ref) => {
    return (
      <StyledPaper paddingY={paddingY} tabPanel={tabPanel} ref={ref} {...rest}>
        {children}
      </StyledPaper>
    );
  }
);

export default Paper;
