export function getNameFromKey<TValue extends { key: string; name?: string; title?: string }>(
  values: Array<TValue>,
  value: any
): string {
  const label =
    values.find(({ key: id }) => id === value)?.name ||
    values.find(({ key: id }) => id === value)?.title ||
    '';

  return label;
}
