import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { forwardRef, ReactNode, useCallback, useMemo } from 'react';
import { InfoTooltip } from '../Tooltip';
import { FlexRow } from '../Layout';
import { FormControlWrapper, FormLabelWrapper, HelperText } from './Components';

interface MenuItem {
  value: any;
  name: string;
  description?: React.ReactNode;
}

interface Props
  extends Pick<
    SelectProps,
    | 'inputProps'
    | 'fullWidth'
    | 'size'
    | 'onChange'
    | 'onBlur'
    | 'name'
    | 'value'
    | 'disabled'
    | 'placeholder'
    | 'required'
    | 'multiple'
  > {
  menuItems: Array<{ value: any; name: string; description?: React.ReactNode }>;
  label: string;
  labelDirection?: 'row' | 'column';
  reset?: () => void;
  success?: string;
  error?: string;
  tooltipText?: string;
  width?: number;
  margin?: 'normal' | 'dense' | 'none';
  noOptionsText?: string;
  labelWidth?: number;
  renderOption?: (menuItem: MenuItem) => ReactNode;
}

const SelectInputField = forwardRef(
  (
    {
      label,
      required,
      menuItems,
      error,
      placeholder,
      margin = 'dense',
      inputProps,
      fullWidth = false,
      disabled,
      tooltipText,
      size = 'medium',
      reset,
      labelDirection = 'column',
      success,
      multiple,
      width,
      noOptionsText,
      labelWidth = 100,
      renderOption,
      ...rest
    }: Props,
    ref
  ) => {
    const inputId = `${rest.name}-id`;
    const formLabelId = `${rest.name}-label`;
    const helperTextId = `${rest.name}-helperText`;

    const renderMultipleValues = useCallback(
      (selected: any) => {
        if (!Array.isArray(selected)) return selected || placeholder || '';

        const filteredArray: string[] = [];
        selected.forEach((selectedItem: string) => {
          const result = menuItems.find((item) => item.value === selectedItem);
          if (result) {
            filteredArray.push(result.name);
          }
        });

        return filteredArray.length > 0 ? filteredArray.join(', ') : placeholder || '';
      },
      [menuItems, placeholder]
    );

    const renderValue = useMemo(() => {
      if (multiple) {
        return renderMultipleValues;
      }
      if (placeholder && ['', null, undefined].some((val) => val === rest.value)) {
        return () => (
          <Box component="span" color={({ palette }) => palette.text.secondary}>
            {placeholder}
          </Box>
        );
      }
      return undefined;
    }, [multiple, placeholder, renderMultipleValues, rest.value]);

    return (
      <FormControl
        margin={margin}
        error={!!error}
        fullWidth={fullWidth}
        required={required}
        disabled={disabled}
        component={FormControlWrapper}
        labelDirection={labelDirection}
      >
        {label && (
          <FormLabelWrapper direction={labelDirection} width={labelWidth} size={size}>
            <FormLabel htmlFor={inputId} id={formLabelId}>
              {label}
            </FormLabel>
            {tooltipText && <InfoTooltip tooltipText={tooltipText} disabled={disabled} />}
          </FormLabelWrapper>
        )}

        <Box flex="1 0 0">
          <Select
            style={{ ...(!fullWidth && width && { width: width }) }}
            placeholder={placeholder}
            size={size}
            key={inputId}
            inputRef={ref}
            id={inputId}
            labelId={formLabelId}
            aria-describedby={helperTextId}
            aria-label={label}
            displayEmpty
            fullWidth={fullWidth}
            multiple={multiple}
            renderValue={renderValue}
            {...rest}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          >
            {placeholder && <ListSubheader>{placeholder}</ListSubheader>}

            {menuItems.length > 0 ? (
              menuItems.map(({ value: optionValue, name, description }) => {
                if (renderOption) return renderOption({ name, value: optionValue, description });

                return (
                  <MenuItem key={`${inputId}-${optionValue}`} value={optionValue} dense>
                    {multiple && (
                      <Checkbox
                        size="small"
                        edge="start"
                        checked={Array.isArray(rest.value) && rest.value.includes(optionValue)}
                        sx={{ my: '-12px' }}
                      />
                    )}
                    <FlexRow gap={2}>
                      {name} {description}
                    </FlexRow>
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem value="" dense disabled>
                {noOptionsText}
              </MenuItem>
            )}
          </Select>

          {error && (
            <HelperText id={helperTextId} status={'error'}>
              {error}
            </HelperText>
          )}

          {success && (
            <HelperText id={helperTextId} status={'success'}>
              {success}
            </HelperText>
          )}
        </Box>
      </FormControl>
    );
  }
);

export default SelectInputField;
