import { ContactCard } from '@dap-common/ui';
import { useSanityContextActions } from '@dap-sanity/utils';
import { Person } from '@dap-sanity/types';
import { Grid, Typography, Card, SxProps } from '@mui/material';

interface ContactListProps {
  contacts: Person[];
  title?: string;
  noPadding?: boolean;
}

export function ContactList({ contacts, title, noPadding = false }: ContactListProps) {
  const { getImageUrlBuilder } = useSanityContextActions();

  if (!contacts.length) return null;

  const sx: SxProps = noPadding ? { padding: 0 } : {};

  return (
    <Card sx={sx}>
      {title && (
        <Typography variant="h4" sx={{ marginBottom: 3 }}>
          {title}
        </Typography>
      )}
      <Grid
        display="grid"
        container
        gridTemplateColumns="repeat(auto-fill, minmax(min(100%, max(250px, 350px)), 1fr))"
        gap={2}
      >
        {contacts.map((contact) => (
          <Grid item key={contact.email}>
            <ContactCard
              {...contact}
              userPicture={
                contact.image ? getImageUrlBuilder(contact?.image).height(200).url() : ''
              }
              phoneNumber={contact.phone}
              jobTitle={contact.jobtitle}
            />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
}
