import { messages } from '@dap-common/i18n';
import { getDashboardRoute } from '@dap-common/consts';
import { withFormProvider } from '@dap-common/ui';
import { SanityServicePreview, SimpleCategory } from '@dap-sanity/types';
import { Card, Stack, Typography } from '@mui/material';
import { Filter, filterList } from '@shared/utils';
import { Straight, ArrowBackOutlined } from '@mui/icons-material';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import ServiceFilters from './ServiceFilters';
import ServicePreviewsList from './ServicePreviewsList';
import SanityPageHeader from '../pageContent/SanityPageHeader';
import { SanityBlockContent } from '@dap-sanity/types';
import { SanityBody } from '../pageContent';

// import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

interface Props {
  title: string;
  info?: SanityBlockContent[];
  services: SanityServicePreview[];
  allServicesRoute: string;
}

export const filterServicesSchema = yup.object().shape({
  tagSearch: yup.string(),
  categories: yup.array().of(yup.string()),
});

export interface FilterForm {
  tagSearch: string;
  categories: string[];
}

export const getServiceCategoryFilter = (
  checkedCategories: string[]
): Filter<SanityServicePreview> => ({
  active: checkedCategories.length > 0,
  predicate: (service) =>
    checkedCategories.some((checkedCategory) =>
      service.categories?.some((category) => category.id === checkedCategory)
    ),
});

export const getTagSearchFilter = (tagSearch: string): Filter<SanityServicePreview> => ({
  active: !!tagSearch,
  predicate: (service) =>
    service.tags
      ? service.tags.some((tag) =>
          tag.toLocaleLowerCase('no').includes(tagSearch.toLocaleLowerCase('no'))
        )
      : false,
});

function ServicesPage({ title, info, services, allServicesRoute }: Props) {
  const { t } = useTranslation(['common', 'services']);
  const { watch } = useFormContext<FilterForm>();
  const checkedCategories = watch('categories');
  const tagSearch = watch('tagSearch');

  const categoryFilter = useMemo(
    () => getServiceCategoryFilter(checkedCategories),
    [checkedCategories]
  );

  const searchTagFilter = useMemo(() => getTagSearchFilter(tagSearch), [tagSearch]);

  // Create array of services matching filters
  const filteredPreviews = useMemo(
    () =>
      filterList({
        filters: [categoryFilter, searchTagFilter],
        initList: services,
      }),
    [categoryFilter, searchTagFilter, services]
  );

  // Create array of all unique categories from all services
  const availableCategories = useMemo(() => {
    const allCategories: SimpleCategory[] =
      services?.flatMap(({ categories }) => categories ?? []) || [];
    const uniqueCategories = allCategories.reduce((accumulator, current) => {
      if (!accumulator.find((obj) => obj.id === current.id)) {
        accumulator.push(current);
      }
      return accumulator;
    }, [] as SimpleCategory[]);
    return uniqueCategories;
  }, [services]);

  // Group filtered services by category
  const categorizedServices = useMemo(
    () =>
      filteredPreviews.reduce((acc, service) => {
        service.categories?.forEach((category) => {
          // If we have selected any categories, only show services that match the selected categories
          if (checkedCategories.length > 0 && !checkedCategories.includes(category.id)) {
            return;
          }
          if (!acc[category.title]) {
            acc[category.title] = [];
          }
          acc[category.title].push(service);
        });
        return acc;
      }, {} as Record<string, SanityServicePreview[]>),
    [filteredPreviews]
  );

  // Sorted groups by number of services in each category
  const sortedCategories = useMemo(
    () =>
      Object.entries(categorizedServices)
        .sort(([, servicesA], [, servicesB]) => servicesB.length - servicesA.length)
        .reduce((acc, [title, services]) => {
          acc[title] = services;
          return acc;
        }, {} as Record<string, SanityServicePreview[]>),
    [categorizedServices]
  );

  return (
    <Stack spacing={2}>
      <Card>
        <SanityPageHeader
          // breadcrumbs={[
          //   {
          //     text: title,
          //   },
          // ]}
          backLink={getDashboardRoute()}
          header={title}
          sx={{ marginBottom: 3 }}
        />
        {info && <SanityBody body={info} />}
        <ServiceFilters availableCategories={availableCategories} />
      </Card>

      {/* Iterate categorized services*/}
      {Object.entries(sortedCategories).map(([category, services]) => (
        <Card key={category}>
          <Typography variant="h3" component="h2" sx={{ marginBottom: 3 }}>
            {category}
          </Typography>
          <ServicePreviewsList
            servicePreviews={services}
            emptyListMessage={t(messages.services.filters.noMatch, { ns: 'services' })}
          />
        </Card>
      ))}
    </Stack>
  );
}

export default withFormProvider<FilterForm, Props>(ServicesPage, () => ({
  schema: filterServicesSchema,
  defaultValues: {
    categories: [],
    tagSearch: '',
  },
  mode: 'onChange',
}));
