import { SanityImageSource, SanityProjectDetails } from '@sanity/image-url/lib/types/types';
import React, { useCallback, useMemo } from 'react';
import imageUrlBuilder from '@sanity/image-url';
import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';

export type GetImageUrlType = (image: SanityImageSource) => ImageUrlBuilder;

export interface SanityContextType {
  state: {
    projectDetails: SanityProjectDetails;
  };
  actions: {
    getImageUrlBuilder: GetImageUrlType;
  };
}

export const SanityContext = React.createContext<SanityContextType | null>(null);

interface ProviderProps {
  projectDetails: SanityProjectDetails;
  children: React.ReactNode;
  imageResolver?: GetImageUrlType;
}

const getDefaultImageResolver =
  (details: SanityProjectDetails): GetImageUrlType =>
  (image) => {
    // Make imageSource mutable by deep copying, because Sanity's imageUrlBuilder mutates images with crop/hotspot properties
    const mutableImageSource = JSON.parse(JSON.stringify(image)) as SanityImageSource;

    return imageUrlBuilder(details).image(mutableImageSource);
  };

export function SanityContextProvider({
  projectDetails,
  children,
  imageResolver = getDefaultImageResolver(projectDetails),
}: ProviderProps) {
  const getImageUrlBuilder: GetImageUrlType = useCallback(imageResolver, [imageResolver]);
  const contextValue: SanityContextType = useMemo(
    () => ({
      state: { projectDetails },
      actions: { getImageUrlBuilder },
    }),
    [getImageUrlBuilder, projectDetails]
  );

  return <SanityContext.Provider value={contextValue}>{children}</SanityContext.Provider>;
}
