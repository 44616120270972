import { ButtonProps, Tooltip, TooltipProps } from '@mui/material';
import { forwardRef } from 'react';
import ContainedButton, { ContainedButtonProps } from './ContainedButton';

interface Props extends ContainedButtonProps<'button'> {
  label: string;
  tooltipTitle: string;
  tooltipPlacement?: TooltipProps['placement'];
}

function ContainedButtonWithTooltipBase(
  { disabled, label, tooltipTitle, tooltipPlacement, ...rest }: Props,
  ref: ButtonProps['ref']
) {
  return (
    <Tooltip
      disableFocusListener={!disabled}
      disableHoverListener={!disabled}
      title={tooltipTitle}
      placement={tooltipPlacement}
    >
      {/* add span => trigger if button is disabled */}
      <span>
        <ContainedButton disabled={disabled} {...rest} ref={ref}>
          {label}
        </ContainedButton>
      </span>
    </Tooltip>
  );
}

const ContainedButtonWithTooltip = forwardRef(ContainedButtonWithTooltipBase);

export default ContainedButtonWithTooltip;
