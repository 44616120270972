import { Stack, SxProps, Typography } from '@mui/material';
import { PreviewCard } from '@dap-common/ui';
import { FillMinMax } from '@shared/components';
import PreviewSanityImage from '../previewContent/PreviewSanityImage';
import { previewCardHeight, previewCardImageHeight } from './fremdriftConstants';
import { SanityModuleArticle } from '@dap-sanity/types';
import { maxLinesSx } from '@shared/utils';

interface Props {
  articles: Array<SanityModuleArticle>;
  categorySlug: string;
}

export default function FremdriftArticleList({ articles, categorySlug }: Props) {
  return (
    <FillMinMax minWidth="260px" gridGap="2.5rem 1.5rem">
      {articles.map(({ id, url, image, title, intro }) => (
        <PreviewCard to={`${categorySlug}/${url}`} key={id}>
          <Stack spacing={2}>
            {image && <PreviewSanityImage height={144} image={image} noBorderRadius />}
            <Stack paddingInline={3} paddingBottom={3} spacing={2}>
              <Typography
                variant="h6"
                sx={{ ...max2LinesTextSx, lineHeight: 21.24 / 18 }}
                component="span"
              >
                {title}
              </Typography>
              <Typography variant="body2" sx={max3LinesTextSx}>
                {intro}
              </Typography>
            </Stack>
          </Stack>
        </PreviewCard>
      ))}
    </FillMinMax>
  );
}

const max2LinesTextSx = maxLinesSx(2);
const max3LinesTextSx = maxLinesSx(3);

// const previewCardSx: SxProps = {
//   height: previewCardHeight,
// };
