import {
  DISABLE_EMPLOYEE,
  EMPLOYEE_APPS,
  EMPLOYEE_ID_V2,
  EMPLOYEE_LOGS,
  EMPLOYEE_REFRESH,
  EMPLOYEE_ROLES,
  EMPLOYEE_SEND_INITIAL_PASSWORD_EMAIL,
  EMPLOYEE_V2,
  EMPLOYEE_V2_REMOVE_CONNECTIONS,
  ENABLE_EMPLOYEE,
  SEARCH_EMPLOYEE,
} from '@dap-admin/config';
import {
  AddAppToEmployee,
  AddRolesToEmployee,
  CreateEmployee,
  EmployeeDetail,
  EmployeeSearchResult,
  PatchEmployeeDto,
  RemoveAppFromEmployee,
  RemoveRolesFromEmployee,
} from '@dap-admin/types';
import { employeeDetailsMapper, employeeSearchMapper } from '@dap-common/utils';
import {
  EmployeeDetailDTO,
  EmployeeSearchDTO,
  EmployeeSearchDTO as EmployeeSearchResponse,
  FindUser,
  UserAuth0Log,
} from '@generated-types';
import { brandadminApi } from './brandadminApi';
import { BrandTags, DataownerTags } from './tags';
import { LocationTags } from './tags';
import { EmployeeTags } from './tags';
import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query';

const tags = [
  ...Object.values(EmployeeTags),
  DataownerTags.EMPLOYEES,
  LocationTags.LOCATION_EMPLOYEES,
  BrandTags.BRAND_EMPLOYEES,
];

export const employeeApi = brandadminApi.enhanceEndpoints({ addTagTypes: tags }).injectEndpoints({
  endpoints: (build) => ({
    createEmployee: build.mutation<EmployeeDetail, CreateEmployee>({
      query: (payload) => ({
        url: EMPLOYEE_V2,
        method: 'POST',
        body: payload, // TODO: Use createEmployeeMapper
      }),
      transformResponse: (response: EmployeeDetailDTO) => employeeDetailsMapper(response),
      invalidatesTags: (result, _error, arg) =>
        result
          ? [
              ...(arg.dataowners.length > 0 ? [DataownerTags.EMPLOYEES] : []),
              ...(arg.locations.length > 0
                ? [LocationTags.LOCATION_EMPLOYEES, LocationTags.SEARCH_LOCATIONS]
                : []),
              ...(arg.brands.length > 0 ? [BrandTags.BRAND_EMPLOYEES] : []),
              DataownerTags.SEARCH_DATAOWNERS,
              LocationTags.SEARCH_LOCATIONS,
              EmployeeTags.SEARCH_EMPLOYEES,
            ]
          : [],
    }),
    getEmployee: build.query<EmployeeDetail, { userId: string }>({
      query: ({ userId }) => ({ url: EMPLOYEE_ID_V2(userId) }),
      transformResponse: (response: EmployeeDetailDTO) => employeeDetailsMapper(response),
      providesTags: (_result, _error, arg) => [{ type: EmployeeTags.EMPLOYEE, id: arg.userId }],
    }),
    searchEmployee: build.mutation<EmployeeSearchResult[], FindUser>({
      query: (payload) => ({
        url: SEARCH_EMPLOYEE,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response: EmployeeSearchResponse[], _, arg) => {
        const singleMatch = response.filter(
          (employee) =>
            employee.email?.toLocaleLowerCase() === arg.filters.query?.toLocaleLowerCase()
        );
        return employeeSearchMapper(singleMatch);
      },
    }),
    searchEmployees: build.query<EmployeeSearchResult[], FindUser>({
      query: (payload) => ({
        url: SEARCH_EMPLOYEE,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response: EmployeeSearchDTO[], _, _arg) => employeeSearchMapper(response),
      providesTags: [EmployeeTags.SEARCH_EMPLOYEES],
    }),
    addRolesToEmployee: build.mutation<EmployeeDetail, AddRolesToEmployee>({
      query: ({ userId, roles }) => ({
        url: EMPLOYEE_ROLES(userId),
        method: 'PUT',
        body: roles, // TODO: Should map to correct payload DTO
      }),
      transformResponse: (response: EmployeeDetailDTO) => employeeDetailsMapper(response),
      invalidatesTags: (result, _error, arg) =>
        result
          ? [{ type: EmployeeTags.EMPLOYEE, id: arg.userId }, EmployeeTags.SEARCH_EMPLOYEES]
          : [],
    }),
    removeRolesFromEmployee: build.mutation<void, RemoveRolesFromEmployee>({
      query: ({ userId, roles }) => ({
        url: EMPLOYEE_ROLES(userId),
        method: 'DELETE',
        body: roles, // TODO: Should map to correct payload DTO
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: EmployeeTags.EMPLOYEE, id: arg.userId },
        EmployeeTags.SEARCH_EMPLOYEES,
      ],
    }),
    addAppsToEmployee: build.mutation<EmployeeDetail, AddAppToEmployee>({
      query: ({ userId, apps }) => ({
        url: EMPLOYEE_APPS(userId),
        method: 'PUT',
        body: apps,
      }),
      transformResponse: (response: EmployeeDetailDTO) => employeeDetailsMapper(response),
      invalidatesTags: (result, _error, arg) =>
        result
          ? [{ type: EmployeeTags.EMPLOYEE, id: arg.userId }, EmployeeTags.SEARCH_EMPLOYEES]
          : [],
    }),
    removeAppsFromEmployee: build.mutation<void, RemoveAppFromEmployee>({
      query: ({ userId, apps }) => ({
        url: EMPLOYEE_APPS(userId),
        method: 'DELETE',
        body: apps,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: EmployeeTags.EMPLOYEE, id: arg.userId },
        EmployeeTags.SEARCH_EMPLOYEES,
      ],
    }),
    editBasicEmployeeDetails: build.mutation<
      EmployeeDetail, // return status: string and not Array<string> MID-894
      { payload: PatchEmployeeDto; userId: string }
    >({
      query: ({ payload, userId }) => ({
        url: EMPLOYEE_ID_V2(userId),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: EmployeeTags.EMPLOYEE, id: arg.userId }],
    }),
    resendInitialPasswordEmail: build.mutation<null | string, { userId: string }>({
      query: (payload) => ({
        url: EMPLOYEE_SEND_INITIAL_PASSWORD_EMAIL(payload.userId),
        method: 'POST',
      }),
      transformResponse: (_response, meta: FetchBaseQueryMeta, _arg) => {
        if (meta.response?.status === 210) return '210'; // Must differentiate between 201 (success) and 210 (email already sent)
        return null;
      },
      invalidatesTags: (result, _error, arg) => [{ type: EmployeeTags.EMPLOYEE, id: arg.userId }],
    }),
    getEmployeeLog: build.query<Array<UserAuth0Log>, { userId: string }>({
      query: ({ userId }) => ({ url: EMPLOYEE_LOGS(userId) }),
    }),
    activateUserAccount: build.mutation<EmployeeDetail, { userId: string }>({
      query: (payload) => ({
        url: ENABLE_EMPLOYEE(payload.userId),
        method: 'POST',
      }),
      transformResponse: (response: EmployeeDetailDTO) => employeeDetailsMapper(response),
      invalidatesTags: (_result, _error, arg) => [{ type: EmployeeTags.EMPLOYEE, id: arg.userId }],
    }),
    deactivateUserAccount: build.mutation<EmployeeDetail, { userId: string }>({
      query: (payload) => ({
        url: DISABLE_EMPLOYEE(payload.userId),
        method: 'POST',
      }),
      transformResponse: (response: EmployeeDetailDTO) => employeeDetailsMapper(response),
      invalidatesTags: (_result, _error, arg) => [{ type: EmployeeTags.EMPLOYEE, id: arg.userId }],
    }),
    deleteUserAccount: build.mutation<void, { userId: string; deepDeletion: boolean }>({
      query: (payload) => ({
        url: `${EMPLOYEE_ID_V2(payload.userId)}?deep=${payload.deepDeletion}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: EmployeeTags.EMPLOYEE, id: arg.userId }],
    }),
    removeEmployeeFromBrand: build.mutation<void, { userId: string; brandKey: string }>({
      query: (payload) => ({
        url: EMPLOYEE_V2_REMOVE_CONNECTIONS(payload.userId),
        method: 'PUT',
        body: { brand: payload.brandKey },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: EmployeeTags.EMPLOYEE, id: arg.userId }],
    }),
    refreshUserData: build.mutation<EmployeeDetailDTO, { userId: string }>({
      query: (payload) => ({
        url: EMPLOYEE_REFRESH(payload.userId),
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: EmployeeTags.EMPLOYEE, id: arg.userId }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetEmployeeQuery,
  useCreateEmployeeMutation,
  useSearchEmployeeMutation,
  useSearchEmployeesQuery,
  useAddRolesToEmployeeMutation,
  useRemoveRolesFromEmployeeMutation,
  useAddAppsToEmployeeMutation,
  useRemoveAppsFromEmployeeMutation,
  useEditBasicEmployeeDetailsMutation,
  useResendInitialPasswordEmailMutation,
  useGetEmployeeLogQuery,
  useActivateUserAccountMutation,
  useDeactivateUserAccountMutation,
  useDeleteUserAccountMutation,
  useRemoveEmployeeFromBrandMutation,
  useRefreshUserDataMutation,
} = employeeApi;
