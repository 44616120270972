import {
  useAppSelector,
  useGetBrandQuery,
  useSelectedBrandKey,
  useUser,
} from '@dap-common/data-access';
import { Box, Divider, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { AppType } from '@dap-common/types';
import { useNavRoutes } from './hooks/useNavRoutes';
import { useSidebarDisclosure } from '@shared/stores';
import {
  Navigation,
  App,
  Sidebar as SharedSidebar,
  SidebarHeader,
  SidebarUserMenu,
  SidebarAppMenu,
  APP_BAR_HEIGHT,
} from '@shared/components';
import { HelpCenter } from '@mui/icons-material';
import { appLogoIcons } from '@assets/appLogos';
import { getUserAppsForBrand } from '@dap-admin/utils';
import { useTranslation } from 'react-i18next';

interface SidebarProps {
  logoutCallback: () => void;
}

function Sidebar({ logoutCallback }: SidebarProps) {
  const { t } = useTranslation('common');
  const { spacing, palette } = useTheme();

  const {
    isOpen: isSidebarOpen,
    onToggle: toggleSidebar,
    onOpen: openSidebar,
  } = useSidebarDisclosure(true);

  const userData = useUser();

  const selectedBrandKey = useSelectedBrandKey();
  const { brandApps } = useGetBrandQuery(selectedBrandKey, {
    selectFromResult: ({ data }) => ({
      brandApps: data?.apps ?? [],
    }),
  });

  const userApps = useMemo(() => {
    const availableApps = getUserAppsForBrand(brandApps, userData.apps)?.filter(
      (app) => app.key !== AppType.BrandAdmin
    );

    return availableApps.map((app) => {
      return {
        key: app.key,
        name: app.name,
        url: app?.url ?? '',
        default: false,
        Icon: appLogoIcons[app.key] ?? HelpCenter,
      } as App;
    });
  }, [brandApps, userData.apps]);

  const userPicture = useAppSelector((state) => state.user.userPicture);
  const navRoutes = useNavRoutes();

  const username = useMemo(
    () =>
      userData && userData.name ? userData.name : `${userData?.givenName} ${userData?.familyName}`,
    [userData]
  );

  if (userData)
    return (
      <Box
        sx={{
          paddingBottom: 3,
          zIndex: 1,
          '& .MuiPaper-root': {
            borderTopRightRadius: spacing(1),
            borderBottomRightRadius: spacing(1),
            position: 'static',
            height: 'auto',
          },
          '& .MuiPaper-root > .MuiBox-root': {
            position: 'static',
            height: 'auto',
          },
          '& .MuiButtonBase-root.Mui-selected': {
            background: palette.grey[700],
          },
          '& .MuiButtonBase-root.Mui-selected:hover, & .MuiButtonBase-root.Mui-selected:focus': {
            background: palette.grey[500],
          },
          '& .MuiButtonBase-root .MuiTypography-root': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
          '& .MuiBox-root .MuiBox-root .MuiBox-root:has(.MuiBox-root button.MuiIconButton-colorPrimary)':
            {
              position: 'static',
            },
          '& .MuiBox-root:has(> button.MuiIconButton-colorPrimary)': {
            position: 'absolute',
            top: spacing(6),
            left: '100%',
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            marginTop: `${APP_BAR_HEIGHT + parseInt(spacing(3), 10)}px`,
          }}
        >
          <SharedSidebar
            isOpen={isSidebarOpen}
            toggleOpen={toggleSidebar}
            // Positions the expand/collapse icon
            appBarHeight={APP_BAR_HEIGHT + parseInt(spacing(3), 10)}
          >
            <SidebarHeader title={t('sidebar.navigation.title')} expandedView={isSidebarOpen} />
            <Navigation
              navRoutes={navRoutes}
              expandedView={isSidebarOpen}
              expandView={openSidebar}
            />

            {!isSidebarOpen && <Divider orientation="horizontal" sx={{ marginInline: 3 }} />}
            {userApps.length > 0 && (
              <SidebarHeader title={t('sidebar.apps.title')} expandedView={isSidebarOpen} />
            )}
            <SidebarAppMenu
              accessibleApps={userApps}
              visibleApps={3}
              expandedView={isSidebarOpen}
            />

            <Box
              marginTop="auto"
              display="flex"
              justifyContent="center"
              p={3}
              id="usermenu"
              sx={{
                '& .MuiStack-root .MuiStack-root': {
                  flex: 1,
                },
                '& .MuiBox-root+.MuiBox-root': {
                  flex: 1,
                  containerType: 'inline-size',
                },
                '& .MuiTypography-root': {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                },
              }}
            >
              <SidebarUserMenu
                userName={username}
                jobTitle={userData?.jobTitle ?? ''}
                userPicture={userPicture}
                logOut={logoutCallback}
                expandedView={isSidebarOpen}
              />
            </Box>
          </SharedSidebar>
        </Box>
      </Box>
    );

  return null;
}

export default Sidebar;
