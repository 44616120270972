// TODO
import { kuloerBannerUri as wifaBannerUri } from '@assets/images';
import { AppFeatureConfig } from '@dap-dashboard/types';
import { defaultBrandFeatures } from './defaultBrandFeatures';

export const wifaBrandFeaturesDefault: AppFeatureConfig = {
  ...defaultBrandFeatures,
  dashboardBanner: { resolveToggle: () => 'on', config: { image: wifaBannerUri } },
};

export const wifaBrandFeaturesDev: AppFeatureConfig = { ...wifaBrandFeaturesDefault };
export const wifaBrandFeaturesStage: AppFeatureConfig = { ...wifaBrandFeaturesDefault };
export const wifaBrandFeaturesProd: AppFeatureConfig = {
  ...wifaBrandFeaturesDefault,
  completeRegistrationEmailInfo: { resolveToggle: () => 'off' },
};
