import { PaperProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import Paper from './Paper';

interface Props extends Omit<PaperProps, 'variant'> {
  /**Should the padding prop not be sufficient, use paddingY = "none" and set the desired padding in the child component.
   * X-padding cannot be overrided. */
  paddingY?: 'none' | 'normal' | 'dense';
}

/**
 * Use as surface in Grid items or as background for content.
 */
function OutlinedPaper({ paddingY = 'normal', children, ...rest }: PropsWithChildren<Props>) {
  return (
    <Paper paddingY={paddingY} variant="outlined" {...rest}>
      {children}
    </Paper>
  );
}

export default OutlinedPaper;
