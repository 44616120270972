import { EmployeeBasicV2, EmployeeStatus } from '@dap-admin/types';

export const sortAdminsFirst = (employees: Array<EmployeeBasicV2>) => {
  return [...employees].sort((employee) =>
    employee.roles.some((role) => role.role.isAdmin) ? 1 : -1
  );
};

export const sortDeactivatedLast = (employees: Array<EmployeeBasicV2>) => {
  return [...employees].sort((employee) => (employee.status === EmployeeStatus.DISABLED ? 1 : -1));
};
