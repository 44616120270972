import { Button, DialogContent, styled, SxProps, Theme } from '@mui/material';
import { useDisclosure } from '@shared/hooks';
import { Dialog } from '@shared/components';

interface Props {
  imgSrc: string;
  expandedImgSrc: string;
  alt: string;
  sx?: SxProps<Theme>;
}

function ExpandableImage({ imgSrc, expandedImgSrc, alt, sx }: Props) {
  const { isOpen: isModalOpen, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();

  return (
    <>
      <Button onClick={onOpenModal} sx={buttonSx}>
        <StyledPreviewImage src={imgSrc} alt={alt} sx={sx} />
      </Button>
      <Dialog open={isModalOpen} maxWidth="xl" fullWidth onClose={onCloseModal}>
        <DialogContent>
          <Button
            onClick={onCloseModal}
            sx={{ padding: '0', border: 'none', background: 'transparent' }}
          >
            <StyledExpandedImage src={expandedImgSrc} alt={alt} />
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

const StyledPreviewImage = styled('img')({
  cursor: 'zoom-in',
});

const StyledExpandedImage = styled('img')({
  cursor: 'zoom-out',
  maxWidth: '100%',
  maxHeight: 'calc(100vh - 64px)',
});

const buttonSx: SxProps = {
  width: '100%',
  padding: '0',
  border: 'none',
  background: 'transparent',
  '&:hover': {
    boxShadow: 'none',
    border: 'none',
  },
};

export default ExpandableImage;
