import { Paper, styled, Switch, SwitchProps } from '@mui/material';

interface Props extends SwitchProps {
  label: string;
}

export default function FramedSwitch({ label, ...props }: Props) {
  return (
    <StyledPaper elevation={1}>
      <Switch {...props} />
      {label}
    </StyledPaper>
  );
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '1.25rem',
  borderColor: 'divider',
  display: 'flex',
  alignItems: 'center',
  padding: '0 1rem 0 0',
  gap: 10,
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.04)',
  border: `1px solid ${theme.palette.grey[200]}`,
  fontSize: theme.typography.body1?.fontSize,
}));
