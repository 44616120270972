import { SvgIcon, SvgIconProps } from '@mui/material';

const SuperAdminIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="32" height="33" viewBox="0 0 32 33">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.4631 4.42559L28.8194 0.349854H3.48376L0.729889 4.42559L2.27206 5.8576C3.81423 3.04866 8.78221 3.76466 8.82628 6.849C8.87034 9.93334 4.53024 11.09 2.27206 8.39117C2.98807 13.3665 6.74435 25.1237 16.0414 32.3499C20.2457 29.0085 28.9736 19.5388 30.2514 8.39117C27.828 10.7595 23.2896 10.2418 23.2015 6.849C23.1134 3.45623 28.8194 3.32404 29.9209 5.8576L31.4631 4.42559ZM20.4311 9.65178C19.8635 9.03254 18.1503 7.82504 15.8385 7.94888C12.9487 8.10369 11.2974 10.0646 11.2974 11.9739C11.2974 13.8832 12.7939 15.3561 14.7135 15.9754C14.9668 16.0571 15.2964 16.1258 15.6585 16.2012C17.0737 16.4961 18.9853 16.8944 18.7798 18.5791C18.5546 20.4259 16.7522 20.7621 15.8385 20.6203C14.5857 20.4258 13.9216 19.9464 13.3028 19.4996C13.1335 19.3773 12.9676 19.2575 12.7939 19.1467L12.0199 21.0044C12.0819 21.0393 12.1471 21.0764 12.2154 21.1152C13.0904 21.6132 14.4687 22.3977 16.1439 22.3977C17.7716 22.3977 20.8956 21.6012 20.8956 18.5791C20.8956 15.4309 18.1132 14.554 17.1286 14.3992C16.1439 14.2444 13.5164 13.78 13.4131 11.9739C13.3099 10.1678 15.1592 9.65178 16.1439 9.65178C17.1286 9.65178 18.5217 10.4361 19.6055 11.4063L20.4311 9.65178Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default SuperAdminIcon;
