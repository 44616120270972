import dayjs from 'dayjs';

type TimeAgoUnit = 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds';

interface TimeAgo {
  years: number;
  months: number;
  weeks: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  value: number;
  unit: TimeAgoUnit;
}

// Returns the time ago from a given date, in various units, as well as the first non-zero value and its unit
export function timeAgo(date: string): TimeAgo {
  const years = dayjs().diff(date, 'year');
  const months = dayjs().diff(date, 'month');
  const weeks = dayjs().diff(date, 'week');
  const days = dayjs().diff(date, 'day');
  const hours = dayjs().diff(date, 'hour');
  const minutes = dayjs().diff(date, 'minute');
  const seconds = dayjs().diff(date, 'second');

  // Determine the first non-zero value
  const hit: { value: number; unit: string } | undefined = [
    { value: years, unit: 'years' },
    { value: months, unit: 'months' },
    { value: weeks, unit: 'weeks' },
    { value: days, unit: 'days' },
    { value: hours, unit: 'hours' },
    { value: minutes, unit: 'minutes' },
    { value: seconds, unit: 'seconds' },
  ].find((x) => x.value > 0);

  return {
    years,
    months,
    weeks,
    days,
    hours,
    minutes,
    seconds,
    value: hit?.value || 0,
    unit: (hit?.unit as TimeAgoUnit) || 'seconds',
  };
}
