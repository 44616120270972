import React from 'react';
import { Box, IconButton, Snackbar as MuiSnack, SnackbarProps, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { FlexRow } from '../Layout';
import { SnackType } from '@dap-common/types';

interface Props extends SnackbarProps {
  variant: SnackType;
}

export const Snackbar = ({
  onClose,
  message,
  variant,
  autoHideDuration = 6000,
  ...snackProps
}: Props) => {
  const { palette } = useTheme();

  return (
    <MuiSnack
      sx={{
        '& .MuiSnackbarContent-root': {
          backgroundColor: palette[variant].dark,
        },
        '& .MuiSnackbarContent-message': {
          width: '100%',
        },
      }}
      autoHideDuration={autoHideDuration}
      message={
        <FlexRow alignItems="baseline" width="100%" justifyContent="space-between">
          {message}
          {onClose && (
            <Box>
              <IconButton
                aria-label="Lukk melding"
                size="small"
                onClick={(e) => onClose(e, 'clickaway')}
              >
                <Close htmlColor={palette.common.white} />
              </IconButton>
            </Box>
          )}
        </FlexRow>
      }
      onClose={onClose}
      {...snackProps}
    />
  );
};
