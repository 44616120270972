export enum ModuleType {
  fremdriftModule = 'fremdrift_module',
  helpCentreModule = 'help_centre_module',
  mosaicModule = 'mosaic_module',
  orderModule = 'order_module',
  proffModule = 'proff_module',
  servicesModule = 'services_module',
  eventsModule = 'events_module',
  newsModule = 'news_module',
}

export interface SanityModuleConfigBase {
  _type: ModuleType;
  show: boolean;
}
