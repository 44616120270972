export enum ObjectType {
  CallToAction = 'callToAction',
  CustomLink = 'customLink',
  ExternalLinkField = 'externalLinkField',
  InternalLinkField = 'internalLinkField',
  NavigationItem = 'navigationItem',
  NavigationSubMenu = 'navigationSubMenu',
  NavigationSubMenuItem = 'navigationSubMenuItem',
  LinkListItem = 'linkListItem',
}
