import {
  BrandBasic,
  BrandLocationFieldConfig,
  BrandProperty,
  BrandV2,
  EcomAssortment,
  InheritableProperty,
  LocationField,
  PropertyDetail,
} from '@dap-admin/types';
import {
  BrandBasicDTO,
  BrandDetailDTO,
  BrandLocationFieldConfigDTO,
  EcommerceAssortmentsDTO,
} from '@generated-types';
import {
  appDetailedMapper,
  inheritablePropertiesMapper,
  propertiesMapper,
  regionDetailMapper,
  roleDetailedMapper,
} from './mappers';
import { locationPropertyMapper } from './locationMappers';
import { alphabeticSort } from '@shared/utils';

export function brandBasicMapper(brandBasicDTO?: BrandBasicDTO): BrandBasic {
  return {
    uuid: '',
    key: '',
    name: '',
    ...brandBasicDTO,
  };
}

export function brandDetailMapper(brandDetailDTO: BrandDetailDTO): BrandV2 {
  const mapped: BrandV2 = {
    uuid: brandDetailDTO.uuid ?? null,
    name: brandDetailDTO.name,
    key: brandDetailDTO.key,
    updatedAt: brandDetailDTO.updatedAt ?? null,
    updatedBy: brandDetailDTO.updatedBy ?? null,
    locationRoles: brandDetailDTO.locationRoles?.map(roleDetailedMapper) ?? [],
    dataownerRoles: brandDetailDTO.dataownerRoles?.map(roleDetailedMapper) ?? [],
    brandRoles: brandDetailDTO.brandRoles?.map(roleDetailedMapper) ?? [],
    regions: brandDetailDTO.regions?.map(regionDetailMapper) ?? [],
    brandLocationFieldConfigs:
      brandDetailDTO.brandLocationFieldConfigs?.map(brandLocationFieldConfigMapper) ?? [],
    employeePortalClientId: brandDetailDTO.employeePortalClientId ?? null,
    locationProperties: brandDetailDTO.locationProperties?.map(locationPropertyMapper) ?? [],
    ecommerceAssortments:
      brandDetailDTO.ecommerceAssortments?.map(ecommerceAssortmentsMapper) ?? [],
    apps: brandDetailDTO.apps?.map(appDetailedMapper) ?? [],
    isAdmin: brandDetailDTO.isAdmin ?? false,
    properties: brandPropertiesMapper(
      propertiesMapper(brandDetailDTO.properties),
      inheritablePropertiesMapper(brandDetailDTO.inheritableProperties)
    ),
  };
  return mapped;
}

export function brandLocationFieldConfigMapper(
  brandLocationFieldConfigDTO: BrandLocationFieldConfigDTO
): BrandLocationFieldConfig {
  const brandLocationFieldConfig: BrandLocationFieldConfig = {
    fieldName: brandLocationFieldConfigDTO.fieldName as LocationField,
    show: brandLocationFieldConfigDTO.show,
    id: brandLocationFieldConfigDTO.id ?? null,
  };
  return brandLocationFieldConfig;
}

export function ecommerceAssortmentsMapper(
  ecomAssortmentsDTO: EcommerceAssortmentsDTO
): EcomAssortment {
  const ecomAssortment: EcomAssortment = {
    key: ecomAssortmentsDTO.key,
    title: ecomAssortmentsDTO.title,
    selected: ecomAssortmentsDTO.selected ?? false,
  };
  return ecomAssortment;
}

export function brandPropertiesMapper(
  properties: PropertyDetail[],
  inheritableProperties: InheritableProperty[]
): BrandProperty[] {
  const brandPropertiesFromProperties: BrandProperty[] = properties
    ? [...properties.map(fromPropertyToBrandProperty)]
    : [];

  const brandPropertiesFromInheritableProperties: BrandProperty[] = inheritableProperties
    ? [...inheritableProperties.map(fromInheritablePropertyToBrandProperty)]
    : [];

  return [...brandPropertiesFromProperties, ...brandPropertiesFromInheritableProperties].sort(
    alphabeticSort<PropertyDetail>('key')
  );
}

export function fromInheritablePropertyToBrandProperty(
  property: InheritableProperty
): BrandProperty {
  const mapped: BrandProperty = {
    locationInheritable: property.locationInheritable,
    dataownerInheritable: property.dataownerInheritable,
    value: '',
    inherited: true,
    key: property.config.key,
    displayName: property.config.displayName,
    enabled: property.config.enabled,
    keyGroup: property.config.keyGroup,
    valueType: property.config.valueType,
    valueOptions: property.config.valueOptions,
    isLocked: property.config.isLocked,
  };
  return mapped;
}

export function fromPropertyToBrandProperty(property: PropertyDetail): BrandProperty {
  const mapped: BrandProperty = {
    ...property,
    locationInheritable: false,
    dataownerInheritable: false,
    inherited: false,
  };
  return mapped;
}
