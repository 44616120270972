/* 
  Organization numbers in different countries:
    * Norway - 9 digit
    * Sweden - 10 digits. Hyphen before the last 4 digits.
    * Finland - 8 digits. Hyphen before the last digit.
*/
export const formatOrgNo = (orgNo: string | undefined | null): string => {
  if (!orgNo) return '-';

  switch (orgNo.length) {
    case 8:
      return orgNo.substring(0, orgNo.length - 1) + '-' + orgNo.substring(orgNo.length - 1);
    case 10:
      return orgNo.substring(0, orgNo.length - 4) + '-' + orgNo.substring(orgNo.length - 4);
    default:
      return orgNo;
  }
};
