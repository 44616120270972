import { Typography } from '@mui/material';
import { messages } from '@dap-common/i18n';
import { useTranslation } from 'react-i18next';

export default function WelcomeInfo() {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h2" component="h1" fontStyle="italic" fontWeight="500">
        {t(messages.common.landingPage.welcome)}
      </Typography>
      <Typography
        variant="h1"
        color="primary"
        fontStyle="bold"
        textAlign="center"
        sx={{
          marginTop: '15px',
          marginBottom: '55px',
          fontSize: '30px !important',
          background: ({ palette }) =>
            `linear-gradient(180deg, ${palette.primary.light} 25%, ${palette.primary.dark} 75%)`,
          backgroundClip: 'text',
          textFillColor: 'transparent',
        }}
      >
        {t(messages.common.landingPage.theDigitalWorkspace)}
      </Typography>
    </>
  );
}
