import { groqQuery } from '@shared/utils';

export const getProffCategoriesQuery = (moduleId: string) =>
  groqQuery({
    filters: [`_id =="${moduleId}"`],
    projections: [
      `
      "id": _id,
      header,
      "items": categories[]-> {
        "id": _id,
        title,
        "slug": category_slug.current
      },
      "info": infoText,
      intro,
      "slug": category_slug.current,
      "frontPageImage": frontPageProffImage.asset
    `,
    ],
  });

export const getProffCategoryQuery = (moduleId: string, categorySlug: string) =>
  groqQuery({
    filters: [
      `references("${moduleId}")`,
      "_type=='category'",
      `category_slug.current == "${categorySlug}"`,
    ],
    projections: [
      `
      title,
      "id":_id,
      "slug": category_slug.current,
      "image": image.asset->url,
      "body": textBlock,
      videoSrc,
      "contacts": person_array[]->,
      "items":  (articles[]->)[(!defined(publishAt) || dateTime(publishAt) <= dateTime(now()))]{
        "id": _id,
        intro,
        "image": image.asset->url,
        title,
        "url": url.current,
      }
    `,
    ],
    slice: { start: 0 },
  });
