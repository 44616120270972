import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorCard, FlexColumn, MainContent } from '@dap-common/ui';
import { messages } from '@dap-common/i18n';

function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <MainContent>
      <FlexColumn margin="auto">
        <ErrorCard width="unset">{t(messages.common.error.notFound)}</ErrorCard>
      </FlexColumn>
    </MainContent>
  );
}

export default NotFoundPage;
