import { capitalizeFirstLetter } from '@shared/utils';

export type Format = 'capitalizeFirstLetter' | 'uppercase' | 'lowercase';
export type FormatFn = (str: string) => string;

export const formatMap: Record<Format, FormatFn> = {
  capitalizeFirstLetter,
  uppercase: (str) => str.toUpperCase(),
  lowercase: (str) => str.toLowerCase(),
};
