import { SanityError } from '@dap-sanity/types';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import { SanityClient } from '@sanity/client';
import { reportException } from '@shared/reporting';

class EventError extends Error {
  private errorObject: any;

  constructor(message: string, errorObject: any) {
    super(message);
    this.name = 'EventError';
    this.errorObject = errorObject;
  }
}

export interface BaseSanityQueryProps {
  query: string;
}

export const getBaseQueryFn =
  (client: SanityClient): BaseQueryFn<BaseSanityQueryProps, unknown, SanityError> =>
  async ({ query }) => {
    try {
      const res = await client.fetch(query);
      if (!res) {
        // Treat as 404
        return { error: { code: 'noResult' } as SanityError };
      }
      return { data: res };
    } catch (error: any) {
      console.warn(error);
      // Ignore network errors
      if (!error?.isNetworkError) {
        if (error instanceof Error) {
          reportException(error, { query });
        } else {
          const eventError = new EventError('Caught non-error exception from sanity fetch', error);
          reportException(eventError, { query });
        }
      }

      return { error: { code: 'serverError', error } as SanityError };
    }
  };
