import { EmployeeDetail, UserData } from '@dap-admin/types';
import { AppType, RoleType } from '@dap-common/types';

export function hasEditUserPermission(
  me: UserData,
  employee: EmployeeDetail,
  orgUnitKey: string
): boolean {
  return (
    isSuperuser(me) ||
    isOrgUnitAdmin(me, orgUnitKey) ||
    me?.userId === employee.userId ||
    isAdmin(me, orgUnitKey)
  );
}

function isOrgUnitAdmin(userData: UserData, orgUnitKey: string) {
  return userData.roles.some((role) => role.role.isAdmin && role.orgUnitKey === orgUnitKey);
}

export function isSuperuser(userData: UserData): boolean {
  return userData.metadata.superuser;
}

 /**
  *
  * @param userData The current userData object
  * @param orgUnitKey The key of the orgUnit
  * @returns true if the user has an admin role in the given OrgUnit
  */
 export function isAdmin(userData: UserData, orgUnitKey: string): boolean {
   return isSuperuser(userData) || isOrgUnitAdmin(userData, orgUnitKey);
 }

 export function isMasterdataAdmin(userData: UserData, orgUnitKey: string): boolean {
   return (
     isSuperuser(userData) ||
     userData.roles.some(
       (role) =>
         role.role.isAdmin &&
         role.role.key === RoleType.MasterdataAdmin &&
         role.orgUnitKey === orgUnitKey
     )
   );
 }

export const hasBrandadminAppAccess = (userData: UserData) =>
  isSuperuser(userData) || userData.apps.some((app) => app.key === AppType.BrandAdmin);
