import { ErrorOutlineRounded } from '@mui/icons-material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {
  Button,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  IconButton,
  List,
  Stack,
} from '@mui/material';
import { PropsWithChildren } from 'react';

interface Props {
  label: string;
  fullWidth?: FormControlProps['fullWidth'];
  onAdd: () => void;
  addButtonLabel: string;
  margin?: FormControlProps['margin'];
  error?: string;
  name: string;
}

export default function FieldArray({
  label,
  fullWidth = false,
  onAdd,
  addButtonLabel,
  children,
  margin,
  error,
  name,
}: PropsWithChildren<Props>) {
  const helperTextId = error && `${name}-helperText`;
  const controlId = `${name}-id`;
  return (
    <FormControl
      id={controlId}
      error={!!error}
      component="fieldset"
      fullWidth={fullWidth}
      margin={margin}
    >
      <Stack gap={1} justifyItems={'flex-start'}>
        <FormLabel component="legend" error={!!error}>
          {label}
        </FormLabel>
        <List dense disablePadding>
          {children}
        </List>
        <div>
          <Button
            variant="text"
            color="inherit"
            startIcon={
              <IconButton
                component="span"
                size="small"
                sx={{ background: ({ palette }) => palette.gradient.primary, mr: 0.5 }}
              >
                <AddRoundedIcon fontSize="small" htmlColor="white" />
              </IconButton>
            }
            onClick={onAdd}
            sx={{ padding: 1 }}
          >
            {addButtonLabel}
          </Button>
        </div>
      </Stack>
      {error && (
        <Stack direction="row" spacing={1} pt={1} flexWrap="nowrap" alignItems="center">
          <ErrorOutlineRounded color="warning" fontSize="small" />
          <FormHelperText id={helperTextId}>{error}</FormHelperText>
        </Stack>
      )}
    </FormControl>
  );
}
