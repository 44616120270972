import type { PortableTextBlock } from '@portabletext/types';

export type SanityBlockContent = PortableTextBlock;

export interface Attachment {
  url: string;
  label: string;
}

export interface SanityUrl {
  url: {
    current: string;
  };
}

export enum KeyInfoType {
  email = 'emailAddress',
  link = 'externalLink',
  text = 'freeText',
}

export interface KeyInfoField {
  title: string;
  value: string;
}

export interface KeyInfoUrlField {
  title: string;
  url: string;
}

export interface KeyInfo {
  email: Array<KeyInfoField>;
  link: Array<KeyInfoUrlField>;
  text: Array<KeyInfoField>;
}
