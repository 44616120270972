import { LanguageCapitalLettersCode, LanguageCode } from '@shared/types';

const getLanguageKeyFromLocale = (languageKey: string): LanguageCode => {
  //locale codes in use: sv, sv-SE, nb, nn, nb-NO, nn-NO etc
  const localePrefix = languageKey.split('-')[0];
  switch (localePrefix) {
    case LanguageCode.Norwegian:
      return LanguageCode.Norwegian;
    case LanguageCode.Norwegian_Nynorsk:
      return LanguageCode.Norwegian;
    case LanguageCode.Swedish:
      return LanguageCode.Swedish;
    default:
      return LanguageCode.English;
  }
};

export const getDTOLanguageKeyFromResource = (
  languageKey: LanguageCode
): LanguageCapitalLettersCode => {
  switch (languageKey) {
    case LanguageCode.Norwegian:
      return LanguageCapitalLettersCode.Norwegian;
    case LanguageCode.Swedish:
      return LanguageCapitalLettersCode.Swedish;
    default:
      return LanguageCapitalLettersCode.English;
  }
};

export const getDefaultLanguageSettingFromLocale = () => {
  const locale = navigator.language;
  return getLanguageKeyFromLocale(locale);
};
