import groq from 'groq';
import { CustomLinkType, ModuleType } from '@dap-sanity/types';
import {
  fremdriftModuleQuery,
  mosaicModuleQuery,
  orderModuleQuery,
  proffModuleQuery,
  staticModuleQuery,
} from './moduleQueries';

// This query is used to fetch the data for the various types of links
export const customLinkQuery = groq`
  _type,
  _key,

  _type == "${CustomLinkType.ReferencedModule}" => {
    _ref,
    "referencedModule": @->{
      _type,
      _type == "${ModuleType.proffModule}" => {
        ${proffModuleQuery}
      },
      _type == "${ModuleType.fremdriftModule}" => {
        ${fremdriftModuleQuery}
      },
      _type == "${ModuleType.mosaicModule}" => {
        ${mosaicModuleQuery}
      },
    }
  },

  _type == "${CustomLinkType.StaticModule}" => {
    ${staticModuleQuery}
  },

  _type == "${CustomLinkType.OrderModule}" => {
    _ref,
    "referencedModule": *[
      _type == "${ModuleType.orderModule}"
      && _id == ^._ref
      && coalesce(^.^.^.^.^.^.^.brand, ^.^.^.^.^.^.brand, ^.^.^.^.^.brand, ^.^.^.^.brand, ^.^.^.brand) in brand
      && show
    ][0]{
      ${orderModuleQuery}
    },
  },

  _type == "${CustomLinkType.RelativeLink}" => {
    link,
  },

  _type == "${CustomLinkType.TopicPage}" => {
    "referencedTopic": @->{
      "slug": slug.current,
      title
    }
  },

  _type == "${CustomLinkType.ExternalLink}" => {
    href,
    blank
  },

  _type == "${CustomLinkType.Content}" => {
    "referencedArticle": @->{
      _type,
      title,
      module,
      "url": url.current,
      "moduleRef": *[
        _id == ^.module_reference._ref
        && show
      ][0] {
        "slug": module_slug.current,
        _type,
      },
      "category": *[
        _type == 'category'
        && references(^._id)
        && !(_id in path("drafts.**"))
        && coalesce(^.^.^.^.^.^.^.brand, ^.^.^.^.^.^.brand, ^.^.^.^.^.brand, ^.^.^.^.brand, ^.^.^.brand) in brand
        && (count(*[
          _type in ['fremdrift_module', 'proff_module']
          && references(^._id)
          && !(_id in path("drafts.**"))
        ])>0)
      ][0] {
        "slug": category_slug.current,
      },
    }
  },
`;
