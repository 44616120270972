import { BrandadminTFunction } from '@dap-admin/i18n';
import { CountryCode } from '@shared/types';
import { Validations } from '@dap-common/utils';

export function getOrgNumberNameFromCountry(countryCode: string, t: BrandadminTFunction) {
  return countryCode === CountryCode.Finland
    ? t('dataowner.organization.FONumber')
    : t('dataowner.organization.abbreviation');
}

export function getOrgNumberName(orgNumber: string | null, t: BrandadminTFunction) {
  if (!orgNumber) return t('dataowner.organization.abbreviation');
  if (
    Validations.swedishOrgNoRegex.test(orgNumber) ||
    Validations.finnishOrgNoRegex.test(orgNumber)
  )
    return t('dataowner.organization.FONumber');

  return t('dataowner.organization.abbreviation');
}
