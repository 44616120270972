import { PropsWithChildren } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  alpha,
  styled,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface Props extends AccordionProps {
  summary: string | React.ReactNode;
  countText?: string;
}

export default function OrderDetailsAccordion({
  disabled,
  summary,
  children,
  countText,
}: PropsWithChildren<Props>) {
  return (
    <Accordion disabled={disabled}>
      <AccordionSummary>
        {summary}
        {countText && (
          <Typography
            component="span"
            variant="inherit"
            color="primary.main"
            fontWeight="500"
            ml={1}
          >
            {countText}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 2),
  '&:before': {
    display: 'none',
  },
  '&.Mui-disabled': {
    backgroundColor: 'transparent !important',
    opacity: 0.8,
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<KeyboardArrowDownIcon />} {...props} />
))(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    ...theme.typography.body2,
  },
  minHeight: 0,
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  '&.MuiAccordionDetails-root': {
    padding: theme.spacing(1, 0, 0),
  },
}));
