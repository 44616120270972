import { AppFeatureConfig } from '@dap-dashboard/types';
import { defaultBrandFeatures } from './defaultBrandFeatures';

const mesterhusFeaturesDefault: AppFeatureConfig = {
  ...defaultBrandFeatures,
  workplace: { resolveToggle: () => 'on' },
  mgaBanner: {
    resolveToggle: ({ order_module }) => (order_module?.[0]?.show ? 'on' : 'off'),
  },
};

export const mesterhusFeaturesDev: AppFeatureConfig = { ...mesterhusFeaturesDefault };
export const mesterhusFeaturesStage: AppFeatureConfig = { ...mesterhusFeaturesDefault };
export const mesterhusFeaturesProd: AppFeatureConfig = {
  ...mesterhusFeaturesDefault,
  completeRegistrationEmailInfo: { resolveToggle: () => 'off' },
};
