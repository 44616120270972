import { Box, useTheme } from '@mui/material';
import { DAPIcon } from '@shared/custom-icons';

export function DAPLogo() {
  const { palette } = useTheme();

  return (
    <Box position="relative" height="100%">
      <DAPIcon
        sx={{
          width: '53px',
          height: '23px',
          display: 'block',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
          color: palette.primary.main,
        }}
      />
      <Box
        sx={{
          width: '120px',
          height: '100%',
          backgroundColor: palette.info[200],
          transform: 'skew(-43deg)',
          marginLeft: '5rem',
          marginRight: '5rem',
        }}
      ></Box>
    </Box>
  );
}
