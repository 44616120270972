import { alpha } from '@mui/material';

/**
 * @deprecated Belongs to the old theme
 */
const focusVisibleButtonShadow =
  '0px 5px 5px -3px rgb(105, 105, 105, 0.25), 0px 6px 10px 1px rgb(105, 105, 105, 0.2), 0px 3px 14px 2px rgb(105, 105, 105, 0.18);';

/**
 * @deprecated Belongs to the old theme
 */
export const colors = {
  background: '#FCFBFB',
  black: '#000',
  white: '#FFFFFF',
  blue: {
    light: '#A1BCC7',
    main: '#7EA5B4',
    dark: '#658B99',
    blueGrey: '#F4F5F7',
  },
  lightBlue: {
    light: '#ECF2FF',
    main: '#EAF0FD',
    dark: '#D7E3FF',
  },
  red: {
    light: '#FE714F',
    main: '#CC492A',
    dark: '#AF3E24',
  },
  green: {
    light: '#BEEC3B',
    main: '#749E00',
    dark: '#9BC526',
  },
  purple: {
    lighter: '#F6F7FF',
    light: '#8176AD',
    main: '#5C4F95',
    dark: '#453B71',
    50: '#F6F7FF',
    100: '#BEB9D5',
    200: '#5A59DF',
  },
  orange: {
    light: '#F5725A1A',
    main: '#F5725A',
    50: '#F7F6F3',
  },
  grey: {
    main: '#F4F5F7',
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#E3E3E3',
    300: '#D0D0CF',
    400: '#B9B6B4',
    500: '#A39F9D',
    600: '#696969',
    700: '#828282',
    800: '#686361',
    900: '#504946',
  },
  border: {
    blueGrey: '#E8EFFC',
    lightBlue: '#C7D1E7',
  },
};

/**
 * @deprecated Belongs to the old theme
 */
export const commonText = {
  primary: '#3C3532',
  secondary: colors.grey[900],
  tertiary: '#272727',
  disabled: colors.grey[900],
};

/**
 * @deprecated Belongs to the old theme
 */
export const themePalette = {
  mode: 'light',
  common: {
    black: colors.black,
    white: colors.white,
  },
  primary: {
    light: colors.purple.light,
    main: colors.purple.main,
    dark: colors.purple.dark,
    contrastText: colors.white,
  },
  secondary: {
    light: colors.blue.light,
    main: colors.blue.main,
    dark: colors.blue.dark,
  },
  error: {
    light: colors.red.light,
    main: colors.red.main,
    dark: colors.red.dark,
    contrastText: colors.white,
  },
  warning: {
    light: colors.orange.light,
    main: colors.orange.main,
    contrastText: colors.white,
  },
  success: {
    light: colors.green.light,
    main: colors.green.main,
    dark: colors.green.dark,
    contrastText: colors.white,
  },
  info: {
    main: alpha(colors.grey[900], 0.8),
    contrastText: colors.white,
  },
  background: {
    paper: colors.white,
    primary: colors.purple.lighter,
    default: colors.background,
    white: colors.white,
  },
  cardBackgrounds: {
    lightBlue: colors.lightBlue.light,
    blue: colors.lightBlue.main,
    blueGrey: colors.blue.blueGrey,
    lightGrey: colors.grey[50],
    grey: colors.grey.main,
    blueBorder: colors.lightBlue.dark,
    orange: alpha(colors.orange.main, 0.2),
    lightOrange: colors.orange.light,
    lightPurple: colors.purple.lighter,
  },
  border: {
    blueGrey: colors.border.blueGrey,
    lightBlue: colors.border.lightBlue,
    darkPurple: alpha(colors.purple.dark, 0.1),
  },
  button: {
    grey: colors.grey.main,
    darkGrey: colors.grey[700],
    blue: colors.lightBlue.dark,
    lighterBlue: '#CFDAF0',
    lightBlue: '#DCE2EF',
    purple: colors.purple.main,
  },
  text: {
    primary: commonText.primary,
    secondary: commonText.secondary,
    tertiary: commonText.tertiary,
    disabled: commonText.disabled,
  },
  divider: alpha(colors.purple.dark, 0.1),
  action: {
    active: alpha(colors.grey[900], 0.7),
    hover: colors.grey[300],
    hoverOpacity: 0.02,
    selected: colors.grey[400],
    selectedOpacity: 0.03,
    disabledOpacity: 0.4,
    focus: focusVisibleButtonShadow,
    focusOpacity: 0.4,
    activatedOpacity: 0.12,
  },
  grey: {
    ...colors.grey,
  },
  purple: {
    ...colors.purple,
  },
  orange: {
    ...colors.orange,
  },
};
