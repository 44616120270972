import { alpha, Button, Link, SimplePaletteColorOptions, styled, Typography } from '@mui/material';
import { BlobSvg } from '@assets/images';

interface Props {
  href: string;
  imageSrc: string;
  header: string;
  text: string;
  buttonText: string;
  colors?: Colors;
  isSmall: boolean;
}

interface Colors {
  background: SimplePaletteColorOptions;
  accent: SimplePaletteColorOptions;
}

const defaultColors = {
  background: {
    main: '#0F2A5F',
    contrastText: '#FFFFFF',
  },
  accent: {
    main: '#FFD029',
  },
};

export default function MosaicWithCTA({
  href,
  imageSrc,
  header,
  text,
  buttonText,
  colors = defaultColors,
  isSmall,
}: Props) {
  return (
    <Link href={href} style={{ textDecoration: 'none' }}>
      <Card colors={colors}>
        <Block>
          <Typography component="div" textAlign="center" variant="h2" marginBottom={1.5}>
            {header}
          </Typography>
          <Typography
            component="div"
            color={alpha(colors.background.contrastText || 'common.white', 0.6)}
            textAlign="center"
            variant="body2"
            marginBottom={1.5}
          >
            {text}
          </Typography>
          <StyledButton colors={colors} variant="outlined">
            {buttonText}
          </StyledButton>
        </Block>
        {!isSmall && (
          <Block>
            <BlobSvg
              color={colors.accent.main}
              width="100%"
              height="100%"
              style={{ position: 'absolute', zIndex: 1 }}
            />
            <img src={imageSrc} alt="" width="70%" style={{ zIndex: 2 }} />
          </Block>
        )}
      </Card>
    </Link>
  );
}

const Card = styled('div')<{ colors: Colors }>(({ theme, colors }) => ({
  color: colors.background.contrastText,
  background: colors.background.main,
  width: '100%',
  height: '224px',
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  padding: theme.spacing(3),

  '* > img': {
    transform: 'none',
    transition: 'transform ease 0.5s',
  },

  ':hover, :focus-within, :focus': {
    '* > img': { transform: 'scale(1.1)' },
  },
}));

const Block = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
}));

const StyledButton = styled(Button)<{ colors: Colors }>(({ colors }) => ({
  color: colors.background.contrastText,
  borderColor: colors.background.contrastText,
  fontWeight: 'bold',

  '&:hover': {
    backgroundColor: colors.background.contrastText,
    color: colors.background.main,
  },
}));
