import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackType } from '@dap-common/types';

export interface SnackbarState {
  variant: SnackType;
  message: string;
}

const snackbarInitialState: SnackbarState = {
  variant: 'info',
  message: '',
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: snackbarInitialState,
  reducers: {
    newSuccessMessage: (state, action: PayloadAction<string>) => {
      state.variant = 'success';
      state.message = action.payload;
    },
    newInfoMessage: (state, action: PayloadAction<string>) => {
      state.variant = 'info';
      state.message = action.payload;
    },
    newErrorMessage: (state, action: PayloadAction<string>) => {
      state.variant = 'error';
      state.message = action.payload;
    },
    clearSnackbar: () => snackbarInitialState,
  },
});

export const { newSuccessMessage, newInfoMessage, newErrorMessage, clearSnackbar } =
  snackbarSlice.actions;
export default snackbarSlice.reducer;
