import { OrderArticle } from '@dap-sanity/types';
import { Add, Close } from '@mui/icons-material';
import {
  Box,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogTitle,
  IconButton,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
  Chip,
} from '@mui/material';
import {
  CheckboxGroup,
  FlexRow,
  PrimaryButton,
  SecondaryButton,
  withFormProvider,
} from '@dap-common/ui';
import { messages } from '@dap-common/i18n';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { SanityBody } from '../pageContent';
import { PreviewSanityImage } from '../previewContent';
import { productInformationImageHeight } from './constants';
import OrderDetailsAccordion from './OrderDetailsAccordion';
import StandardFeatureItem from './StandardFeatureItem';
import { NorwegianKroner } from '@shared/utils';

interface ProductInformationSchema {
  optional: Array<string>;
}

interface Props {
  open: boolean;
  close: () => void;
  article: OrderArticle;
  onSubmit: (form: ProductInformationSchema) => void;
}

const formId = 'product-information-form';

function ProductInformationDialog({ open, close, article, onSubmit }: Props) {
  const { t } = useTranslation(['order', 'common']);
  const { control, handleSubmit } = useFormContext<ProductInformationSchema>();
  const optionalController = useController({ name: 'optional', control });

  const { breakpoints, palette } = useTheme();
  const isLessThanMedium = useMediaQuery(breakpoints.down('md'));

  return (
    <Dialog maxWidth="lg" open={open} onClose={close} fullWidth>
      <Stack
        px={0.75}
        pt={0.75}
        direction="row"
        justifyContent="space-between"
        bgcolor="primary.light"
      >
        <DialogTitle>{article.title}</DialogTitle>
        <IconButton onClick={close}>
          <Close fontSize="small" />
        </IconButton>
      </Stack>
      <Stack
        px={2}
        pb={3}
        direction={isLessThanMedium ? 'column' : 'row'}
        bgcolor="primary.light"
        justifyContent="space-between"
        spacing={3}
      >
        <Stack flex="0 0 50%">
          <Typography variant="h4">{article.intro}</Typography>
          {article.body && <SanityBody body={article.body} />}
        </Stack>
        {article.image && (
          <PreviewSanityImage height={productInformationImageHeight} image={article.image} />
        )}
      </Stack>

      {article.includedFeatures && (
        <Stack p="1.5rem 1rem 1.25rem">
          <OrderDetailsAccordion summary={t(messages.order.order.includedOptions)}>
            <Box
              display="grid"
              gridTemplateColumns="repeat(auto-fill, minmax(min(100%, max(200px, 100%/4)), 1fr))"
              rowGap={2}
            >
              {article.includedFeatures?.map((feature) => (
                <StandardFeatureItem key={feature} feature={feature} />
              ))}
            </Box>
          </OrderDetailsAccordion>
        </Stack>
      )}

      {article.optionalFeatures && (
        <Stack p="0 1rem">
          <OrderDetailsAccordion summary={t(messages.order.order.additionalOptions)}>
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
              <CheckboxGroup
                {...optionalController.field}
                options={
                  article.optionalFeatures?.map((feature) => ({
                    value: feature,
                    label: feature,
                  })) || []
                }
                noOptionsText={t(messages.order.order.noAvailableOptions)}
                enableSelectAll
                fullWidth
                direction="row"
              />
            </form>
          </OrderDetailsAccordion>
        </Stack>
      )}

      <FlexRow justifyContent={'space-between'} alignItems={'flex-end'}>
        <Stack maxWidth={'600px'} spacing={1} p={2}>
          <Chip
            style={{
              width: 'fit-content',
              backgroundColor: palette.primary.light,
              borderRadius: '12px',
            }}
            label={
              <Typography variant="body2">
                {article.price
                  ? t(messages.order.price.priceExample, {
                      price: NorwegianKroner.format(article.price),
                    })
                  : t(messages.order.price.onRequest)}
              </Typography>
            }
          />
          <Typography variant="body2">{t(messages.order.price.examplePriceInfo)}</Typography>
          <Typography variant="body2">{t(messages.order.price.sendRequestForPrice)}</Typography>
        </Stack>
        <DialogActions sx={{ mt: '1.5rem' }}>
          <SecondaryButton onClick={close} variant="text">
            {t(`common:${messages.common.close}`)}
          </SecondaryButton>
          <PrimaryButton
            form={formId}
            type="submit"
            onClick={handleSubmit(onSubmit)}
            startIcon={<Add />}
          >
            {t(messages.order.order.addItem)}
          </PrimaryButton>
        </DialogActions>
      </FlexRow>
    </Dialog>
  );
}

export default withFormProvider<ProductInformationSchema, Props>(ProductInformationDialog, () => ({
  schema: yup.object({
    includedOptions: yup.array(yup.string()),
    additionalOptions: yup.array(yup.string()),
  }),
  defaultValues: {
    optional: [],
  },
}));

const Dialog = styled(MuiDialog)(() => ({
  '.MuiDialog-paper': {
    padding: 0,
  },
}));

const DialogActions = styled(MuiDialogActions)(() => ({
  padding: '0 1rem 1rem 1rem',
}));
