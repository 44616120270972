import { Button, ButtonProps, CircularProgress, styled } from '@mui/material';

interface Props extends ButtonProps {
  loading?: boolean;
  startIcon?: JSX.Element | null;
  borderRadius?: number;
}

/**
 * @deprecated Use buttons from @shared/components
 */
function LoadingButton({
  loading = false,
  children,
  variant = 'contained',
  size = 'small',
  startIcon = null,
  borderRadius = 7,
  disabled = false,
  sx,
  ...rest
}: Props) {
  return (
    <StyledButton
      variant={variant}
      startIcon={loading ? <CircularProgress color="inherit" size={18} /> : startIcon}
      disabled={disabled || loading}
      size={size}
      borderRadius={borderRadius}
      sx={sx}
      {...rest}
    >
      {children}
    </StyledButton>
  );
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'borderRadius',
})<Props>(({ borderRadius = 7 }) => ({
  borderRadius: `${borderRadius}px`,
}));

export default LoadingButton;
