import { ButtonProps } from '@mui/material';
import LoadingButton from './LoadingButton';

interface Props extends Omit<ButtonProps, 'color'> {
  loading?: boolean;
  startIcon?: JSX.Element | null;
  borderRadius?: number;
}

/**
 * @deprecated Use buttons from @shared/components
 */
export default function PrimaryButton({ ...rest }: Props) {
  return <LoadingButton color="primary" {...rest} />;
}
