import React from 'react';
import { Box, BoxProps } from '@mui/material';

const CenterElement: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box
    height="100%"
    width="100%"
    display="flex"
    justifyContent="center"
    alignItems="center"
    {...props}
  >
    {children}
  </Box>
);

export default CenterElement;
