import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  OutlinedInputProps,
  Select,
  Stack,
  SxProps,
} from '@mui/material';
import { AsteriskIcon } from '@shared/custom-icons';
import { forwardRef } from 'react';

/**
 * @deprecated This component is deprecated, use SelectInputField instead
 */
interface Props
  extends Pick<OutlinedInputProps, 'onChange' | 'onBlur' | 'name' | 'defaultValue' | 'value'> {
  label?: string;
  required?: boolean;
  menuItems: Array<{ value: any; name: string }>;
  error?: string;
  placeholder: string;
  margin?: 'normal' | 'dense' | 'none';
  fullWidth?: boolean;
}

const InputSelect = forwardRef(
  (
    {
      label,
      required,
      menuItems,
      error,
      placeholder,
      margin = 'dense',
      fullWidth = true,
      ...rest
    }: Props,
    ref
  ) => {
    const inputId = `${rest.name}-id`;
    const formLabelId = label && `${rest.name}-label`;
    const helperTextId = error && `${rest.name}-helperText`;

    const InputElement = (
      <OutlinedInput placeholder={placeholder} ref={ref} fullWidth={fullWidth} {...rest} />
    );
    return (
      <FormControl margin={margin} required={required} error={!!error} fullWidth={fullWidth}>
        {label && (
          <FormLabel htmlFor={inputId} id={formLabelId} sx={formLabelSx}>
            {label}
          </FormLabel>
        )}
        <Stack direction="row" alignItems="center">
          <Select
            key={inputId}
            id={label}
            label={label ? null : placeholder}
            labelId={formLabelId}
            input={InputElement}
            aria-describedby={helperTextId}
            aria-label={label}
            displayEmpty
            renderValue={
              rest.value !== '' ? undefined : () => !label && <InputLabel>{placeholder}</InputLabel>
            }
            fullWidth={fullWidth}
            sx={textFieldSx}
          >
            {!label && (
              <MenuItem disabled sx={labelMenuItemSx} dense>
                {placeholder}
              </MenuItem>
            )}
            {menuItems.map(({ value, name }) => (
              <MenuItem key={value} value={value} dense>
                {name}
              </MenuItem>
            ))}
          </Select>
          {!label && required && <AsteriskIcon fontSize="inherit" />}
        </Stack>
        {error && (
          <FormHelperText id={helperTextId} sx={formHelperTextSx}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default InputSelect;

const textFieldSx = {
  '& .MuiOutlinedInput-input': {
    padding: '6px 10px',
  },
};

const formHelperTextSx = {
  ml: 0,
  typography: 'body3',
};

const labelMenuItemSx: SxProps = {
  fontStyle: 'italic',
  '&.Mui-disabled': {
    opacity: 0.5,
  },
};

const formLabelSx: SxProps = {
  typography: 'h4',
  color: 'text.primary',
  mb: 0.5,
};
