import { Link, LinkProps } from '@mui/material';
import { ElementType } from 'react';

interface Props extends LinkProps {
  // 'component' exists as a prop in Link, but not in LinkProps for some reason
  component?: ElementType<any>;
}

function InlineLink({ children, sx = {}, ...rest }: Props): JSX.Element {
  return (
    <Link
      sx={{
        color: ({ palette }) => palette.common.black,
        textDecoration: 'underline',
        textUnderlineOffset: '3px',
        '&:hover': {
          color: ({ palette }) => palette.primary.dark,
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Link>
  );
}

export default InlineLink;
