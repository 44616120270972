import { alpha, createTheme, Theme } from '@mui/material';
import dapTheme from './dapTheme';

const colors = {
  purple: {
    primary: '#512AFC',
    gradient:
      'linear-gradient(283deg, #512AFC 0.09%, #623FFC 32.38%, #7455FD 62.89%, #856AFD 98.77%)',
  },
  blue: { 100: '#F4F5F7', 200: '#EBF2FF', 500: '#2692AA', 600: '#237C8F' },
  black: '#272727',
  grey: { 200: '#D9D9D9', 900: '#333333' },
  green: { 500: '#60AA26' },
};

const mgOrderTheme: Theme = createTheme(dapTheme, {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1354,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      light: colors.blue[100],
      main: colors.purple.primary,
      dark: colors.blue[600],
      ...colors.blue,
    },
    secondary: {
      main: colors.black,
    },
    success: { main: colors.green[500] },
    grey: colors.grey,
    colors: colors,
    text: {
      secondary: colors.grey[900],
    },
    divider: alpha(colors.blue[500], 0.3),
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.black,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          borderRadius: '20px',
          fontSize: '16px',
          width: '25px',
          height: '25px',
        },
      },
    },
  },
});

export default mgOrderTheme;
