import { SvgIcon, SvgIconProps } from '@mui/material';

const AlarmIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="27" height="27" viewBox="0 0 29 29" data-test-id="AlarmIcon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 19.5962C2 19.2153 2.15471 18.8508 2.42864 18.5862L3.45759 17.5924C3.84928 17.2141 4.06977 16.6924 4.06814 16.1479L4.05867 12.9948C4.04543 8.58343 7.61789 5.00024 12.0293 5.00024C16.4314 5.00024 20 8.56883 20 12.9709L20 16.1718C20 16.7023 20.2107 17.211 20.5858 17.586L21.5858 18.586C21.851 18.8512 22 19.211 22 19.586C22 20.3671 21.3668 21.0002 20.5858 21.0002H16C16 23.2094 14.2091 25.0002 12 25.0002C9.79086 25.0002 8 23.2094 8 21.0002H3.40408C2.62863 21.0002 2 20.3716 2 19.5962ZM10 21.0002C10 22.1048 10.8954 23.0002 12 23.0002C13.1046 23.0002 14 22.1048 14 21.0002H10ZM18 16.1718C18 17.2327 18.4214 18.2501 19.1716 19.0002L4.87851 19.0002C5.64222 18.2462 6.07136 17.2163 6.06813 16.1419L6.05867 12.9888C6.04875 9.68434 8.7248 7.00024 12.0293 7.00024C15.3268 7.00024 18 9.6734 18 12.9709L18 16.1718Z"
      />
      <rect
        x="12.8021"
        y="1.80208"
        width="12.3958"
        height="12.3958"
        rx="6.19792"
        stroke="#FCFCFC"
        strokeWidth="2.39583"
      />
      <rect x="13.9021" y="2.90208" width="10.0958" height="10.0958" rx="6.19792" fill="#FF6A55" />
    </SvgIcon>
  );
};

export default AlarmIcon;
