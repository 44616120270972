import { MessagePaths } from '@shared/i18n';
import { BrandResources } from '../types/BrandResources';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '@shared/utils';

type Format = 'capitalize' | 'uppercase' | 'lowercase';
type FormatFn = (str: string) => string;

const formatMap: Record<Format, FormatFn> = {
  capitalize: capitalizeFirstLetter,
  uppercase: (str) => str.toUpperCase(),
  lowercase: (str) => str.toLowerCase(),
};

const useBrandMessagesTranslation = <MessagesT extends Record<string, any>>(brandKey: string) => {
  const translation = useTranslation(brandKey);

  const appT = (key: MessagePaths<MessagesT>, format?: Format) => {
    const [keyNs, ...rest] = key.split('.');

    const text = translation.t(rest.join('.'), {
      ns: [brandKey, keyNs],
    });

    return format ? formatMap[format](text) : text;
  };

  return {
    ...translation,
    t: appT,
  };
};

export const useBrandTranslation = (brandKey: string) =>
  useBrandMessagesTranslation<BrandResources>(brandKey);

export type BrandMessageKey = MessagePaths<BrandResources>;

export const useBrandTFunction = (brandKey: string) => useBrandTranslation(brandKey).t;

export type BrandTFunction = ReturnType<typeof useBrandTFunction>;
