import { dot, object } from 'dot-object';
import { AppResources, defaultResources } from '../resources';

/**
 * Takes an object and sets all leaf values to their respective dotted string paths
 * Example: defineMessages({ a: { b: 'hello', c: 'yo' }}) -> { b: 'a.b', c: 'a.c' }
 */
const defineMessages = (obj: any): any => {
  const dottedCommons = Object.keys(dot(obj)).reduce((tmp, key) => ({ ...tmp, [key]: key }), {});

  return object(dottedCommons);
};

export const messages: AppResources = Object.entries(defaultResources).reduce(
  (tmpMessages, [resourceName, resourveValue]) => ({
    ...tmpMessages,
    [resourceName]: defineMessages(resourveValue),
  }),
  {} as AppResources
);
