import { SvgIcon, SvgIconProps } from '@mui/material';

export const NewsIcon = ({ fontSize }: Pick<SvgIconProps, 'fontSize'>) => {
  return (
    <SvgIcon fontSize={fontSize} width="14" height="14" viewBox="0 0 14 14" fill="none">
      <g clipPath="url(#clip0_3522_8039)">
        <path
          d="M0 3.5V11.8125C0 12.5371 0.587617 13.125 1.3125 13.125C2.03738 13.125 2.625 12.5371 2.625 11.8125V2.625H0.875C0.391836 2.625 0 3.01602 0 3.5ZM7.4375 7.875H4.8125C4.57188 7.875 4.375 8.07188 4.375 8.3125C4.375 8.55312 4.57188 8.75 4.8125 8.75H7.4375C7.67911 8.75 7.875 8.55411 7.875 8.3125C7.875 8.07188 7.67812 7.875 7.4375 7.875ZM9.1875 8.75H11.8125C12.0541 8.75 12.25 8.55411 12.25 8.3125C12.25 8.07188 12.0531 7.875 11.8125 7.875H9.1875C8.94687 7.875 8.75 8.07188 8.75 8.3125C8.75 8.55312 8.94687 8.75 9.1875 8.75ZM7.4375 10.5H4.8125C4.57188 10.5 4.375 10.6969 4.375 10.9375C4.375 11.1781 4.57188 11.375 4.8125 11.375H7.4375C7.67911 11.375 7.875 11.1791 7.875 10.9375C7.875 10.6969 7.67812 10.5 7.4375 10.5ZM11.8125 10.5H9.1875C8.94589 10.5 8.75 10.6958 8.75 10.9375C8.75 11.1791 8.94589 11.375 9.1875 11.375H11.8125C12.0541 11.375 12.25 11.1791 12.25 10.9375C12.25 10.6969 12.0531 10.5 11.8125 10.5Z"
          fill="currentColor"
        />
        <path
          opacity="0.4"
          d="M13.125 0.875H3.5C3.01602 0.875 2.625 1.26684 2.625 1.75V11.8125C2.625 12.5371 2.03738 13.125 1.3125 13.125H12.25C13.2166 13.125 14 12.3416 14 11.375V1.75C14 1.26684 13.609 0.875 13.125 0.875ZM7.4375 11.375H4.8125C4.57188 11.375 4.375 11.1781 4.375 10.9375C4.375 10.6969 4.57188 10.5 4.8125 10.5H7.4375C7.67911 10.5 7.875 10.6958 7.875 10.9375C7.875 11.1781 7.67812 11.375 7.4375 11.375ZM7.4375 8.75H4.8125C4.57188 8.75 4.375 8.55312 4.375 8.3125C4.375 8.07188 4.57188 7.875 4.8125 7.875H7.4375C7.67812 7.875 7.875 8.07188 7.875 8.3125C7.875 8.55312 7.67812 8.75 7.4375 8.75ZM11.8125 11.375H9.1875C8.94589 11.375 8.75 11.1791 8.75 10.9375C8.75 10.6958 8.94589 10.5 9.1875 10.5H11.8125C12.0541 10.5 12.25 10.6958 12.25 10.9375C12.25 11.1781 12.0531 11.375 11.8125 11.375ZM11.8125 8.75H9.1875C8.94688 8.75 8.75 8.55312 8.75 8.3125C8.75 8.07188 8.94688 7.875 9.1875 7.875H11.8125C12.0531 7.875 12.25 8.07188 12.25 8.3125C12.25 8.55312 12.0531 8.75 11.8125 8.75ZM12.25 5.6875C12.25 5.92812 12.0531 6.125 11.8125 6.125H4.8125C4.57188 6.125 4.375 5.92812 4.375 5.6875V3.0625C4.375 2.82187 4.57188 2.625 4.8125 2.625H11.8125C12.0531 2.625 12.25 2.82187 12.25 3.0625V5.6875Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3522_8039">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default NewsIcon;
