import { Country } from '@shared/types';

export const formatPostalCode = (
  postalCode: string | undefined,
  country: Country | undefined
): string => {
  if (!postalCode) {
    return '–';
  }

  const trimmedPostalCode = postalCode.replace(/\s/g, '');

  switch (country) {
    case 'NO':
      return postalCode;
    case 'SE':
      return trimmedPostalCode.substring(0, 3) + ' ' + trimmedPostalCode.substring(3);
    default:
      return postalCode;
  }
};
