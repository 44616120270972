import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { KeyInfo, SanityBlockContent } from '../common/commonFieldTypes';

export interface SanityEventPreview {
  title: string;
  intro: string;
  id: string;
  eventInformation: EventInformation;
  url: string;
  image?: SanityImageSource;
}

export interface SanityEvent extends SanityEventPreview {
  keyInfo?: KeyInfo;
  body?: SanityBlockContent[];
}

export interface EventInformation {
  dateTimeRange: DateTimeRange;
  address?: EventAddress;
  registration?: RegistrationViaUrl | RegistrationViaEmail;
  extraInfo?: ExtraInfo;
}

export enum RegistrationType {
  email = 'registrationEmail',
  url = 'registrationUrl',
}

interface RegistrationViaUrl {
  registrationUrl: string;
  registrationEmail: never;
}

interface RegistrationViaEmail {
  registrationUrl: never;
  registrationEmail: string;
}

export interface DateTimeRange {
  startDate: string;
  endDate?: string;
}

export interface EventAddress {
  street?: string;
  postalCode?: string;
  city?: string;
  addressFreeText?: string;
}

export type EventSourceType = 'upcoming' | 'upcoming_and_current' | 'current' | 'past';

export enum ExtraInfoType {
  personRef = 'personRef',
  externalLink = 'externalLink',
  freeText = 'freeText',
}

interface ExtraInfo {
  person: Array<ExtraInfoPersonField>;
  link: Array<ExtraInfoLinkField>;
  text: Array<ExtraInfoTextField>;
}

interface ExtraInfoPersonField {
  title: string;
  name: string;
}

interface ExtraInfoLinkField {
  title: string;
  url: string;
}

interface ExtraInfoTextField {
  title: string;
  value: string;
}
