import { useMemo, useRef } from 'react';
import { useScroll, useVisibleElements } from 'react-snaplist-carousel';

export function useCarousel(numItems: number) {
  const snapListRef = useRef(null);
  const visibleElements = useVisibleElements(
    { debounce: 10, ref: snapListRef },
    (elements) => elements
  );

  const { firstItemIsVisible, lastItemIsVisible, itemInFocus } = useMemo(() => {
    const firstItemIsVisible = visibleElements[0] === 0;
    const lastItemIsVisible = visibleElements.some(
      (elementIndex: number) => elementIndex === numItems - 1
    );
    // the "main" item in focus is the middle element of all visible elements
    const itemInFocus = visibleElements[Math.floor(visibleElements.length / 2)];

    return { firstItemIsVisible, lastItemIsVisible, itemInFocus };
  }, [numItems, visibleElements]);

  const goToSnapItem = useScroll({ ref: snapListRef });

  const scrollBack = () => {
    if (itemInFocus > 0) {
      goToSnapItem(itemInFocus - 1);
    }
  };
  const scrollForward = () => {
    if (itemInFocus < numItems - 1) {
      goToSnapItem(itemInFocus + 1);
    }
  };

  return {
    snapListRef,
    firstItemIsVisible,
    lastItemIsVisible,
    visibleElements,
    goToSnapItem,
    scrollForward,
    scrollBack,
  };
}
