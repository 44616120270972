import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import { TypedUseMutationResult } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import React from 'react';
import ErrorDefaultComponent from '../Fallbacks/ErrorDefaultComponent';
import LoadingDefaultComponent from '../Fallbacks/LoadingDefaultComponent';
import { LoadingErrorComponent } from './LoadingErrorComponent';

interface Props<DataT, ErrorT> {
  loadingState: TypedUseMutationResult<DataT, any, BaseQueryFn<any, unknown, ErrorT>>;
  children: (data: DataT) => JSX.Element;
  LoadingComponent?: React.ComponentType;
  ErrorComponent?: LoadingErrorComponent<ErrorT>;
  NoDataComponent?: React.ComponentType;
}

function RtkMutationResultWrapper<DataT, ErrorT>({
  loadingState: { data, error, isError, isLoading },
  LoadingComponent = LoadingDefaultComponent,
  ErrorComponent = ErrorDefaultComponent,
  NoDataComponent = () => null,
  children,
}: Props<DataT, ErrorT>) {
  if (isError) {
    return <ErrorComponent error={error as ErrorT} />;
  }
  if (isLoading) {
    return <LoadingComponent />;
  }
  if (!data) {
    return <NoDataComponent />;
  }

  return children(data);
}

export default RtkMutationResultWrapper;
