import { AppBasic, AppDetailed } from '@dap-admin/types';
import { useMemo } from 'react';

export const getApps = (
  existingApps: AppBasic[] = [],
  updatedApps: string[]
): {
  appsToBeRemoved: string[];
  appsToBeAdded: string[];
} => {
  const appsToBeRemoved: string[] =
    existingApps
      ?.filter(({ key }) => !updatedApps.some((app) => app === key))
      .map(({ key }) => key) || [];

  const appsToBeAdded: string[] =
    updatedApps?.filter((app) => !existingApps?.some(({ key }) => key === app)) || [];

  return {
    appsToBeRemoved,
    appsToBeAdded,
  };
};

export const getEditableApps = (
  existingApps: AppBasic[] = [],
  availableApps: AppDetailed[] = []
): AppBasic[] => {
  const existingNotInAvailable = existingApps.filter(
    (app) => !availableApps.some((avApp) => avApp.key === app.key)
  );

  return [
    ...availableApps.map((app) => ({
      key: app.key,
      name: app.name,
      default: app.default,
      uuid: app.uuid,
    })),
    ...existingNotInAvailable,
  ];
};

export function getIfDefaultApp(app: Pick<AppBasic | AppDetailed, 'default' | 'key'>) {
  return app.default;
}

export function getAppObjectFromAppKey(
  basis: Array<Pick<AppBasic | AppDetailed, 'key' | 'name' | 'default' | 'uuid'>>,
  arrayOfAppKeys: Array<string>
) {
  return basis.filter(({ key }) => arrayOfAppKeys.includes(key));
}

export function getBasicAppDetails(appDetailed: AppDetailed): AppBasic {
  const appBasic: AppBasic = {
    uuid: appDetailed.uuid,
    name: appDetailed.name,
    key: appDetailed.key,
    default: appDetailed.default,
  };
  return appBasic;
}

export function mapBasicToAppDetailed(appBasic: AppBasic): AppDetailed {
  const appDetailed: AppDetailed = {
    uuid: appBasic.uuid,
    name: appBasic.name,
    key: appBasic.key,
    default: appBasic.default,

    // not in basic
    updatedAt: null,
    updatedBy: null,
    config: {
      name: null,
      url: null,
    },
    url: null,
    ssoEnabled: true,
    integrationEnabled: false,
  };
  return appDetailed;
}

export function useServiceFilters(availableServices: Array<AppBasic>) {
  const defaultServices = useMemo(
    () => availableServices.filter((service) => getIfDefaultApp(service)),
    [availableServices]
  );

  const filteredServices = useMemo(
    () => availableServices.filter((service) => !getIfDefaultApp(service)),
    [availableServices]
  );

  return { defaultServices, filteredServices };
}

export default useServiceFilters;
