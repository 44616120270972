import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckboxIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M15.5,0 L4.5,0 C2.01471863,0 0,2.01471863 0,4.5 L0,15.5 C0,17.9852814 2.01471863,20 4.5,20 L15.5,20 C17.9852814,20 20,17.9852814 20,15.5 L20,4.5 C20,2.01471863 17.9852814,0 15.5,0 Z M4.5,1 L15.5,1 C17.4329966,1 19,2.56700338 19,4.5 L19,15.5 C19,17.4329966 17.4329966,19 15.5,19 L4.5,19 C2.56700338,19 1,17.4329966 1,15.5 L1,4.5 C1,2.56700338 2.56700338,1 4.5,1 Z"></path>
    </SvgIcon>
  );
};

export default CheckboxIcon;
