import { AppFeatureConfig } from '@dap-dashboard/types';
import { defaultBrandFeatures } from './defaultBrandFeatures';

const pretreFeaturesDefault: AppFeatureConfig = {
  ...defaultBrandFeatures,
};

export const pretreFeaturesDev: AppFeatureConfig = {
  ...pretreFeaturesDefault,
};
export const pretreFeaturesStage: AppFeatureConfig = {
  ...pretreFeaturesDefault,
};
export const pretreFeaturesProd: AppFeatureConfig = {
  ...pretreFeaturesDefault,
  completeRegistrationEmailInfo: { resolveToggle: () => 'off' },
};
