import { useContext } from 'react';
import { SanityContext, SanityContextType } from './SanityContext';

export function useSanityContext(): SanityContextType {
  const context = useContext(SanityContext);
  if (!context) {
    throw new Error('useSanityContext must be used within a SanityContextProvider');
  }
  return context;
}

export function useSanityContextActions() {
  return useSanityContext().actions;
}
