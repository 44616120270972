import React, { useCallback, useState } from 'react';

type DragEventHandler = (files: Array<File>, event: React.DragEvent<HTMLDivElement>) => void;
type ChangeEventHandler = (files: Array<File>, event: React.ChangeEvent<HTMLInputElement>) => void;

const useDragAndDrop = (isDragActive = false) => {
  const [isDragging, setIsDragging] = useState<boolean>(isDragActive);

  const onDrag = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.type === 'dragenter' || event.type === 'dragover') {
      setIsDragging(true);
    } else if (event.type === 'dragleave') {
      setIsDragging(false);
    }
  }, []);

  const onDrop = (eventHandler: DragEventHandler) => (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      eventHandler(Array.from(event.dataTransfer.files), event);
    }
  };

  const onInputChange =
    (eventHandler: ChangeEventHandler) => (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      if (event.target.files && event.target.files[0]) {
        eventHandler(Array.from(event.target.files), event);
      }
    };

  return { isDragging, onDrag, onDrop, onInputChange };
};

export default useDragAndDrop;
