import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { LocationState } from './locationReducer';

const useLocationStateSelector: TypedUseSelectorHook<{ location: LocationState }> = useSelector;

/**
 * @deprecated is currently in use during the ongoing transition to RTK Query. Delete after.
 */
export const useSelectedNewLocation = () => {
  const createState = useLocationStateSelector(
    ({ location: { createNewLocation } }) => createNewLocation
  );
  return createState;
};
