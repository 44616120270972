import { useAppTFunction } from '@dap-common/i18n';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Paper } from '@shared/components';
import { FC } from 'react';
import ErrorTemplate from './ErrorTemplate';

export interface SentryErrorProps {
  componentStack: string;
  error: Error;
  eventId: string;
  resetError: () => void;
}

const SentryErrorFallback: FC<SentryErrorProps> = ({
  componentStack,
  error,
  eventId,
  resetError,
}) => {
  const t = useAppTFunction();

  return (
    <Paper sx={{ height: '100%' }}>
      <ErrorTemplate
        icon={<PriorityHighIcon />}
        title={t('error.generic')}
        subtitle={t('error.contact')}
        errorMessage={error.message + ' ' + componentStack}
      />
    </Paper>
  );
};

export default SentryErrorFallback;
