import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { useGetBrandQuery } from '../../api/brandApi';
import { BrandState } from './brandReducer';

const useBrandStateSelector: TypedUseSelectorHook<{ brand: BrandState }> = useSelector;

export const useSelectedBrandKey = () => {
  return useBrandStateSelector(({ brand: { selectedBrandKey } }) => selectedBrandKey);
};

export const useBrand = () => {
  const brandKey = useSelectedBrandKey();
  const getBrandQueryState = useGetBrandQuery(brandKey, { skip: !brandKey });

  if (!getBrandQueryState.data) {
    throw new Error('Cannot get brand');
  }

  return getBrandQueryState.data;
};

export const useBrandState = () => {
  const brandKey = useSelectedBrandKey();
  const getBrandQueryState = useGetBrandQuery(brandKey, { skip: !brandKey });

  return getBrandQueryState;
};
