import { dapBffConfig } from './dapBffConfig';

export const dapBffBaseUrl = dapBffConfig.url;

const apiPaths = {
  workplace: '/v1/workplace',
  omnium: '/v1/omnium',
  events: 'v1/datahubexplorer/find/events',
};

export const WORKPLACE = apiPaths.workplace;
export const OMNIUM = apiPaths.omnium;

// WORKPLACE
export const WORKPLACE_FEED = (brandKey: string) => `${WORKPLACE}/${brandKey}`;

// OMNIUM
export const OMNIUM_LEADS_DAILY_REPORT = `${OMNIUM}/leads/dailyReport`;
export const OMNIUM_LEADS_NEW = `${OMNIUM}/leads/new`;
export const OMNIUM_LEADS_URGENT = `${OMNIUM}/leads/urgent`;

export const OMNIUM_ORDER_MGA = `${OMNIUM}/order/mga`;

// EVENTS
export const FIND_EVENTS = apiPaths.events;