import { LinkProps } from '@mui/material';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import InlineLink from './InlineLink';

interface Props extends LinkProps {
  to: RouterLinkProps['to'];
}

function InlineInternalLink({ children, ...linkProps }: Props): JSX.Element {
  return (
    <InlineLink {...linkProps} component={RouterLink}>
      {children}
    </InlineLink>
  );
}

export default InlineInternalLink;
