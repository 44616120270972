import { AppFeatureConfig } from '@dap-dashboard/types';
import { defaultBrandFeatures } from './defaultBrandFeatures';

export const saltdalshyttaBrandFeaturesDefault: AppFeatureConfig = {
  ...defaultBrandFeatures,
};

export const saltdalshyttaBrandFeaturesDev: AppFeatureConfig = {
  ...saltdalshyttaBrandFeaturesDefault,
};
export const saltdalshyttaBrandFeaturesStage: AppFeatureConfig = {
  ...saltdalshyttaBrandFeaturesDefault,
};
export const saltdalshyttaBrandFeaturesProd: AppFeatureConfig = {
  ...saltdalshyttaBrandFeaturesDefault,
  completeRegistrationEmailInfo: { resolveToggle: () => 'off' },
};
