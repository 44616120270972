import * as yup from 'yup';

export enum ConfigChoice {
  custom = 'custom',
  default = 'default',
}

interface ErrorMessages {
  urlType: string;
  nameType: string;
  name: string;
  url: string;
}

const configChoices = Object.values(ConfigChoice);

const yupCustomOrDefaultType = yup.mixed().oneOf([...configChoices, null]);

export const appConfigSchema = (errorMessages: ErrorMessages) =>
  yup.object().shape({
    appKey: yup.string(),
    available: yup.boolean(),
    default: yup.boolean(),
    urlType: yupCustomOrDefaultType.when('available', {
      is: true,
      then: yupCustomOrDefaultType.required(errorMessages.urlType),
      otherwise: yupCustomOrDefaultType,
    }),
    nameType: yupCustomOrDefaultType.when('available', {
      is: true,
      then: yupCustomOrDefaultType.required(errorMessages.nameType),
      otherwise: yupCustomOrDefaultType,
    }),
    name: yup.string().when(['available', 'nameType'], {
      is: (availableValue: boolean, nameTypeValue: ConfigChoice) =>
        availableValue && nameTypeValue === ConfigChoice.custom,
      then: yup.string().required(errorMessages.name).default(''),
      otherwise: yup.string().default(''),
    }),
    url: yup.string().when(['available', 'urlType'], {
      is: (availableValue: boolean, urlTypeValue: ConfigChoice) =>
        availableValue && urlTypeValue === ConfigChoice.custom,
      then: yup.string().required(errorMessages.url).default(''),
      otherwise: yup.string().default(''),
    }),
  });

export interface AppConfigSchema {
  appKey: string;
  available: boolean;
  default: boolean;
  nameType: ConfigChoice;
  name?: string;
  urlType: ConfigChoice | null;
  url?: string | null;
}
