import { alpha } from '@mui/material';
import {
  createTheme,
  PaletteColor,
  PaletteOptions,
  responsiveFontSizes,
} from '@mui/material/styles';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CheckboxIcon, CheckboxIconChecked } from '@shared/custom-icons';
import { themePalette } from './themePalette';
import { headerAppBarHeight } from './styleConstants';
import {
  circularStdBold,
  circularStdBoldItalic,
  circularStdBook,
  circularStdBookItalic,
  circularStdMedium,
  circularStdMediumItalic,
  figtreeItalic,
  figtreeVariable,
} from '@assets/fonts';

const focusVisibleButtonShadow =
  '0px 5px 5px -3px rgb(105, 105, 105, 0.25), 0px 6px 10px 1px rgb(105, 105, 105, 0.2), 0px 3px 14px 2px rgb(105, 105, 105, 0.18);';

const elevation1Shadow = '2px 2px 4px rgba(0, 0, 0, 0.12)';

const dapTheme = createTheme({
  palette: themePalette as PaletteOptions,
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: ['Circular Std', 'sans-serif'].join(','),
    h1: {
      fontWeight: 700,
      fontSize: '22px',
    },
    h2: {
      fontWeight: 700,
      fontSize: '18px',
    },
    h3: {
      fontWeight: 700,
      fontSize: '16px',
    },
    h4: {
      fontWeight: 700,
      fontSize: '14px',
    },
    h5: {
      fontWeight: 500,
      fontSize: '12px',
    },
    body1: {
      fontWeight: 450,
      fontSize: '16px',
    },
    body2: {
      fontWeight: 450,
      fontSize: '14px',
    },
    body3: {
      fontWeight: 450,
      fontSize: '12px',
    },
    body3Light: {
      fontWeight: 450,
      color: `${themePalette.grey[900]}`,
      fontSize: '12px',
    },
    body4: {
      fontWeight: 450,
      fontSize: '10px',
    },
    body4Light: {
      fontWeight: 450,
      color: alpha(themePalette.grey[900], 0.7),
      fontSize: '10px',
    },
    body5: {
      fontWeight: 450,
      fontSize: '8px',
    },
    filterHeader: {
      color: '#021351',
    },
    smallBold: {
      fontSize: '14px',
      fontWeight: 500,
    },
    capitalizedTitleBold: {
      fontSize: '14px',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: headerAppBarHeight,
          backgroundColor: themePalette.purple[50],
          boxShadow: 'none',
          padding: 0,
          paddingX: 50,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'space-between',
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'initial',
          height: '2.1rem',
          border: 'none !important',
          borderRadius: 0,
          backgroundColor: 'transparent',
          padding: '0.25rem',
          color: themePalette.grey[400],
          '&.Mui-selected': {
            backgroundColor: 'transparent',
            color: themePalette.grey[900],
          },
          '&:hover': {
            backgroundColor: themePalette.grey[200],
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: '7px',
          padding: '5px 16px',
          textTransform: 'none',
          boxShadow: 'none',
          '&.Mui-focusVisible': {
            boxShadow: focusVisibleButtonShadow,
          },
          minWidth: 'auto',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textAlign: 'left',
          display: 'flex',
          fontWeight: 500,

          '&.MuiButton-outlinedSuccess': {
            color: theme.palette.text.primary,
          },
          '&.MuiButton-textGrey': {
            color: alpha(theme.palette.grey[600], 0.7),
          },
          ...(ownerState.variant === 'contained' && {
            ...(ownerState.color !== 'inherit' &&
              ownerState.color && {
                '&:hover': {
                  backgroundColor: (theme.palette[ownerState.color] as PaletteColor).main,
                },
              }),
          }),
        }),
      },
      variants: [
        {
          props: { variant: 'rounded' },
          style: {
            borderRadius: '20px',
            boxShadow: 'none',
            color: alpha(themePalette.grey[900], 0.8),
            fontWeight: 450,
            border: `1px solid ${alpha(themePalette.grey[900], 0.4)}`,
            padding: '5px 10px',
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': {
            boxShadow: focusVisibleButtonShadow,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            fontSize: 14,
            fontWeight: 450,
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 450,
          borderRadius: 10,
          backgroundColor: themePalette.grey[100],
          borderColor: themePalette.common.white,
          '&.Mui-focused': {
            backgroundColor: themePalette.common.white,
          },
          '&.MuiInputBase-colorGrey': {
            backgroundColor: themePalette.grey[900],
            color: themePalette.common.white,
            textTransform: 'capitalize',
            borderRadius: '7px',
            textAlign: 'center',
            '& .MuiSvgIcon-root': {
              color: themePalette.common.white,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': {
            backgroundColor: alpha(themePalette.primary.main, 0.2),
          },
        },
      },
      variants: [
        {
          props: { className: 'navigationListItemButton' },
          style: {
            padding: 0,
            borderTop: `1px solid ${alpha(themePalette.primary.dark, 0.1)}`,
            '&:last-child': {
              borderBottom: `1px solid ${alpha(themePalette.primary.dark, 0.1)}`,
            },
            height: '6.125rem',
            backgroundColor: themePalette.common.white,
            '&:hover': {
              backgroundColor: alpha(themePalette.primary.main, 0.2),
            },
            '&.Mui-selected': {
              backgroundColor: alpha(themePalette.primary.dark, 0.05),
              border: `1px solid ${themePalette.primary.dark}`,
              borderRadius: '4px',
              '.MuiTypography-root': {
                color: themePalette.primary.dark,
              },
              '&:hover': {
                backgroundColor: alpha(themePalette.primary.main, 0.2),
              },
            },
          },
        },
      ],
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: themePalette.grey[900],
          fontWeight: 700,
          textTransform: 'unset',
          padding: '8px 20px 8px 0',
          borderRadius: '10px 10px 0 0',
          opacity: 0.8,
          '&.Mui-selected': {
            color: themePalette.text.primary,
            opacity: 1,
          },
          '&.Mui-focusVisible': {
            backgroundImage:
              'linear-gradient(200deg, rgba(227, 227, 227, 0), rgba(227, 227, 227, 1))',
          },
          '&.MuiButtonBase-root': {
            minHeight: '32px',
            textAlign: 'left',
            alignItems: 'self-start',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '34px',
        },
        indicator: {
          display: 'flex',
          backgroundColor: 'transparent',
          '& .MuiTabs-indicatorSpan': {
            maxWidth: 26,
            width: '100%',
            backgroundColor: themePalette.primary.main,
            borderRadius: '15px',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: themePalette.text.primary,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: themePalette.common.white,
          border: `1px solid ${alpha(themePalette.primary.dark, 0.1)}`,
          borderRadius: '7px',
        },
      },
      defaultProps: {
        elevation: 0,
      },
      variants: [
        {
          props: { color: 'primary' },
          style: {
            background: themePalette.cardBackgrounds.lightPurple,
            border: `1px solid ${alpha(themePalette.primary.dark, 0.1)}`,
          },
        },
      ],
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '15px 20px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '15px 20px',
          ':last-child': {
            paddingBottom: '15px',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: themePalette.common.white,
          backgroundColor: themePalette.grey[900],
          borderRadius: '7px',
        },
        arrow: {
          color: themePalette.grey[900],
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          fontSize: 1,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: themePalette.grey[900],
          typography: theme.typography.body2,
          '.MuiFormLabel-asterisk': {
            color: theme.palette.error.light,
            fontSize: '1rem',
          },
        }),
      },
    },
    // MuiPickersLayout: {
    //   styleOverrides: {
    //     root: {
    //       maxWidth: '320px',
    //       borderRadius: '5px',
    //       '.MuiIconButton-root': {
    //         height: '25px',
    //         width: '25px',
    //         borderRadius: '5px',
    //         '&:hover': {
    //           background: themePalette.grey[200],
    //         },
    //       },
    //       '.MuiButtonBase-root.MuiPickersDay-root': {
    //         '&:hover': {
    //           background: themePalette.grey[200],
    //         },
    //       },
    //       '.MuiTypography-caption': {
    //         fontSize: '1rem',
    //         color: themePalette.grey[900],
    //         fontWeight: '500',
    //       },
    //       div: {
    //         justifyContent: 'space-between',
    //       },
    //     },
    //   },
    // },
    // MuiPickersDay: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: 'transparent',
    //       '&.Mui-selected': {
    //         backgroundColor: themePalette.grey[900],
    //       },
    //     },
    //   },
    // },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: '2rem',
          height: '2rem',
        },
      },
    },
    MuiChip: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        label: {
          fontWeight: 450,
          borderColor: alpha(themePalette.common.black, 0.1),
        },
      },
      variants: [
        {
          props: { variant: 'large' },
          style: {
            margin: '5px 10px 5px 0px',
            backgroundColor: 'transparent',
            border: `1px solid ${alpha(themePalette.border.darkPurple, 0.1)}`,
            '& .MuiChip-label': {
              color: alpha(themePalette.text.secondary, 0.8),
              padding: '0 20px',
              fontWeight: 500,
              fontSize: '1rem',
            },
          },
        },
        {
          props: { variant: 'small' },
          style: {
            '& .MuiChip-label': {
              color: themePalette.text.tertiary,
            },
          },
        },
      ],
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'default' },
          style: {
            backgroundColor: themePalette.background.paper,
          },
        },
        {
          props: { variant: 'primary' },
          style: {
            backgroundColor: themePalette.background.primary,
          },
        },
      ],
      styleOverrides: {
        root: {
          padding: '0.625rem',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 8,
          '& .MuiSwitch-track': {
            borderRadius: 22 / 2,
            backgroundColor: themePalette.grey[300],
            border: `1px solid ${themePalette.grey[800]}`,
          },
          '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: 20,
            height: 20,
          },
        },
        sizeSmall: {
          '& .MuiSwitch-track': {
            height: '12px',
            border: 0,
          },
          '& .MuiSwitch-thumb': {
            border: `1px solid ${themePalette.grey[300]}`,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '& .MuiAccordionSummary-root': {
            padding: 0,
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          },
          '& .MuiAccordionDetails-root': {
            padding: '0.5rem',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '10px',
          borderRadius: '7px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '10px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0 10px 10px',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: ({ theme }) => ({
          borderRadius: '7px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: alpha(themePalette.primary.dark, 0.1),
          boxShadow: 'none',
        }),
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          margin: 0,
        },
        root: {
          color: themePalette.text.primary,
          fontSize: '12px',
          fontWeight: 500,
          '& .MuiTypography-root': {
            fontSize: '12px',
            fontWeight: 500,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxIcon fontSize="small" />,
        checkedIcon: <CheckboxIconChecked fontSize="small" />,
      },
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': {
            filter: 'drop-shadow(2px 2px 4px rgba(105, 105, 105, 0.4))',
          },
          color: alpha(themePalette.common.black, 0.5),
          '.MuiSvgIcon-root': {
            borderRadius: '4.5px',
            backgroundColor: themePalette.common.white,
          },
          '&.Mui-disabled': {
            '.MuiSvgIcon-root': {
              color: themePalette.grey[400],
              background: themePalette.grey[200],
            },
          },
        },
      },
      variants: [
        {
          props: { color: 'grey' },
          style: {
            '&.Mui-focusVisible': {
              filter: 'drop-shadow(2px 2px 4px rgba(105, 105, 105, 0.4))',
            },
            color: themePalette.border.darkPurple,
            '.MuiSvgIcon-root': {
              backgroundColor: themePalette.grey[100],
            },
            '&.Mui-checked': {
              color: themePalette.border.darkPurple,
              '.check': { color: themePalette.text.primary },
            },
            '&.Mui-disabled': {
              '.MuiSvgIcon-root': {
                color: themePalette.grey[400],
                background: themePalette.grey[200],
              },
            },
          },
        },
      ],
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: ({ theme }) => ({
          '& .MuiPaper-root': {
            boxShadow: 'none',
            borderRadius: theme.shape.borderRadius,
            border: `1px solid ${alpha(theme.palette.primary.dark, 0.1)}`,
          },
        }),
        listbox: ({ theme }) => ({
          ...theme.typography.body2,
          '& .MuiAutocomplete-option.Mui-focused': {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          },
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
            font-family: "Circular Std";
            font-style: italic;
            font-weight: 400;
            src: url("${circularStdMediumItalic}") format("opentype");
        }

        @font-face {
            font-family: "Circular Std";
            font-style: italic;
            font-weight: 450;
            src: url("${circularStdBookItalic}") format("opentype");
        }

        @font-face {
            font-family: "Circular Std";
            font-style: italic;
            font-weight: 500;
            src: url("${circularStdMediumItalic}") format("opentype");
        }

        @font-face {
            font-family: "Circular Std";
            font-style: italic;
            font-weight: 700;
            src: url("${circularStdBoldItalic}") format("opentype");
        }

        @font-face {
            font-family: "Circular Std";
            font-style: normal;
            font-weight: 400;
            src: url("${circularStdMedium}") format("opentype");
        }

        @font-face {
            font-family: "Circular Std";
            font-style: normal;
            font-weight: 450;
            src: url("${circularStdBook}") format("opentype");
        }

        @font-face {
            font-family: "Circular Std";
            font-style: normal;
            font-weight: 500;
            src: url("${circularStdMedium}") format("opentype");
        }

        @font-face {
            font-family: "Circular Std";
            font-style: normal;
            font-weight: 700;
            src: url("${circularStdBold}") format("opentype");
        }

        @font-face {
            font-family: "Figtree Variable";
            font-style: italic;
            src: url("${figtreeItalic}") format("truetype");
        }

        @font-face {
            font-family: "Figtree Variable";
            font-style: normal;
            src: url("${figtreeVariable}") format("truetype");
        }
      `,
    },
  },
});

dapTheme.shadows[1] = elevation1Shadow;

export default responsiveFontSizes(dapTheme);
