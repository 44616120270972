import React from 'react';
import { useTranslation } from 'react-i18next';
import { messages } from '@dap-common/i18n';
import { Typography } from '@mui/material';

const GenericFallback = () => {
  const { t } = useTranslation();
  return <Typography>{t(messages.common.error.generic)}</Typography>;
};

export default GenericFallback;
