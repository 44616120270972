import { useMemo } from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { App } from './SidebarAppMenu';

type AppLinkProps = {
  app: App;
  expandedView: boolean;
};

export const SidebarAppLink = ({ app: { name, url, Icon }, expandedView }: AppLinkProps) => {
  const listItemStyles = useMemo(() => getListItemStyles(expandedView), [expandedView]);
  const listItemTextStyles = useMemo(() => getListItemTextStyles(expandedView), [expandedView]);

  return (
    <ListItem disablePadding sx={listItemStyles}>
      <Tooltip title={expandedView ? '' : name} arrow placement="right">
        <ListItemButton sx={listItemButtonStyles} href={url} target="_blank">
          <ListItemIcon sx={listItemIconStyles}>
            <Icon fontSize="small" />
          </ListItemIcon>

          <ListItemText
            disableTypography
            primary={<Typography sx={{ fontWeight: 500 }}>{name}</Typography>}
            sx={listItemTextStyles}
          />
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
};

export const getListItemStyles = (expandedView: boolean): SxProps<Theme> => ({
  height: '48px',
  width: expandedView ? '100%' : '48px',
  mb: '0.5rem',
});

const listItemButtonStyles: SxProps<Theme> = ({ palette }) => ({
  height: '50px',
  color: palette.grey.A400,
  borderRadius: 3,
  justifyContent: 'center',
});

const listItemIconStyles: SxProps<Theme> = ({ palette }) => ({
  color: palette.grey.A400,
  minWidth: 20,
});

const getListItemTextStyles = (expandedView: boolean): SxProps<Theme> => ({
  marginLeft: '1rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...(!expandedView && { display: 'none' }),
});
