import { alpha, styled } from '@mui/material';

interface Props {
  href: string;
  imageSrc: string;
  text: string;
}

export default function StandardMosaic({ href, imageSrc, text }: Props) {
  return (
    <a href={href}>
      <Card>
        <Image imageSrc={imageSrc} />
        <TextOverlay>{text}</TextOverlay>
      </Card>
    </a>
  );
}

const Card = styled('div')(({ theme }) => ({
  width: '100%',
  height: '224px',
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  overflow: 'hidden',
}));

const Image = styled('div')<{ imageSrc: string }>(({ theme, imageSrc }) => ({
  background: `${alpha(theme.palette.common.black, 0.1)} url('${imageSrc}') no-repeat`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundBlendMode: 'overlay',
  width: '100%',
  height: '100%',
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  transform: 'none',
  transition: 'transform ease 0.5s',

  ':hover, :focus-within, :focus': {
    transform: 'scale(1.1)',
  },
}));

const TextOverlay = styled('span')(({ theme }) => ({
  display: 'block',
  position: 'absolute',
  bottom: 0,
  height: '50px',
  background: `${alpha(theme.palette.common.black, 0.5)}`,
  boxShadow: `4px 0px 4px ${alpha(theme.palette.common.black, 0.25)}`,
  backdropFilter: 'blur(4px)',
  borderRadius: '0px 10px 0px 7px',
  color: theme.palette.common.white,
  padding: '15px 30px',
}));
