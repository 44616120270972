import { ajax, AjaxError as rxjsAjaxError } from 'rxjs/ajax';

export const bearerToken = (token: string) => ({
  Authorization: `Bearer ${token}`,
});

export const getRequest = <TResponse>(url: string, headers?: Record<string, string>) => {
  return ajax.get<TResponse>(url, headers);
};

export const putRequest = <TResponse>(
  url: string,
  headers?: Record<string, string>,
  body?: any
) => {
  return ajax.put<TResponse>(url, body, headers);
};

export const postRequest = <TResponse>(
  url: string,
  headers?: Record<string, string>,
  body?: any
) => {
  return ajax.post<TResponse>(url, body, headers);
};

export const deleteRequest = <TResponse>(
  url: string,
  headers?: Record<string, string>,
  body?: any
) => {
  return ajax<TResponse>({ method: 'DELETE', url, body, headers });
};

export const patchRequest = <TResponse>(
  url: string,
  headers?: Record<string, string>,
  body?: any
) => {
  return ajax.patch<TResponse>(url, body, headers);
};

export type AjaxError = Pick<rxjsAjaxError, 'message' | 'status' | 'response'>;
