import { Avatar as MuiAvatar, AvatarProps } from '@mui/material';

interface Props extends AvatarProps {
  imgSrc?: string;
  name: string;
}

const Avatar = ({ imgSrc, name, ...rest }: Props) => {
  return imgSrc ? (
    <MuiAvatar alt={name} src={imgSrc} {...rest} />
  ) : (
    <MuiAvatar {...rest}>{name.charAt(0)}</MuiAvatar>
  );
};

export default Avatar;
