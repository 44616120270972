import { Box } from '@mui/material';
import { Paper } from '../Block';
import { PropsWithChildren } from 'react';
import { controlledElementA11yProps } from '@shared/utils';

/* Use the same id as Tabs  */
interface Props {
  prefixId: string;
  value: string | number;
  hidden: boolean;
}

export default function TabPanel({ children, prefixId, value, hidden }: PropsWithChildren<Props>) {
  return (
    <Box role="tabpanel" {...controlledElementA11yProps(prefixId, value)} hidden={hidden}>
      <Paper tabPanel>{children}</Paper>
    </Box>
  );
}
