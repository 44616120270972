import { LandingPageImage } from '@assets/images';
import { messages } from '@dap-common/i18n';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

function LandingPageIllustration() {
  const { t } = useTranslation();

  return (
    <Box
      display={['none', 'none', 'block']}
      component="img"
      src={LandingPageImage}
      height={[0, 0, '500px', '815px']}
      alt={t(messages.common.landingPage.illustrationAlt)}
      sx={{ aspectRatio: '466 / 500' }}
    />
  );
}

export default LandingPageIllustration;
