import { SvgIcon, SvgIconProps } from '@mui/material';

const AddEntityIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="29" height="29" viewBox="0 0 29 29">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5451 26.6031C23.4488 26.6031 26.6134 23.4386 26.6134 19.5349C26.6134 15.6313 23.4488 12.4667 19.5451 12.4667C15.6415 12.4667 12.4769 15.6313 12.4769 19.5349C12.4769 23.4386 15.6415 26.6031 19.5451 26.6031ZM15.0656 19.5349C15.0656 18.9826 15.5133 18.5349 16.0656 18.5349H18.5451V16.0554C18.5451 15.5031 18.9928 15.0554 19.5451 15.0554C20.0974 15.0554 20.5451 15.5031 20.5451 16.0554V18.5349H23.0247C23.577 18.5349 24.0247 18.9826 24.0247 19.5349C24.0247 20.0872 23.577 20.5349 23.0247 20.5349H20.5451V23.0145C20.5451 23.5668 20.0974 24.0145 19.5451 24.0145C18.9928 24.0145 18.5451 23.5668 18.5451 23.0145V20.5349H16.0656C15.5133 20.5349 15.0656 20.0872 15.0656 19.5349Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.268 15.0745H9.38171C9.1165 15.0745 8.86214 15.1799 8.67461 15.3674C8.48707 15.5549 8.38171 15.8093 8.38171 16.0745V22.0746C8.38171 22.3398 8.27637 22.5942 8.08886 22.7817C7.90135 22.9692 7.64703 23.0746 7.38184 23.0746L1.38257 23.0754C1.25124 23.0754 1.12119 23.0496 0.999849 22.9993C0.878509 22.9491 0.768255 22.8754 0.675384 22.7826C0.582512 22.6897 0.508841 22.5795 0.458579 22.4581C0.408316 22.3368 0.382446 22.2068 0.382446 22.0754V10.5179C0.382446 10.3786 0.411558 10.2408 0.467915 10.1134C0.524271 9.98595 0.60663 9.87172 0.70971 9.77799L10.709 0.686143C10.8931 0.518777 11.1329 0.426034 11.3817 0.426025C11.6305 0.426017 11.8704 0.518745 12.0544 0.686099L22.0551 9.77799C22.1582 9.87171 22.2406 9.98595 22.297 10.1134C22.3533 10.2408 22.3824 10.3786 22.3824 10.5179V11.6389C21.3938 11.1814 20.2925 10.9261 19.1316 10.9261C16.1517 10.9261 13.5646 12.6081 12.268 15.0745Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default AddEntityIcon;
