export enum BrandTags {
  SELECTED_BRAND = 'SELECTED_BRAND',
  BRAND_EMPLOYEES = 'BRAND_EMPLOYEES',
  BRAND_LOCATIONS = 'BRAND_LOCATIONS',
  BRAND_DATAOWNERS = 'BRAND_DATAOWNERS',
  BRAND_INHERITABLE_PROPERTIES = 'BRAND_INHERITABLE_PROPERTIES',
}

export enum DataownerTags {
  SEARCH_DATAOWNERS = 'SEARCH_DATAOWNERS',
  DATAOWNER = 'DATAOWNER',
  EMPLOYEES = 'DATAOWNER_EMPLOYEES',
}

export enum EmployeeTags {
  EMPLOYEE = 'EMPLOYEE',
  SEARCH_EMPLOYEES = 'SEARCH_EMPLOYEES',
}

export enum LocationTags {
  LOCATION = 'LOCATION',
  SEARCH_LOCATIONS = 'SEARCH_LOCATIONS',
  LOCATION_EMPLOYEES = 'LOCATION_EMPLOYEES',
}

export enum UserTags {
  USER = 'USER',
}

export enum ServiceTags {
  SERVICES = 'SERVICES',
}

export enum PropertyTags {
  VALUE_TYPES = 'VALUE_TYPES',
}
