import { alpha, Box, Container } from '@mui/material';
import { messages } from '@dap-common/i18n';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import { brandStylesConfig } from '@dap-common/styles';
import { brandKeyFromCurrentUrl } from '@dap-common/utils';

interface Props {
  children: ReactNode;
}

function LandingPageLayout({ children }: Props) {
  const { t } = useTranslation();

  const brandStyle = brandKeyFromCurrentUrl
    ? brandStylesConfig[brandKeyFromCurrentUrl]
    : brandStylesConfig.default;

  return (
    <Box
      component="main"
      sx={{
        background: ({ palette }) =>
          `linear-gradient(75deg, ${alpha(palette.primary.light, 0.1)} 35%, ${
            palette.common.white
          })`,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'space-around',
          position: 'relative',
          alignItems: 'center',
        }}
      >
        <img
          src={brandStyle.logo}
          height="75px"
          style={{ position: 'absolute', top: '30px', left: '40px' }}
          alt={t(messages.common.image.logo)}
        />
        {children}
      </Container>
    </Box>
  );
}

export default LandingPageLayout;
