export enum ViewMode {
  List = 'List',
  Grid = 'Grid',
}

export enum SortOptions {
  ascName = 'nameAsc',
  descName = 'nameDesc',
}

export enum EmployeeSortOptions {
  ascFamilyName = 'familyNameAsc',
  descFamilyName = 'familyNameDesc',
  ascGivenName = 'givenNameAsc',
  descGivenName = 'givenNameDesc',
}

export enum RoleType {
  Bedriftsutvikler = 'bedriftsutvikler',
  Regionschef = 'regionschef',
  Leder = 'leder',
  Vd = 'vd',
  Kjedeadmin = 'kjedeadmin',
  SuperUser = 'superuser',
  BrandAdmin = 'brandadmin',
  DataownerAdmin = 'dataowneradmin',
  LocationAdmin = 'locationadmin',
  MasterdataAdmin = 'masterdata',
}

export enum AppType {
  BrandAdmin = 'brandadmin',
}
