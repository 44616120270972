import { brandAdminConfig } from './brandAdminConfig';

export const iamUrl = brandAdminConfig.iam.url;

const apiPathsV2 = {
  me: `${iamUrl}/v2/employees/me`,
  brands: `${iamUrl}/v2/brands`,
  employees: `${iamUrl}/v2/employees`,
  dataowner: `${iamUrl}/v2/dataowners`,
  location: `${iamUrl}/v2/locations`,
  services: `${iamUrl}/v2/apps`,
  properties: `${iamUrl}/v2/properties`,
};

// Service
export const SERVICES_V2 = `${apiPathsV2.services}`;
export const SERVICES_V2_KEY = (key: string) => `${SERVICES_V2}/${key}`;

// Brand
export const BRANDS_V2 = (brand?: string) => `${apiPathsV2.brands}${brand ? `/${brand}` : ''}`;
export const BRANDS_V2_PUBLISH_APP = (brand: string, app: string) =>
  `${BRANDS_V2(brand)}/apps/${app}/publish`;
export const BRANDS_V2_EMPLOYEES = (brand: string) => `${BRANDS_V2(brand)}/employees`;
export const BRANDS_V2_EMPLOYEES_EMPLOYEE = (brand: string, userId: string) =>
  `${BRANDS_V2(brand)}/employees/${userId}`;
export const BRANDS_V2_LOCATIONS = (brand: string) => `${BRANDS_V2(brand)}/locations`;
export const BRANDS_V2_DATAOWNERS = (brand: string) => `${BRANDS_V2(brand)}/dataowners`;
export const BRANDS_V2_LOCATION_PROPERTIES = (brand: string) =>
  `${BRANDS_V2(brand)}/locationproperties`;
export const BRANDS_V2_REGIONS = (brand: string) => `${BRANDS_V2(brand)}/regions`;
export const BRANDS_V2_REGIONS_KEY = (brand: string, regionId: string) =>
  `${BRANDS_V2_REGIONS(brand)}/${regionId}`;
export const BRANDS_V2_LOCATION_FIELD_CONFIG = (brand: string, id?: number) =>
  `${BRANDS_V2(brand)}/locationfieldconfigs${id ? `/${id}` : ''}`;
export const BRANDS_V2_PROPERTIES = (brandKey: string) => `${BRANDS_V2(brandKey)}/properties`;
export const BRANDS_V2_PROPERTY_KEY = (brandKey: string, propertyKey: string) =>
  `${BRANDS_V2_PROPERTIES(brandKey)}/${propertyKey}`;
export const BRANDS_V2_INHERITABLE_PROPERTIES = (brandKey: string) =>
  `${BRANDS_V2(brandKey)}/inheritableproperties`;
export const BRANDS_V2_INHERITABLE_PROPERTIES_KEY = (brandKey: string, propertyKey: string) =>
  `${BRANDS_V2_INHERITABLE_PROPERTIES(brandKey)}/${propertyKey}`;

//Dataowner
export const DATAOWNER = `${apiPathsV2.dataowner}`;
export const DATAOWNER_KEY = (dataownerKey: string) => `${DATAOWNER}/${dataownerKey}`;
export const DATAOWNER_LOCATIONS = (dataownerKey: string) =>
  `${DATAOWNER_KEY(dataownerKey)}/locations`;
export const DATAOWNER_EMPLOYEES = (dataownerKey: string) =>
  `${DATAOWNER_KEY(dataownerKey)}/employees`;
export const DATAOWNER_APPS = (dataownerKey: string) => `${DATAOWNER_KEY(dataownerKey)}/apps`;
export const DATAOWNER_KEY_VALUES = (dataownerKey: string) =>
  `${DATAOWNER_KEY(dataownerKey)}/keyvalues`;
export const DATAOWNER_V2_PROPERTIES = (dataownerKey: string) =>
  `${DATAOWNER_KEY(dataownerKey)}/properties`;
export const DATAOWNER_V2_PROPERTY_KEY = (dataownerKey: string, propertyKey: string) =>
  `${DATAOWNER_V2_PROPERTIES(dataownerKey)}/${propertyKey}`;
export const DATAOWNER_V2_SEARCH = `${DATAOWNER}/search`;

//Location
export const LOCATION_V2 = `${apiPathsV2.location}`;
export const LOCATION_V2_KEY = (locationKey: string) => `${LOCATION_V2}/${locationKey}`;
export const LOCATION_V2_APPS = (locationKey: string) =>
  `${LOCATION_V2_KEY(locationKey)}/apps/integration`;
export const LOCATION_V2_PROPERTIES = (locationKey: string) =>
  `${LOCATION_V2_KEY(locationKey)}/properties`;
export const LOCATION_V2_PROPERTY_KEY = (locationKey: string, propertyKey: string) =>
  `${LOCATION_V2_PROPERTIES(locationKey)}/${propertyKey}`;
export const LOCATION_V2_SEARCH = `${LOCATION_V2}/search`;
export const LOCATION_V2_EMPLOYEES = (locationKey: string) =>
  `${LOCATION_V2_KEY(locationKey)}/employees`;
export const LOCATION_V2_BULK_EMPLOYEE = (locationKey: string) =>
  `${LOCATION_V2_KEY(locationKey)}/bulk/employees`;
export const LOCATION_PINMETO = (locationKey: string) => `${LOCATION_V2_KEY(locationKey)}/pinmeto`;
export const LOCATION_PINMETO_SYNC = (locationKey: string) =>
  `${LOCATION_V2_KEY(locationKey)}/pinmeto/sync`;
export const LOCATION_PINMETO_MAPPING = (locationKey: string) =>
  `${LOCATION_V2_KEY(locationKey)}/pinmetomapping`;

// Employee - User
export const EMPLOYEE_ME = apiPathsV2.me;

// Employee Management
export const EMPLOYEE_V2 = apiPathsV2.employees;
export const EMPLOYEE_ID_V2 = (userId: string) => `${EMPLOYEE_V2}/${userId}`;
export const SEARCH_EMPLOYEE = `${apiPathsV2.employees}/search`;
export const EMPLOYEE_ROLES = (userId: string) => `${EMPLOYEE_ID_V2(userId)}/roles`;
export const EMPLOYEE_APPS = (userId: string) => `${EMPLOYEE_ID_V2(userId)}/apps`;
export const ENABLE_EMPLOYEE = (userId: string) => `${EMPLOYEE_ID_V2(userId)}/enable`;
export const DISABLE_EMPLOYEE = (userId: string) => `${EMPLOYEE_ID_V2(userId)}/disable`;
export const EMPLOYEE_LOGS = (userId: string) => `${EMPLOYEE_ID_V2(userId)}/logs`;
export const EMPLOYEE_REFRESH = (userId: string) => `${EMPLOYEE_ID_V2(userId)}/refresh`;
export const EMPLOYEE_SEND_INITIAL_PASSWORD_EMAIL = (userId: string) =>
  `${EMPLOYEE_ID_V2(userId)}/email/initialPasswordEmail`;
export const EMPLOYEE_V2_REMOVE_CONNECTIONS = (userId: string) =>
  `${EMPLOYEE_ID_V2(userId)}/removeconnections`;

// Property
const PROPERTY = apiPathsV2.properties;
export const PROPERTY_VALUE_TYPES = () => `${PROPERTY}/value-types`;
