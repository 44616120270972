import { ArticleType, KeyInfoType } from '@dap-sanity/types';
import { groqQuery } from '@shared/utils';

interface ServicePreviewsQueryProps {
  brandKey: string;
}

export const getServicePreviewsQuery = ({ brandKey }: ServicePreviewsQueryProps) =>
  groqQuery({
    filters: [
      `"${brandKey}" in brand`,
      `_type == "${ArticleType.service}"`,
      `(!defined(publishAt) || dateTime(publishAt) <= dateTime(now()))`,
    ],
    ordering: [{ fieldname: '_createdAt', order: 'desc' }],
    projections: [
      '"id": _id',
      'title',
      '"url": url.current',
      'intro',
      `"categories": simpleCategoryReferences[] -> {
            "id": _id,
            title,
            module
        }`,
      'tags',
      'image',
    ],
  });

interface ServiceArticleQueryProps {
  brandKey: string;
  slug: string;
}

export const getServiceArticleQuery = ({ slug, brandKey }: ServiceArticleQueryProps) =>
  groqQuery({
    filters: ['_type == "serviceArticle"', `"${brandKey}" in brand`, `url.current == "${slug}"`],
    projections: [
      '"id": _id',
      'title',
      '"url": url.current',
      'intro',
      `"body": textBlock[]`,
      'tags',
      `attachments[]{
        label,
        "url": asset->url
      }`,
      `"categories": simpleCategoryReferences[]->{title}`,
      `"keyInfo": {
        "email": keyInfo.fields[_type match "${KeyInfoType.email}"],
        "link": keyInfo.fields[_type match "${KeyInfoType.link}"],
        "text": keyInfo.fields[_type match "${KeyInfoType.text}"],
      }`,
    ],
    slice: { start: 0, end: 0 },
  });
