import { BrandadminTFunction, BrandTFunction, useBrandadminTFunction } from '@dap-admin/i18n';
import {
  BrandProperty,
  BrandPropertyInheritType,
  InheritableProperty,
  PropertyDetail,
  PropertyValueTypes,
} from '@dap-admin/types';
import { createFilterOptions, FilterOptionsState } from '@mui/material';

export interface KeyGroupOptionType {
  inputValue?: string;
  keyGroup: string;
}

export const keyGroupFilterOptions = createFilterOptions<KeyGroupOptionType>();

export const getKeyGroupOptions = (
  properties: Array<PropertyDetail>
): readonly KeyGroupOptionType[] => {
  return properties
    .map(({ keyGroup }) => keyGroup || '')
    .filter((keyGroup) => !!keyGroup || keyGroup !== null)
    .filter((keyGroup, index, array) => array.indexOf(keyGroup) === index)
    .map((keyGroup) => ({ keyGroup }));
};

export const getValueTypeLabel = (valueType: string, t: BrandadminTFunction) => {
  switch (valueType) {
    case PropertyValueTypes.string:
      return t('brandadmin.properties.types.string');
    case PropertyValueTypes.boolean:
      return t('brandadmin.properties.types.boolean');
    case PropertyValueTypes.number:
      return t('brandadmin.properties.types.number');
    case PropertyValueTypes.date:
      return t('brandadmin.properties.types.date');
    case PropertyValueTypes.enum:
      return t('brandadmin.properties.types.enum');
    case PropertyValueTypes.multipleChoice:
      return t('brandadmin.properties.types.multipleChoice');
    default:
      return '';
  }
};

export const getInheritTypeLabel = (
  inheritType: BrandPropertyInheritType,
  t: BrandadminTFunction,
  tBrand: BrandTFunction
) => {
  switch (inheritType) {
    case BrandPropertyInheritType.BRAND_ONLY:
      return t('brandadmin.properties.create.inheritable.brandOnly');
    case BrandPropertyInheritType.DATAOWNERS:
      return t('brandadmin.properties.create.inheritable.allDataowners', {
        dataowners: tBrand('brand.dataowner.name.plural'),
      });
    case BrandPropertyInheritType.LOCATIONS:
      return t('brandadmin.properties.create.inheritable.allLocations', {
        locations: tBrand('brand.location.name.plural'),
      });
    case BrandPropertyInheritType.DATAOWNERS_AND_LOCATIONS:
      return t('brandadmin.properties.create.inheritable.allDataownersAndLocations', {
        dataowners: tBrand('brand.dataowner.name.plural'),
        locations: tBrand('brand.location.name.plural'),
      });
    default:
      return '';
  }
};

export const getInheritType = (
  property: BrandProperty,
  t: BrandadminTFunction,
  tBrand: BrandTFunction
) => {
  switch (true) {
    case !property.dataownerInheritable && !property.locationInheritable:
      return getInheritTypeLabel(BrandPropertyInheritType.BRAND_ONLY, t, tBrand);

    case property.dataownerInheritable && property.locationInheritable:
      return getInheritTypeLabel(BrandPropertyInheritType.DATAOWNERS_AND_LOCATIONS, t, tBrand);

    case property.dataownerInheritable:
      return getInheritTypeLabel(BrandPropertyInheritType.DATAOWNERS, t, tBrand);

    case property.locationInheritable:
      return getInheritTypeLabel(BrandPropertyInheritType.LOCATIONS, t, tBrand);
    default:
      return '';
  }
};

export const getInheritableType = (property: BrandProperty | InheritableProperty) => {
  switch (true) {
    case !property.dataownerInheritable && !property.locationInheritable:
      return BrandPropertyInheritType.BRAND_ONLY;

    case property.dataownerInheritable && property.locationInheritable:
      return BrandPropertyInheritType.DATAOWNERS_AND_LOCATIONS;

    case property.dataownerInheritable:
      return BrandPropertyInheritType.DATAOWNERS;

    case property.locationInheritable:
      return BrandPropertyInheritType.LOCATIONS;
  }
  return '';
};

export const useTransformValueTypesToReadableLabel = (valueTypes: Array<string> | undefined) => {
  const t = useBrandadminTFunction();

  if (!valueTypes) return [];

  return valueTypes.map((valueType) => ({
    name: getValueTypeLabel(valueType, t),
    value: valueType,
  }));
};

export const getOptionLabel = (option: string | KeyGroupOptionType | null) => {
  if (option === null) return '';
  if (typeof option === 'string') return option;
  if (option.inputValue) return option.inputValue;
  return option.keyGroup;
};

export const getFilterOptions = (
  options: KeyGroupOptionType[],
  params: FilterOptionsState<KeyGroupOptionType>,
  addText: string
) => {
  const filtered = keyGroupFilterOptions(options, params);
  const { inputValue } = params;
  const isExisting = options.some((option) => inputValue === option.keyGroup);
  if (inputValue !== '' && !isExisting) {
    filtered.push({
      inputValue,
      keyGroup: `${addText} "${inputValue}"`,
    });
  }
  return filtered;
};
