import { useMemo } from 'react';
import { Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { timeAgo } from '@dap-sanity/utils';
import { useTranslation } from 'react-i18next';
import { messages } from '@dap-common/i18n';

export type LatestArticleItem = {
  title: string;
  intro: string;
  url: string;
  publishedAt: string;
};

export const LatestArticleItem = ({ title, intro, url, publishedAt }: LatestArticleItem) => {
  const { t } = useTranslation('newsArticles');

  const publishedString = useMemo(() => {
    const ago = timeAgo(publishedAt);
    return t(messages.newsArticles.updatedAgo, {
      amount: ago.value,
      timeUnit: t(messages.newsArticles.timeUnits[ago.unit]),
    });
  }, [publishedAt, t]);

  return (
    <Link
      to={url}
      component={RouterLink}
      sx={{
        flexDirection: 'column',
        justifyContent: 'flex-start',
        paddingBlock: 1.5,
        paddingInline: 3,
        width: 'calc(30cqw)',
        minWidth: '280px',
        maxWidth: '320px',
        textDecoration: 'none',
        color: 'inherit',
        borderRight: ({ palette }) => `1px solid ${palette.grey[100]}`,
        '&:hover, &:focus-visible': {
          textDecoration: 'underline',
        },
      }}
    >
      <Typography
        variant="body1"
        fontWeight={600}
        sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        color="grey.A400"
        sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
      >
        <span>{publishedString}</span> {intro}
      </Typography>
    </Link>
  );
};
