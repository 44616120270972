import themeV2 from '../dap';
import { Theme, createTheme } from '@mui/material';
import { componentsStyles } from './componentsStyles';
import { midnightBlue, neutral, primaryText, secondaryText } from './colors';
import { TypographyOptions } from '@mui/material/styles/createTypography';

const intranetTheme: Theme = createTheme({
  ...themeV2,
  palette: {
    ...themeV2.palette,
    // For debug purposes, these brand colors can be used, but it should not be defined in deployed code, as the values will be set IF a brand has defined brand colors
    // brand: {
    //   main: 'deeppink',
    //   contrastText: 'lime',
    // },
    primary: {
      ...themeV2.palette.primary,
      ...midnightBlue,
    },
    grey: {
      ...themeV2.palette.grey,
      ...neutral,
    },
    text: {
      ...themeV2.palette.text,
      primary: primaryText,
      secondary: secondaryText,
    },
    gradient: {
      ...themeV2.palette.gradient,
      // primary: `linear-gradient(282.61deg, ${midnightBlue[900]} 0.09%, ${midnightBlue[800]} 32.38%, ${midnightBlue[700]} 62.89%, ${midnightBlue[600]} 98.77%)`,
    },
  },

  typography: {
    // Reduce font-weight for all headings to 600
    ...Object.entries(themeV2.typography).reduce((curr, [key, value]) => {
      if (![`h1`, `h2`, `h3`, `h4`, `h5`].includes(key)) {
        // Return the same value for non-heading keys
        return { ...curr, [key]: value };
      }
      return { ...curr, [key]: { ...value, fontWeight: 600 } };
    }, {} as TypographyOptions),
  },
  components: {
    ...themeV2.components,
    ...componentsStyles,
  },
});

export default intranetTheme;
