import { Typography, Box, SxProps, Theme } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { messages } from '@dap-common/i18n';

interface Props {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

export default function MainContent({ children, sx }: Props) {
  const { t } = useTranslation('common');

  return (
    <ErrorBoundary
      fallback={<Typography>{t(messages.common.error.generic)}</Typography>}
      beforeCapture={(scope) => {
        scope.setTag('component', 'main-content');
      }}
    >
      <Box
        component="main"
        sx={{
          padding: 3,
          ...sx,
        }}
      >
        {children}
      </Box>
    </ErrorBoundary>
  );
}
