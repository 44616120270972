import { messages } from '@dap-common/i18n';
import { useTranslation } from 'react-i18next';
import { LoadingErrorComponent } from '../Wrappers';
import { isCustomError } from '@dap-common/utils';
import ErrorTemplate from './ErrorTemplate';

const ErrorDefaultComponent: LoadingErrorComponent<any> = ({ reload, error }) => {
  const { t } = useTranslation();

  return (
    <ErrorTemplate
      title={t(messages.common.error.oops)}
      subtitle={t(messages.common.error.fetch)}
      actionButtonLabel={t(messages.common.error.tryReloading)}
      onActionButtonClick={reload}
      errorMessage={
        error ? `${error.status} ${isCustomError(error) ? error.data.message : ''}` : undefined
      }
    />
  );
};

export default ErrorDefaultComponent;
