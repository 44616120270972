import { CardMedia, SxProps } from '@mui/material';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { useSanityContextActions } from '@dap-sanity/utils';

interface Props {
  height: number;
  image: SanityImageSource;
  imageWidth?: string;
  noBorderRadius?: boolean;
  sx?: SxProps;
}

export default function PreviewSanityImage({
  height,
  image,
  imageWidth,
  noBorderRadius,
  sx,
}: Props) {
  const { getImageUrlBuilder } = useSanityContextActions();

  const imageUrl = getImageUrlBuilder(image)
    .height(height * 2)
    .quality(50)
    .url();

  return (
    <CardMedia
      component="img"
      height={height}
      image={imageUrl}
      sx={{
        display: 'flex',
        alignItems: 'center',
        objectFit: 'cover',
        objectPosition: 'center',
        width: imageWidth ? imageWidth : '100%',
        borderRadius: noBorderRadius ? 0 : 1,
        ...sx,
      }}
    />
  );
}
