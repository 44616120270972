import { Dataowner } from '@dap-admin/types';
import { useOutletContext } from 'react-router-dom';

export interface DataownerOutletState {
  selectedDataowner: Dataowner;
}

function useDataownerOutletContext() {
  const dataownerOutletState = useOutletContext<DataownerOutletState | undefined>();

  if (!dataownerOutletState) {
    throw new Error(
      `useDataownerOutletContext is ${dataownerOutletState}. Make sure to use this context in a direct Route child to a Route exposing an Outlet with this context.`
    );
  }

  return dataownerOutletState;
}

export default useDataownerOutletContext;
