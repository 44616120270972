export const omitPropertyFromObject = <T extends object>(
  obj: T,
  valueToOmit: string[],
  replacementValue: any = undefined
): T => {
  return JSON.parse(JSON.stringify(obj), (key, value) => {
    if (valueToOmit.some((k) => k === key)) {
      return replacementValue;
    }
    return value;
  });
};
