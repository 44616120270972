import groq from 'groq';

interface GroqQueryProps {
  filters: (string | undefined)[];
  traversal?: string;
  ordering?: {
    fieldname: string;
    order: 'asc' | 'desc';
  }[];
  orderAfterProjections?: boolean;
  projections?: string[];
  slice?: {
    start: number;
    end?: number;
  };
}

export const groqQuery = ({
  filters,
  traversal,
  ordering,
  projections,
  slice,
  orderAfterProjections = false,
}: GroqQueryProps) => {
  const filterString = filters
    .filter((f) => !!f)
    .map((f) => f?.trim())
    .join(' && ');
  const traversalString = traversal ? `.${traversal}` : '';
  const orderString = ordering
    ? ' ' + ordering.map(({ fieldname, order }) => `| order(${fieldname} ${order})`).join(' ')
    : '';
  const projectionsString = projections ? ` {${projections.join(', ')}}` : '';
  const sliceString = slice ? `[${slice.start}${slice.end ? `...${slice.end}` : ''}]` : '';

  if (orderAfterProjections) {
    return groq`*[${filterString}]${traversalString}${projectionsString}${orderString}${sliceString}`;
  }

  return groq`*[${filterString}]${traversalString}${orderString}${projectionsString}${sliceString}`;
};
