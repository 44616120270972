import { Card } from '@mui/material';
import React, { PropsWithChildren } from 'react';

export const newsCardPaddingX = '20px';

function NewsCarouselCard({ children }: PropsWithChildren) {
  return (
    <Card
      sx={{
        padding: 3,
      }}
    >
      {children}
    </Card>
  );
}

export default NewsCarouselCard;
