import { byggtorgetProffUri } from '@assets/images';
import { AppFeatureConfig } from '@dap-dashboard/types';
import { defaultBrandFeatures } from './defaultBrandFeatures';

const byggtorgetFeaturesDefault: AppFeatureConfig = {
  ...defaultBrandFeatures,
  workplace: { resolveToggle: () => 'on' },
  workplaceBanner: {
    resolveToggle: () => 'off',
    config: { url: 'https://byggtorget.workplace.com/' },
  },
  leads: { resolveToggle: () => 'off' },
  proffConcepts: {
    resolveToggle: ({ proff_module }) => (proff_module?.[0]?.show ? 'on' : 'off'),
    config: {
      proffIcon: byggtorgetProffUri,
    },
  },
  dashboardBanner: { resolveToggle: () => 'off' },
};

export const byggtorgetFeaturesDev: AppFeatureConfig = {
  ...byggtorgetFeaturesDefault,
};
export const byggtorgetFeaturesStage: AppFeatureConfig = {
  ...byggtorgetFeaturesDefault,
};
export const byggtorgetFeaturesProd: AppFeatureConfig = {
  ...byggtorgetFeaturesDefault,
  completeRegistrationEmailInfo: { resolveToggle: () => 'off' },
};
