import { CardActionArea, Stack, Typography, CircularProgress } from '@mui/material';
import { Autorenew } from '@mui/icons-material';
import { OmniumIcon } from '@assets/appLogos';

interface Props {
  href: string;
  value: number;
  title: string;
}

export default function ToolCard({ href, value, title }: Props) {
  return (
    <SmallView href={href} title={title}>
      <span>{value}</span>
    </SmallView>
  );
}

export const LoadingToolCard = ({ title }: { title: string }) => {
  return (
    <SmallView title={title}>
      <CircularProgress color="primary" size={16} sx={{ position: 'relative', top: '0.125rem' }} />
    </SmallView>
  );
};

export const ErrorToolCard = ({ reload, title }: { reload?: () => void; title: string }) => {
  return (
    <SmallView title={title} onClick={reload}>
      <Autorenew
        fontSize="inherit"
        sx={{
          position: 'relative',
          top: '0.125rem',
          transform: 'scale(1.2)',
        }}
      />
    </SmallView>
  );
};

const SmallView = ({
  title,
  href,
  children,
  onClick,
}: React.PropsWithChildren<{ title: string; href?: string; onClick?: () => void }>) => {
  return (
    <CardActionArea
      disabled={!href && !onClick}
      href={href ?? ''}
      target={'_blank'}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        sx={{
          background: ({ palette }) => palette.grey[50],
          padding: 2,
          borderRadius: 1,
        }}
      >
        <Stack direction="row" alignItems="center">
          <Typography variant="body1" component="span">
            {title}:
          </Typography>
          <Typography variant="body1" component="span" sx={{ fontWeight: 700, marginLeft: 1 }}>
            {children}
          </Typography>
        </Stack>
        <OmniumIcon width="28px" />
      </Stack>
    </CardActionArea>
  );
};
