import { Stack, Box, Typography, Card, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { messages } from '@dap-common/i18n';
import { Autorenew } from '@mui/icons-material';

type Props<ErrorT> = React.ComponentType<{
  error?: ErrorT;
  reload?: () => void;
  title?: string;
}>;

export const ErrorBlockComponent: Props<any> = ({ reload, error, title }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Box>
          {title && (
            <Typography variant="h4" component="h2">
              {title}
            </Typography>
          )}
          <Typography variant="body1">{t(messages.common.error.fetch)}</Typography>
        </Box>
        {reload && (
          <Stack direction="row" alignItems="center">
            <Button onClick={reload} variant="outlined">
              <Typography variant="body1" marginRight={1}>
                {t(messages.common.error.tryReloading)}
              </Typography>
              <Autorenew fontSize="medium" />
            </Button>
          </Stack>
        )}
      </Stack>
    </Card>
  );
};
