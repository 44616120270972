import {
  DATAOWNER,
  DATAOWNER_EMPLOYEES,
  DATAOWNER_KEY,
  DATAOWNER_V2_PROPERTIES,
  DATAOWNER_V2_PROPERTY_KEY,
  DATAOWNER_V2_SEARCH,
} from '@dap-admin/config';
import {
  CreateDataownerDTO,
  DataownerSearchArgs,
  DataownerSearchResult,
  Dataowner,
  EmployeeBasicV2,
  PatchDataownerDTO,
  EditProperty,
  CreateProperty,
  PropertyDetail,
} from '@dap-admin/types';
import {
  dataownerDetailMapper,
  dataownerSearchArgsMapper,
  dataownerSearchResultMapper,
  employeeOverviewMapper,
  propertyMapper,
} from '@dap-common/utils';
import {
  DataownerDetailDTO,
  DataownerSearchDTO,
  EmployeeOverviewDTO,
  PropertyDetailDTO,
} from '@generated-types';
import { selectBrandKey } from '../redux/brand/brandReducer';
import { RootState } from '../redux/store';
import { BrandTags, LocationTags } from './tags';
import { brandadminApi } from './brandadminApi';
import { DataownerTags, EmployeeTags } from './tags';
import { AddEmployeesToDataownerRequest } from '../redux/types';
import { sortAdminsFirst, sortDeactivatedLast } from '@dap-admin/utils';

const tags = [
  ...Object.values(DataownerTags),
  BrandTags.BRAND_DATAOWNERS,
  EmployeeTags.EMPLOYEE,
  LocationTags.LOCATION,
];

export const dataownerApi = brandadminApi.enhanceEndpoints({ addTagTypes: tags }).injectEndpoints({
  endpoints: (build) => ({
    createDataowner: build.mutation<Dataowner, CreateDataownerDTO>({
      query: (body) => ({
        url: DATAOWNER,
        method: 'POST',
        body,
      }),
      transformResponse: dataownerDetailMapper,
      invalidatesTags: [DataownerTags.SEARCH_DATAOWNERS, BrandTags.BRAND_DATAOWNERS],
    }),
    getDataowner: build.query<Dataowner, Dataowner['key']>({
      query: (dataownerKey) => ({
        url: DATAOWNER_KEY(dataownerKey),
      }),
      async onQueryStarted(_key, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const state = getState() as RootState;
          // if the user has access to location but is on the wrong brand, select correct brand.
          if (state.brand.selectedBrandKey !== data.brand.key) {
            dispatch(selectBrandKey(data.brand?.key || ''));
          }
        } catch (error) {
          /* empty */
        }
      },
      transformResponse: (response: DataownerDetailDTO) => dataownerDetailMapper(response),
      providesTags: (result, _error, arg) => [
        { type: DataownerTags.DATAOWNER, id: arg },
        ...(result?.properties
          ? result.properties
              .filter((property) => property.inherited)
              .map(({ key }) => ({
                type: BrandTags.BRAND_INHERITABLE_PROPERTIES,
                id: key,
              }))
          : []),
      ],
    }),
    deleteDataowner: build.mutation<void, Dataowner['key']>({
      query: (dataownerKey) => ({
        url: DATAOWNER_KEY(dataownerKey),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [
        DataownerTags.SEARCH_DATAOWNERS,
        { type: DataownerTags.DATAOWNER, id: arg },
        BrandTags.BRAND_DATAOWNERS,
      ],
    }),
    editDataownerDetails: build.mutation<
      Dataowner,
      { dataownerKey: Dataowner['key']; payload: Partial<PatchDataownerDTO> }
    >({
      query: ({ dataownerKey, payload }) => ({
        url: DATAOWNER_KEY(dataownerKey),
        method: 'PATCH',
        body: payload,
        transformResponse: dataownerDetailMapper,
      }),
      invalidatesTags: (result, _error, arg) =>
        result
          ? [
              { type: DataownerTags.DATAOWNER, id: arg.dataownerKey },
              DataownerTags.SEARCH_DATAOWNERS,
              BrandTags.BRAND_DATAOWNERS,
              ...(result.locations
                ? result.locations.map(({ key }) => ({ type: LocationTags.LOCATION, id: key }))
                : []),
            ]
          : [],
    }),
    createDataownerProperty: build.mutation<
      PropertyDetail,
      { dataownerKey: string; property: CreateProperty }
    >({
      query: ({ dataownerKey, property }) => ({
        url: DATAOWNER_V2_PROPERTIES(dataownerKey),
        method: 'POST',
        body: property,
      }),
      transformResponse: (response: PropertyDetailDTO) => propertyMapper(response),
      invalidatesTags: (_result, _error, arg) => [
        { type: DataownerTags.DATAOWNER, id: arg.dataownerKey },
      ],
    }),
    updateDataownerProperty: build.mutation<
      PropertyDetail,
      { dataownerKey: string; propertyKey: string; property: EditProperty }
    >({
      query: ({ dataownerKey, propertyKey, property }) => ({
        url: DATAOWNER_V2_PROPERTY_KEY(dataownerKey, propertyKey),
        method: 'PATCH',
        body: property,
      }),
      transformResponse: (response: PropertyDetailDTO) => propertyMapper(response),
      invalidatesTags: (_result, _error, arg) => [
        { type: DataownerTags.DATAOWNER, id: arg.dataownerKey },
      ],
    }),
    deleteDataownerProperty: build.mutation<void, { dataownerKey: string; propertyKey: string }>({
      query: ({ dataownerKey, propertyKey }) => ({
        url: DATAOWNER_V2_PROPERTY_KEY(dataownerKey, propertyKey),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: DataownerTags.DATAOWNER, id: arg.dataownerKey },
      ],
    }),
    searchDataowners: build.query<Array<DataownerSearchResult>, DataownerSearchArgs>({
      query: (props) => ({
        url: DATAOWNER_V2_SEARCH,
        method: 'POST',
        body: dataownerSearchArgsMapper(props),
      }),
      providesTags: [DataownerTags.SEARCH_DATAOWNERS],
      transformResponse: (response: Array<DataownerSearchDTO>) =>
        response.map(dataownerSearchResultMapper),
    }),
    getDataownerEmployees: build.query<Array<EmployeeBasicV2>, Dataowner['key']>({
      query: (dataownerKey) => ({
        url: `${DATAOWNER_EMPLOYEES(dataownerKey)}`,
      }),
      transformResponse: (response: EmployeeOverviewDTO, _meta, args) => {
        const mappedResponse = employeeOverviewMapper(response);
        const mergedEmployees = [
          ...mappedResponse.activeEmployees,
          ...mappedResponse.inactiveEmployees,
        ];
        return sortDeactivatedLast(sortAdminsFirst(mergedEmployees));
      },
      providesTags: (result, _error, dataownerKey) =>
        result
          ? [
              ...result.map(({ userId }) => ({ type: EmployeeTags.EMPLOYEE, id: userId })),
              { type: DataownerTags.EMPLOYEES, id: dataownerKey },
              { type: DataownerTags.DATAOWNER, id: dataownerKey },
            ]
          : [{ type: DataownerTags.EMPLOYEES, id: dataownerKey }],
    }),
    addDataownerEmployee: build.mutation<EmployeeBasicV2[], AddEmployeesToDataownerRequest>({
      query: ({ dataownerKey, ...rest }) => ({
        url: DATAOWNER_EMPLOYEES(dataownerKey),
        method: 'POST',
        body: [rest], // TODO: Use addEmployeeMapper after MID-901
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: DataownerTags.EMPLOYEES, id: arg.dataownerKey },
        { type: DataownerTags.SEARCH_DATAOWNERS },
        { type: EmployeeTags.SEARCH_EMPLOYEES },
        { type: EmployeeTags.EMPLOYEE, id: arg.userId },
      ],
    }),
    removeDataownerEmployee: build.mutation<
      void,
      { dataownerKey: string; userId: EmployeeBasicV2['userId'] }
    >({
      query: ({ dataownerKey, userId }) => ({
        url: `${DATAOWNER_EMPLOYEES(dataownerKey)}/${userId}`,
        method: 'DELETE',
      }),
      // is invalidated directly when successful in RemoveDataownerEmployee
      invalidatesTags: [],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateDataownerMutation,
  useGetDataownerQuery,
  useDeleteDataownerMutation,
  useEditDataownerDetailsMutation,
  useCreateDataownerPropertyMutation,
  useUpdateDataownerPropertyMutation,
  useDeleteDataownerPropertyMutation,
  useSearchDataownersQuery,
  useGetDataownerEmployeesQuery,
  useAddDataownerEmployeeMutation,
  useRemoveDataownerEmployeeMutation,
} = dataownerApi;
