import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import { Link, useTheme } from '@mui/material';

interface Props {
  href: string;
}

const ExternalLink: FunctionComponent<PropsWithChildren<Props>> = ({ href, children }) => {
  const { palette } = useTheme();
  const absoluteUrl = useMemo(() => {
    if (href.startsWith('http://') || href.startsWith('https://')) {
      return href;
    }
    return `//${href}`;
  }, [href]);

  return (
    <Link href={absoluteUrl} rel="noopener noreferrer" target="_blank" color={palette.primary.main}>
      {children || href}
    </Link>
  );
};
export default ExternalLink;
