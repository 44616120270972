import { Container, ContainerProps } from '@mui/material';
import React from 'react';

export default function MaxWidthWrapper<C extends React.ElementType>({
  children,
  ...rest
}: Omit<ContainerProps<C, { component?: C }>, 'maxWidth'>) {
  return (
    <Container maxWidth="lg" {...rest}>
      {children}
    </Container>
  );
}
