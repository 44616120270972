import { CircularProgress } from '@mui/material';
import { CenterElement } from '../Layout';

function LoadingDefaultComponent() {
  return (
    <CenterElement>
      <CircularProgress color="primary" />
    </CenterElement>
  );
}

export default LoadingDefaultComponent;
