import { IconButton as MuiIconButton, styled, SxProps } from '@mui/material';
import { FlexRow } from '@shared/components';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

interface Props {
  disableNext: boolean;
  disablePrevious: boolean;
  handleNext: () => void;
  handlePrevious: () => void;
  hideWhenDisabled?: boolean;
}

export function PaginationArrows({
  disableNext,
  disablePrevious,
  handleNext,
  handlePrevious,
  hideWhenDisabled,
}: Props) {
  return (
    <FlexRow gap={1}>
      <SquareIconButton
        disabled={disablePrevious}
        onClick={handlePrevious}
        hideWhenDisabled={hideWhenDisabled}
      >
        <ArrowBack fontSize="small" />
      </SquareIconButton>
      <SquareIconButton
        disabled={disableNext}
        onClick={handleNext}
        hideWhenDisabled={hideWhenDisabled}
      >
        <ArrowForward fontSize="small" />
      </SquareIconButton>
    </FlexRow>
  );
}

const SquareIconButton = styled(MuiIconButton, {
  shouldForwardProp: (prop) => prop !== 'hideWhenDisabled',
})<{ hideWhenDisabled?: boolean }>(({ theme, hideWhenDisabled }) => ({
  padding: theme.spacing(1),
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: theme.palette.grey[900],
  '&:hover': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[700],
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[200],
    opacity: hideWhenDisabled ? 0 : 1,
  },
}));
