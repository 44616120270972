import { Stack, Card } from '@mui/material';
import { CardsCarousel } from '../../components';
import { ContactCard, ContactCardProps } from '@dap-common/ui';

interface Props {
  title: string;
  people: ContactCardProps[];
}

export function PersonList({ title, people }: Props) {
  const cards = people.map((person) => (
    <Stack
      sx={{
        width: 'calc(25cqw - 12px)',
        minWidth: '300px',
      }}
    >
      <ContactCard key={person.email} {...person} />
    </Stack>
  ));

  return (
    <Card>
      <CardsCarousel title={title} cards={cards} />
    </Card>
  );
}
