import { ModuleType, ArticleType, InternalLink } from '@dap-sanity/types';
import { getAllNewsRoute, getAllEventsRoute, getModuleCategoryRoute } from '@dap-common/consts';

// This function takes an internalLink object returns a proper URL to the content, or null if the link cannot be resolved.
export const getUrlToInternalContent = (link: InternalLink): string | null => {
  if (!link) {
    return null;
  }

  switch (true) {
    case link._type === ArticleType.standard && link?.module === ModuleType.newsModule:
      return `${getAllNewsRoute()}/${link.url}`;

    case link._type === ArticleType.event:
      return `${getAllEventsRoute()}/${link.url}`;

    case link._type === ArticleType.module && !!link.moduleRef?.slug && !!link.category?.slug:
      return `${getModuleCategoryRoute({
        moduleSlug: link.moduleRef.slug,
        categorySlug: link.category.slug,
      })}/${link.url}`;

    default:
      return null;
  }
};
