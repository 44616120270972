import groq from 'groq';
import { BrandConfigurationRequest, ModuleType, DocumentType, ObjectType } from '@dap-sanity/types';
import { groqQuery } from '@shared/utils';
import { contentBlocksQuery } from './contentBlockQueries';
import {
  eventsModuleQuery,
  fremdriftModuleQuery,
  helpCenterModuleQuery,
  mosaicModuleQuery,
  newsModuleQuery,
  orderModuleQuery,
  proffModuleQuery,
  servicesModuleQuery,
} from './moduleQueries';
import { customLinkQuery } from './fieldQueries';

export const getBrandConfiguration = ({ brandKey }: BrandConfigurationRequest) =>
  groqQuery({
    filters: [`_type=="${DocumentType.BrandConfiguration}" && brand=="${brandKey}"`],
    projections: [
      `'${ModuleType.newsModule}':modules[_type match "${ModuleType.newsModule}"][0]{
        ${newsModuleQuery}
      }`,
      `'${ModuleType.eventsModule}':modules[_type match "${ModuleType.eventsModule}"][0]{
        ${eventsModuleQuery}
      }`,
      `'${ModuleType.servicesModule}':modules[_type match "${ModuleType.servicesModule}"][0]{
        ${servicesModuleQuery}
      }`,
      `'${ModuleType.proffModule}':modules[_type match "${ModuleType.proffModule}"][]->{
        ${proffModuleQuery}
      }`,
      `'${ModuleType.fremdriftModule}':*[_type match "${ModuleType.fremdriftModule}" &&  (_id in ^.modules[]._ref) && count((categories[]->brand)["${brandKey}" in @]) > 0]{
        ${fremdriftModuleQuery}
      }`,
      `'${ModuleType.orderModule}':*[_type == "${ModuleType.orderModule}" && "${brandKey}" in brand][]{
        ${orderModuleQuery}
      }`,
      `'${ModuleType.mosaicModule}':modules[_type match "${ModuleType.mosaicModule}"][]->{
        ${mosaicModuleQuery}
      }`,
      `'${ModuleType.helpCentreModule}':modules[_type match "${ModuleType.helpCentreModule}"][0]{
        ${helpCenterModuleQuery}
      }`,
    ],
    slice: { start: 0 },
  });

export const navigationItemQuery = groq`
  label,
  icon,
  _key,
  link[0]{
    ${customLinkQuery}
  }
`;

export const getBrandNavigationQuery = ({ brandKey }: BrandConfigurationRequest) => {
  const query = groqQuery({
    filters: [`_type=="${DocumentType.BrandNavigation}" && brand=="${brandKey}"`],
    projections: [
      `
      items[]{
        _type,
        _key,
        _type == "${ObjectType.NavigationItem}" => {
          ${navigationItemQuery}
        },
        _type == "${ObjectType.NavigationSubMenu}" => {
          label,
          items[]{
            _type,
            _key,
            ${navigationItemQuery}
          }
        }
      }
      `,
    ],
    slice: { start: 0 },
  });

  return query;
};

export const getBrandDashboardQuery = ({ brandKey }: BrandConfigurationRequest) => {
  const query = groqQuery({
    filters: [`_type=="${DocumentType.Dashboard}" && _id=="${brandKey}-dashboard"`],
    projections: [
      `
      showLatestArticles,
      mainContent[]{
        ${contentBlocksQuery}
      },
      secondaryContent[]{
        ${contentBlocksQuery}
      }
      `,
    ],
    slice: { start: 0 },
  });

  return query;
};
