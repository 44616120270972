import { ExternalLink } from './externalLink';
import { OrderModuleConfigBase } from './../order/orderTypes';
import { InternalLink } from './internalLink';

export enum CustomLinkType {
  ReferencedModule = 'referenced_module',
  StaticModule = 'static_module',
  RelativeLink = 'relative_link',
  OrderModule = 'order_module',
  TopicPage = 'topic_page',
  ExternalLink = 'external_link',
  Content = 'content',
}

export type CustomLinkField =
  | CustomLinkReferencedModule
  | CustomLinkStaticModule
  | CustomLinkOrderModule
  | CustomLinkRelativeLink
  | CustomLinkTopicPage
  | CustomLinkExternalLink
  | CustomLinkArticle;

export interface CustomLinkBase {
  _type: CustomLinkType;
}

export interface CustomLinkReferencedModule extends CustomLinkBase {
  _type: CustomLinkType.ReferencedModule;
  _ref: string;
  referencedModule: {
    _type: string;
    _id: string;
    show: boolean;
    slug: string;
    header: string;
  };
}

export interface CustomLinkStaticModule extends CustomLinkBase {
  _type: CustomLinkType.StaticModule;
  module_type: string;
  staticModuleData?: {
    show: boolean;
    header: string;
  };
}

export interface CustomLinkOrderModule extends CustomLinkBase {
  _type: CustomLinkType.OrderModule;
  _ref: string;
  referencedModule: OrderModuleConfigBase;
}

export interface CustomLinkRelativeLink extends CustomLinkBase {
  _type: CustomLinkType.RelativeLink;
  link: string;
}

export interface CustomLinkTopicPage extends CustomLinkBase {
  _type: CustomLinkType.TopicPage;
  referencedTopic: {
    slug: string;
    title: string;
  };
}
export interface CustomLinkExternalLink extends ExternalLink {
  _type: CustomLinkType.ExternalLink;
}

export interface CustomLinkArticle extends CustomLinkBase {
  _type: CustomLinkType.Content;
  referencedArticle: InternalLink;
}
