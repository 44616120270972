import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

function ServiceCatalogIcon(props: SvgIconProps) {
  const { palette } = useTheme();
  return (
    <SvgIcon {...props} width="14" height="14" viewBox="0 0 14 14" fill="none">
      <g clipPath="url(#clip0_3522_8047)">
        <path
          d="M1.3125 0.875H5.40039C5.86523 0.875 6.3082 1.0593 6.63633 1.3877L11.2301 5.98008C11.9137 6.66367 11.9137 7.77383 11.2301 8.45742L7.58242 12.1051C6.89883 12.7887 5.78867 12.7887 5.10508 12.1051L0.512695 7.51133C0.184379 7.1832 0 6.74023 0 6.27539V2.1875C0 1.46262 0.587617 0.875 1.3125 0.875ZM3.0625 4.8125C3.54648 4.8125 3.9375 4.42148 3.9375 3.9375C3.9375 3.45352 3.54648 3.0625 3.0625 3.0625C2.57934 3.0625 2.1875 3.45352 2.1875 3.9375C2.1875 4.42148 2.57934 4.8125 3.0625 4.8125Z"
          fill={palette.primary.dark}
        />
        <path
          opacity="0.4"
          d="M8.50709 1.06454C8.76412 0.809694 9.17975 0.812155 9.40944 1.07001L12.9286 4.60473C14.3587 6.05395 14.3587 8.38364 12.9286 9.83286L9.86608 12.9309C9.61178 13.1879 9.19615 13.1907 8.93912 12.9364C8.67936 12.6821 8.67936 12.2665 8.93366 12.0067L11.9934 8.91137C12.9204 7.97348 12.9204 6.46411 11.9934 5.52622L8.50162 1.99258C8.24459 1.73473 8.24733 1.31938 8.50709 1.06454Z"
          fill={palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_3522_8047">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default ServiceCatalogIcon;
