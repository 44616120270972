import { useMemo } from 'react';
import { Link, Typography, useTheme, Tooltip } from '@mui/material';
import { LinkListItem, CustomLinkType, BlockLinkListPalette } from '@dap-sanity/types';
import { getUrlAndTitleFromCustomLink, getPaletteColors } from '@dap-sanity/utils';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  item: LinkListItem;
  palette: BlockLinkListPalette;
}

export function LinkCard({ item, palette }: Props) {
  const { palette: themePalette } = useTheme();

  const [href, linkTitle] = item.link
    ? getUrlAndTitleFromCustomLink(item.link)
    : [item.url, undefined];

  const { backgroundColor, textColor } = useMemo(
    () => getPaletteColors(themePalette, palette),
    [themePalette, palette]
  );

  // If we could not resolve a link return null to prevent the card from rendering
  if (!href) {
    return null;
  }

  const blank = item.link?._type === CustomLinkType.ExternalLink ? item.link.blank : false;
  const title = item.title ?? linkTitle ?? item.link._type;

  return (
    <Tooltip title={item?.subtitle ?? ''} placement="bottom" arrow>
      <Link
        to={href as string}
        component={RouterLink}
        target={blank ? '_blank' : ''}
        rel={blank ? 'noopener noreferrer' : ''}
        key={item.title}
        position="relative"
        color="primary"
        sx={{
          color: textColor,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          // Using container relative units to solve width that keeps 4 items in viewport
          width: 'calc(25cqw - 12px)',
          minWidth: '240px',
          maxHeight: '160px',
          gap: 2,
          paddingInline: 4,
          paddingBlock: 2,
          aspectRatio: 260 / 144,
          borderRadius: 1,
          // background: themePalette.grey[800],
          background: backgroundColor,
          textDecoration: 'none',

          '& svg': {
            transition: 'transform 250ms ease-in',
          },

          '&:hover svg': {
            transform: 'translateX(6px)',
            transition: 'transform 250ms ease-out',
          },
        }}
      >
        <Typography variant="h4" component="span">
          {title}
        </Typography>
        <ArrowForwardIcon />
      </Link>
    </Tooltip>
  );
}
