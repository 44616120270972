import { SanityBlockContent } from '@dap-sanity/types';
import { replaceBodyBrandNamePlaceholder, replacePlaceholderWithBrandName } from './sanityUtils';

export const replaceBrandPlaceholderInArticlePreview = <PreviewT extends { intro: string }>(
  { intro, ...rest }: PreviewT,
  brandName: string
) => ({
  intro: intro ? replacePlaceholderWithBrandName(intro, brandName) : '',
  ...rest,
});

export const replaceBrandPlaceholderInArticle = <
  ArticleT extends { body?: SanityBlockContent[]; intro: string }
>(
  { body, intro, ...rest }: ArticleT,
  brandName: string
) => ({
  intro: replacePlaceholderWithBrandName(intro, brandName),
  body: body ? replaceBodyBrandNamePlaceholder(body, brandName) : undefined,
  ...rest,
});
