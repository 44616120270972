import { MoreHoriz } from '@mui/icons-material';
import { IconButton, Menu, MenuProps, styled } from '@mui/material';
import { usePopupElement } from '@shared/hooks';
import React, { PropsWithChildren, useEffect } from 'react';

interface Props {
  popupButtonIcon?: React.JSX.Element;
  disableAutoFocusItem?: MenuProps['disableAutoFocusItem'];
  onMenuClose?: () => void;
  isOpen?: boolean;
}

export function MenuWithPopup({
  popupButtonIcon = <MoreHoriz />,
  disableAutoFocusItem = true,
  children,
  onMenuClose,
  isOpen = true,
}: PropsWithChildren<Props>) {
  const { menuElementProps, triggerElementProps } = usePopupElement();

  const handleClose = () => {
    if (onMenuClose) {
      onMenuClose();
    }
    menuElementProps.onClose();
  };

  useEffect(() => {
    if (!isOpen) {
      menuElementProps.onClose();
    }
  }, [isOpen, menuElementProps]);

  return (
    <>
      <IconButton {...triggerElementProps} size="small">
        {popupButtonIcon}
      </IconButton>
      <StyledMenu
        {...menuElementProps}
        disableAutoFocusItem={disableAutoFocusItem}
        onClose={handleClose}
      >
        {children}
      </StyledMenu>
    </>
  );
}

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiMenu-list': {
    '.MuiListSubheader-root': {
      ...theme.typography.h6,
      fontWeight: 700,
      color: theme.palette.primary.main,
      padding: theme.spacing(0, 1, 1),
    },
    '.MuiMenuItem-root': {
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
}));
