import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  token: string;
  isAuthenticated: boolean;
}

const authInitialState: AuthState = {
  token: '',
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    storeToken: (state, action: PayloadAction<string>) => {
      state.isAuthenticated = true;
      state.token = action.payload;
    },
    removeToken: (state) => {
      state.isAuthenticated = false;
      state.token = '';
    },
  },
});

export const { storeToken, removeToken } = authSlice.actions;
export default authSlice.reducer;
