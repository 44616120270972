import { AppFeatureConfig } from '@dap-dashboard/types';
import { defaultBrandFeatures } from './defaultBrandFeatures';

const systemhusFeaturesDefault: AppFeatureConfig = {
  ...defaultBrandFeatures,
  mgaBanner: {
    resolveToggle: ({ order_module }) => (order_module?.[0]?.show ? 'on' : 'off'),
  },
  workplace: { resolveToggle: () => 'on' },
  leads: {
    resolveToggle: ({ userHasOmniumLeadsAccess }) => (userHasOmniumLeadsAccess ? 'on' : 'off'),
  },
};

export const systemhusFeaturesDev: AppFeatureConfig = { ...systemhusFeaturesDefault };
export const systemhusFeaturesStage: AppFeatureConfig = { ...systemhusFeaturesDefault };
export const systemhusFeaturesProd: AppFeatureConfig = {
  ...systemhusFeaturesDefault,
  completeRegistrationEmailInfo: { resolveToggle: () => 'off' },
};
