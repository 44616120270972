import {
  BRANDS_V2,
  BRANDS_V2_DATAOWNERS,
  BRANDS_V2_EMPLOYEES,
  BRANDS_V2_EMPLOYEES_EMPLOYEE,
  BRANDS_V2_INHERITABLE_PROPERTIES,
  BRANDS_V2_INHERITABLE_PROPERTIES_KEY,
  BRANDS_V2_LOCATIONS,
  BRANDS_V2_LOCATION_FIELD_CONFIG,
  BRANDS_V2_LOCATION_PROPERTIES,
  BRANDS_V2_PROPERTIES,
  BRANDS_V2_PROPERTY_KEY,
  BRANDS_V2_PUBLISH_APP,
  BRANDS_V2_REGIONS,
  BRANDS_V2_REGIONS_KEY,
} from '@dap-admin/config';
import {
  BrandV2,
  CreateBrandDTO,
  CreateInheritableProperty,
  CreateProperty,
  DataownerBasic,
  EditInheritableProperty,
  EditProperty,
  EmployeeBasicV2,
  InheritableProperty,
  LocationOverview,
  PropertyDetail,
} from '@dap-admin/types';
import { sortAdminsFirst, sortDeactivatedLast } from '@dap-admin/utils';
import {
  brandDetailMapper,
  dataownersBasicMapper,
  employeeBasicMapper,
  employeeOverviewMapper,
  inheritablePropertiesMapper,
  inheritablePropertyMapper,
  locationOverviewMapper,
  propertyMapper,
} from '@dap-common/utils';
import {
  BrandDetailDTO,
  EmployeeBasicDTO,
  EmployeeOverviewDTO,
  InheritablePropertyDTO,
  LocationOverviewDTO,
  PropertyDetailDTO,
} from '@generated-types';
import {
  AddEmployeesToBrandRequest,
  CreateBrandLocationFieldConfigRequest,
  CreateBrandRegionRequest,
  DeleteBrandRegionRequest,
  UpdateBrandAction,
  UpdateBrandLocationFieldConfigRequest,
  UpdateBrandLocationProperties,
  UpdateBrandRegionRequest,
} from '../redux/types';
import { brandadminApi } from './brandadminApi';
import {
  BrandTags,
  DataownerTags,
  EmployeeTags,
  LocationTags,
  ServiceTags,
  UserTags,
} from './tags';

const tags = [
  ...Object.values(BrandTags),
  UserTags.USER,
  EmployeeTags.EMPLOYEE,
  ServiceTags.SERVICES,
  LocationTags.SEARCH_LOCATIONS,
  DataownerTags.SEARCH_DATAOWNERS,
];

export const brandApi = brandadminApi.enhanceEndpoints({ addTagTypes: tags }).injectEndpoints({
  endpoints: (build) => ({
    getBrand: build.query<BrandV2, BrandV2['key']>({
      query: (key) => ({ url: BRANDS_V2(key) }),
      transformResponse: (response: BrandDetailDTO) => brandDetailMapper(response),
      providesTags: (_result, _error, arg) => [{ type: BrandTags.SELECTED_BRAND, id: arg }],
    }),
    createBrand: build.mutation<BrandV2, CreateBrandDTO>({
      query: (payload) => ({ url: BRANDS_V2(), method: 'POST', body: payload }), // TODO: Should map payload to generated DTO CreateBrand
      transformResponse: (response: BrandDetailDTO) => brandDetailMapper(response),
      invalidatesTags: [UserTags.USER],
    }),
    updateBrand: build.mutation<BrandV2, UpdateBrandAction>({
      query: ({ key, data }) => ({ url: BRANDS_V2(key), method: 'PATCH', body: data }), // TODO: Should map payload to generated DTO PatchBrand
      transformResponse: (response: BrandDetailDTO) => brandDetailMapper(response),
      invalidatesTags: (_result, _error, arg) => [
        { type: BrandTags.SELECTED_BRAND, id: arg.key },
        UserTags.USER,
        LocationTags.SEARCH_LOCATIONS,
      ],
    }),
    deleteBrand: build.mutation<void, BrandV2['key']>({
      query: (key) => ({ url: BRANDS_V2(key), method: 'DELETE' }),
      invalidatesTags: (_result, _erro, arg) => [
        { type: BrandTags.SELECTED_BRAND, id: arg },
        UserTags.USER,
      ],
    }),
    publishService: build.mutation<void, { brandKey: string; serviceKey: string }>({
      query: ({ brandKey, serviceKey }) => ({
        url: BRANDS_V2_PUBLISH_APP(brandKey, serviceKey),
        method: 'PUT',
      }),
      invalidatesTags: [BrandTags.SELECTED_BRAND],
    }),
    // TODO: Should use this query for fetching dataowners for brand
    getBrandDataowners: build.query<Array<DataownerBasic>, BrandV2['key']>({
      query: (key) => ({ url: BRANDS_V2_DATAOWNERS(key) }),
      transformResponse: dataownersBasicMapper,
      providesTags: [{ type: BrandTags.BRAND_DATAOWNERS }],
    }),
    getBrandLocations: build.query<Array<LocationOverview>, BrandV2['key']>({
      query: (key) => ({ url: BRANDS_V2_LOCATIONS(key) }),
      transformResponse: (response: Array<LocationOverviewDTO>) => locationOverviewMapper(response),
      providesTags: [{ type: BrandTags.BRAND_LOCATIONS }],
    }),
    getBrandEmployees: build.query<Array<EmployeeBasicV2>, BrandV2['key']>({
      query: (key) => ({ url: BRANDS_V2_EMPLOYEES(key) }),
      transformResponse: (response: EmployeeOverviewDTO) => {
        const overview = employeeOverviewMapper(response);
        const mergedEmployees = [...overview.activeEmployees, ...overview.inactiveEmployees];
        return sortDeactivatedLast(sortAdminsFirst(mergedEmployees));
      },
      providesTags: (result, _error, brandKey) =>
        result
          ? [
              ...result.map(({ userId }) => ({ type: EmployeeTags.EMPLOYEE, id: userId })),
              { type: BrandTags.BRAND_EMPLOYEES, id: brandKey },
            ]
          : [{ type: BrandTags.BRAND_EMPLOYEES, id: brandKey }],
    }),
    addBrandEmployee: build.mutation<Array<EmployeeBasicV2>, AddEmployeesToBrandRequest>({
      query: ({ brandKey, ...payload }) => ({
        url: BRANDS_V2_EMPLOYEES(brandKey),
        method: 'POST',
        body: [payload],
      }),
      transformResponse: (response: Array<EmployeeBasicDTO>) => response.map(employeeBasicMapper),
      invalidatesTags: (result, _error, arg) =>
        result ? [BrandTags.BRAND_EMPLOYEES, { type: EmployeeTags.EMPLOYEE, id: arg.userId }] : [],
    }),
    removeBrandEmployee: build.mutation<void, { brandKey: string; userId: string }>({
      query: ({ brandKey, userId }) => ({
        url: BRANDS_V2_EMPLOYEES_EMPLOYEE(brandKey, userId),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: EmployeeTags.EMPLOYEE, id: arg.userId },
        BrandTags.BRAND_EMPLOYEES,
        EmployeeTags.SEARCH_EMPLOYEES,
        LocationTags.SEARCH_LOCATIONS,
        DataownerTags.SEARCH_DATAOWNERS,
      ],
    }),
    updateBrandLocationProperties: build.mutation<void, UpdateBrandLocationProperties>({
      query: ({ brandKey, data }) => ({
        url: BRANDS_V2_LOCATION_PROPERTIES(brandKey),
        method: 'PUT',
        body: data, //TODO: Should map payload to generated DTO PatchLocationProperty
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: BrandTags.SELECTED_BRAND, id: arg.brandKey },
      ],
    }),
    createBrandRegion: build.mutation<void, CreateBrandRegionRequest>({
      query: ({ brandKey, region }) => ({
        url: BRANDS_V2_REGIONS(brandKey),
        method: 'POST',
        body: region,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: BrandTags.SELECTED_BRAND, id: arg.brandKey },
      ],
    }),
    updateBrandRegion: build.mutation<void, UpdateBrandRegionRequest>({
      query: ({ brandKey, region }) => ({
        url: BRANDS_V2_REGIONS_KEY(brandKey, region.uuid),
        method: 'PATCH',
        body: { name: region.name },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: BrandTags.SELECTED_BRAND, id: arg.brandKey },
      ],
    }),
    deleteBrandRegion: build.mutation<void, DeleteBrandRegionRequest>({
      query: ({ brandKey, uuid }) => ({
        url: BRANDS_V2_REGIONS_KEY(brandKey, uuid),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: BrandTags.SELECTED_BRAND, id: arg.brandKey },
      ],
    }),
    createBrandLocationFieldConfig: build.mutation<void, CreateBrandLocationFieldConfigRequest>({
      query: ({ brandKey, show, fieldName }) => ({
        url: BRANDS_V2_LOCATION_FIELD_CONFIG(brandKey),
        method: 'POST',
        body: { show, fieldName },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: BrandTags.SELECTED_BRAND, id: arg.brandKey },
      ],
    }),
    updateBrandLocationFieldConfig: build.mutation<void, UpdateBrandLocationFieldConfigRequest>({
      query: ({ brandKey, show, fieldId }) => ({
        url: BRANDS_V2_LOCATION_FIELD_CONFIG(brandKey, fieldId),
        method: 'PATCH',
        body: { show },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: BrandTags.SELECTED_BRAND, id: arg.brandKey },
      ],
    }),
    createBrandProperty: build.mutation<
      PropertyDetail,
      { brandKey: string; property: CreateProperty }
    >({
      query: ({ brandKey, property }) => ({
        url: BRANDS_V2_PROPERTIES(brandKey),
        method: 'POST',
        body: property,
      }),
      transformResponse: (response: PropertyDetailDTO) => propertyMapper(response),
      invalidatesTags: (result, _error, arg) =>
        result ? [{ type: BrandTags.SELECTED_BRAND, id: arg.brandKey }] : [],
    }),
    updateBrandProperty: build.mutation<
      PropertyDetail,
      { brandKey: string; propertyKey: string; property: EditProperty }
    >({
      query: ({ brandKey, propertyKey, property }) => ({
        url: BRANDS_V2_PROPERTY_KEY(brandKey, propertyKey),
        method: 'PATCH',
        body: property,
      }),
      transformResponse: (response: PropertyDetailDTO) => propertyMapper(response),
      invalidatesTags: (result, _error, arg) =>
        result ? [{ type: BrandTags.SELECTED_BRAND, id: arg.brandKey }] : [],
    }),
    getInheritableBrandProperty: build.query<Array<InheritableProperty>, { brandKey: string }>({
      query: ({ brandKey }) => BRANDS_V2_INHERITABLE_PROPERTIES(brandKey),
      transformResponse: (response: Array<InheritablePropertyDTO>) =>
        inheritablePropertiesMapper(response),
      providesTags: (result, _error, arg) =>
        result ? [{ type: BrandTags.BRAND_INHERITABLE_PROPERTIES, id: arg.brandKey }] : [],
    }),
    createInheritableBrandProperty: build.mutation<
      InheritableProperty,
      { brandKey: string; property: CreateInheritableProperty }
    >({
      query: ({ brandKey, property }) => ({
        url: BRANDS_V2_INHERITABLE_PROPERTIES(brandKey),
        method: 'POST',
        body: property,
      }),
      transformResponse: (response: InheritablePropertyDTO) => inheritablePropertyMapper(response),
      invalidatesTags: (_result, _error, arg) => [
        { type: BrandTags.SELECTED_BRAND, id: arg.brandKey },
        ...(arg.property.dataownerInheritable
          ? [{ type: DataownerTags.DATAOWNER }, { type: BrandTags.BRAND_DATAOWNERS }]
          : []),
        ...(arg.property.locationInheritable
          ? [{ type: LocationTags.LOCATION }, { type: BrandTags.BRAND_LOCATIONS }]
          : []),
        { type: BrandTags.BRAND_INHERITABLE_PROPERTIES, id: arg.brandKey },
      ],
    }),
    updateInheritableBrandProperty: build.mutation<
      InheritableProperty,
      { brandKey: string; propertyKey: string; property: EditInheritableProperty }
    >({
      query: ({ brandKey, propertyKey, property }) => ({
        url: BRANDS_V2_INHERITABLE_PROPERTIES_KEY(brandKey, propertyKey),
        method: 'PATCH',
        body: property,
      }),
      transformResponse: (response: InheritablePropertyDTO) => inheritablePropertyMapper(response),
      invalidatesTags: (result, _error, arg) =>
        result
          ? [
              { type: BrandTags.SELECTED_BRAND, id: arg.brandKey },
              { type: BrandTags.BRAND_INHERITABLE_PROPERTIES, id: arg.propertyKey },
            ]
          : [],
    }),
    deleteBrandProperty: build.mutation<void, { brandKey: string; propertyKey: string }>({
      query: ({ brandKey, propertyKey }) => ({
        url: BRANDS_V2_PROPERTY_KEY(brandKey, propertyKey),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: BrandTags.SELECTED_BRAND, id: arg.brandKey },
        { type: BrandTags.BRAND_INHERITABLE_PROPERTIES, id: arg.brandKey },
      ],
    }),
    deleteInheritableBrandProperty: build.mutation<void, { brandKey: string; propertyKey: string }>(
      {
        query: ({ brandKey, propertyKey }) => ({
          url: BRANDS_V2_INHERITABLE_PROPERTIES_KEY(brandKey, propertyKey),
          method: 'DELETE',
        }),
        invalidatesTags: (_result, _error, arg) => [
          { type: BrandTags.SELECTED_BRAND, id: arg.brandKey },
          { type: BrandTags.BRAND_INHERITABLE_PROPERTIES, id: arg.brandKey },
          { type: BrandTags.BRAND_INHERITABLE_PROPERTIES, id: arg.propertyKey },
        ],
      }
    ),
  }),
  overrideExisting: false,
});

export const {
  usePrefetch: usePrefetchBrand,
  useGetBrandQuery,
  useCreateBrandMutation,
  useUpdateBrandMutation,
  useDeleteBrandMutation,
  usePublishServiceMutation,
  useGetBrandDataownersQuery,
  useGetBrandLocationsQuery,
  useGetBrandEmployeesQuery,
  useAddBrandEmployeeMutation,
  useRemoveBrandEmployeeMutation,
  useUpdateBrandLocationPropertiesMutation,
  useCreateBrandRegionMutation,
  useUpdateBrandRegionMutation,
  useDeleteBrandRegionMutation,
  useCreateBrandLocationFieldConfigMutation,
  useUpdateBrandLocationFieldConfigMutation,
  useGetInheritableBrandPropertyQuery,
  useCreateBrandPropertyMutation,
  useCreateInheritableBrandPropertyMutation,
  useUpdateBrandPropertyMutation,
  useUpdateInheritableBrandPropertyMutation,
  useDeleteBrandPropertyMutation,
  useDeleteInheritableBrandPropertyMutation,
} = brandApi;
