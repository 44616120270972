import { SvgIcon, SvgIconProps } from '@mui/material';

export const SanityIcon = ({ fontSize }: Pick<SvgIconProps, 'fontSize'>) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1"
        width="1365.333"
        height="1365.333"
        viewBox="0 0 1024.000000 1024.000000"
      >
        <path
          fill="currentColor"
          d="M472.5 166.6c-64.4 7.3-119.4 33.6-156.3 74.7l-7.4 8.2.7 9c1.3 19.6 7.8 42.8 16.5 59.4 7.1 13.4 15.1 24.1 26.5 35.7 10.8 10.8 14.2 13.4 14.9 11.2 9.2-30.3 16.7-43.9 33.6-60.9 18.9-19.1 42.2-31 72.4-37.2 9.8-1.9 14-2.2 36.6-2.2 27.6.1 35 1.1 54.2 7.1 33.3 10.5 59.3 32.8 75.5 64.9l5.8 11.5h60.8c55.6 0 60.7-.1 60.7-1.6 0-3.1-6-22.5-10.6-34.2-11.9-30.3-28.4-55.2-51.5-77.8-37.8-36.8-86.2-58.4-150-67-16.7-2.2-65.6-2.7-82.4-.8z"
        />
        <path
          fill="currentColor"
          d="M285.5 283.1c-13.3 29.7-19.3 65-16.6 98.2 3.6 43.8 17.1 76.1 43.5 103.4 21.7 22.6 48.5 38.8 85.1 51.6 18.5 6.4 24.4 7.9 99.4 25.2 71.7 16.5 78.8 18.4 104.2 27.1 56 19.2 94.8 44.8 120.5 79.3 7.7 10.3 19.2 32.9 23.4 46 1.7 5.2 4.2 15.1 5.6 22 1.4 6.9 2.6 13 2.8 13.6.6 1.6 8.3-15.3 11.5-25.2 4.1-12.7 7.8-30 9.2-43.1 1.5-14.8.7-46.6-1.6-59.2-12.9-71.2-62.7-120.6-148-146.5-6.6-2-44.2-11.2-83.5-20.5s-77.8-18.8-85.5-21c-37-10.9-73-27.9-97.5-46.1-12.2-9.1-29-25.7-38.2-37.9-13-17.3-26.2-47-29.3-66-1.2-7.5-1.9-7.7-5-.9z"
        />
        <path
          fill="currentColor"
          d="M673.5 659.8c-11.1 47.5-45.2 81.5-94.7 94.1-19.7 5.1-24.5 5.6-52.3 5.6-22.7 0-27.5-.3-37.7-2.3-31.8-6-57.7-19-77.3-38.7-12.5-12.5-22.3-27.5-29.8-45.8l-1.6-3.7h-63.5c-62.1 0-63.6 0-63.6 1.9 0 3.6 7 26.1 11.5 37.4 36.1 88.3 117.4 141.4 231 150.8 18.1 1.5 62 .6 77.5-1.5 64.5-9 118.9-34.8 152.9-72.5l7.3-8.1-1.1-12.4c-2.8-31.8-10.9-56.6-25.7-78.8-6.4-9.6-20.1-25-27.4-30.8l-3.7-2.9-1.8 7.7z"
        />
      </svg>
    </SvgIcon>
  );
};
