import React from 'react';
import getIn from 'lodash.get';
import { Control, Controller, DeepMap, FieldError, useFormContext } from 'react-hook-form';
import { ITextInput } from './formTypes';
import { InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material';

interface TextInputRawProps extends ITextInput {
  formMethods: {
    errors: DeepMap<Record<string, any>, FieldError>;
    control: Control<any>;
  };
}

/**
 * @deprecated This component is deprecated, use ../Input/InputText.tsx instead
 */
export const TextInputRaw: React.FC<TextInputRawProps> = ({
  name,
  readOnly = false,
  errorKey,
  margin = 'none',
  defaultValue = '',
  startIcon,
  endIcon,
  formMethods,
  fullWidth,
  ariaLabel,
  ...rest
}) => {
  const { errors, control } = formMethods;
  const errKey = errorKey || getIn(errors, name);
  const { breakpoints } = useTheme();
  const isLessThanMedium = useMediaQuery(breakpoints.down('md'));

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth={isLessThanMedium || fullWidth}
          margin={margin}
          type="text"
          id={name}
          error={!!errKey}
          helperText={errKey && errKey?.message}
          inputProps={{ readOnly }}
          autoComplete="off"
          InputProps={{
            startAdornment: startIcon ? (
              <InputAdornment position="start">{startIcon}</InputAdornment>
            ) : undefined,
            endAdornment: endIcon ? (
              <InputAdornment position="end">{endIcon}</InputAdornment>
            ) : undefined,
            'aria-label': ariaLabel,
          }}
          {...rest}
        />
      )}
      control={control}
      name={name}
      defaultValue={defaultValue}
    />
  );
};

/**
 * @deprecated This component is deprecated, use ../Input/InputText.tsx instead
 */
const TextInput: React.FC<ITextInput> = (props) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return <TextInputRaw {...props} formMethods={{ control, errors }} />;
};

export default TextInput;
