import { MessagePaths, useMessagesTranslation } from '@shared/i18n';
import { BrandadminResources } from '../types/BrandadminResources';

export const useBrandadminTranslation = () => useMessagesTranslation<BrandadminResources>();

export type BrandadminMessageKey = MessagePaths<BrandadminResources>;

export const useBrandadminTFunction = () => useBrandadminTranslation().t;

export type BrandadminTFunction = ReturnType<typeof useBrandadminTFunction>;
