import { getDefaultLanguageSettingFromLocale } from '@shared/utils';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { appLanguageResources } from './resources';

export const setupi18n = () => {
  const language = getDefaultLanguageSettingFromLocale();
  i18n.use(initReactI18next).init({
    resources: appLanguageResources,
    lng: language,
    fallbackLng: 'no',
    ns: Object.keys(appLanguageResources.no),
    defaultNS: 'common',
  });
};
