import { DocumentType, TopicPageRequest } from '@dap-sanity/types';
import { groqQuery } from '@shared/utils';
import { contentBlocksQuery } from './contentBlockQueries';

export const getTopicPageQuery = ({ slug, brandKey }: TopicPageRequest) => {
  const query = groqQuery({
    filters: [
      `_type=="${DocumentType.TopicPage}"`,
      `brand == "${brandKey}"`,
      `slug.current=="${slug}"`,
    ],
    projections: [
      `
      title,
      "slug": slug.current,
      text,
      image,
      content[]{
        ${contentBlocksQuery}
      },
      `,
    ],
    slice: { start: 0 },
  });

  return query;
};
